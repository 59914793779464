//This page provides a form to enter user_code and calls the ValidateUserCode API to fetch the authorize_uri and redirect to it
import React, {Component} from "react";
import * as styles from './DeviceVerification.module.css';
import qs from 'query-string';
import map from '../../../configuration/tenantIdAPIEndpointMappigs.js';
import {NavBar, NavTitle, Spinner, Text} from "@amzn/fulfillment-hig-components";
let displayInvalid = false, loading = false, displayError = false, error = "", invalid = "";

//DeviceVerification class with state variables tenant_id, user_code and below methods provides a form to get the user_code.
//Has methods for validating tenant_id, user_code and calling ValidateUserCode API to fetch authorize_uri.
class DeviceVerification extends Component<{},{}> {
    state = {
        tenant_id: '',
        user_code: ''
    }

    // React lifecycle method. Gets called when the component output has been rendered to the DOM.
    componentDidMount() {
        const query_parameters = qs.parse(window.location.search);
        this.setState({
            tenant_id: query_parameters.tenant_id as string,
            user_code: query_parameters.user_code as string
        });
    }

    //Method to set error to be displayed on the screen
    setError(e: string) {
        displayError = true;
        loading = false;
        error = e;
        this.setState({user_code: ''});
    }

    //Method to call ValidateUserCode API and fetch response i.e, authorize_uri.
    async redirectToAuthorizeURI() {
        const user_code = this.state.user_code;
        const tenant_id = this.state.tenant_id;
        try {
            const url = map.get(tenant_id) + user_code;
            const response = await fetch(url);
            await response.json().then(data => {
                    if(data.authorize_uri) {
                        window.location.href = data.authorize_uri;
                    } else if (data.message === "No valid record found for the given userCode and tenantId") {
                        this.setError("Invalid user_code or tenant_id");
                    } else {
                        this.setError("There seems to be a issue with the service. Kindly retry in some time");
                    }
                }
            );
        } catch (e) {
            this.setError("There seems to be a issue with the service. Kindly retry in some time");
        }
    }

    //Method to check whether the userCode is in valid format or not.
    isValidUserCode() {
        const user_code = this.state.user_code;
        if (user_code != null && user_code.length === 8 && user_code.match("^[a-zA-Z0-9]*$")) {
            this.redirectToAuthorizeURI();
            loading=true;
        } else if (!user_code) {
            displayInvalid = false;
        } else {
            displayInvalid = true;
            invalid = "Invalid user_code format";
            loading = false;
        }
    }

    //Method to return userCode form.
    getUserCodeForm() {
        return(
            <div className = {styles.parent}>
                <Text bold size = "2xl">FIT device verification</Text>
                <form>
                    <p><b>User Code</b></p>
                    Onetime code got from the device
                    <br/>
                    <input type = "hidden" name = "tenant_id" value={this.state.tenant_id} />
                    <input className={styles.input}
                           style = {{border: (displayInvalid || displayError) ? "2px solid red" : "2px solid"}}
                           type = "text"
                           name = "user_code"
                           placeholder = "8 digital code" />
                    <button className = {styles.button} onClick={this.isValidUserCode}>
                        Submit
                    </button>
                    { displayError && <p style = {{color:"red", lineHeight: 0}}>{error}</p> }
                    { displayInvalid && <p style = {{color:"red", lineHeight: 0}}>{invalid}</p> }
                </form>
            </div>
        );
    }

    //Method to display loading
    displayLoader() {
        return(
            <div className = {styles.loading}>
                <Spinner size = "md"/><br/>
                <Text size = "2xl">Processing...</Text>
            </div>
        );
    }

    //Method to validate tenantId
    isValidTenantId() {
        if(!map.get(this.state.tenant_id)) {
            displayInvalid = true;
            invalid = "invalid tenant_id";
            return false;
        }
       return true;
    }

    render() {
        if(this.isValidTenantId()) {
            this.isValidUserCode();
        }
        const renderOnCondition = loading ? this.displayLoader() : this.getUserCodeForm();
        return (
            <div>
                <NavBar>
                    <NavTitle>
                        <Text size="lg">FIT Security Token Service</Text>
                    </NavTitle>
                </NavBar>
                {renderOnCondition}
            </div>
        )
    }

}
export default DeviceVerification;
