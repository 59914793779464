import React from "react"

import { MarginProps, convertMarginPropsToStyle } from "../helpers/parseMargin"
import { ThemeContext } from "../theme/ThemeContext"
import { FontSize, TextColor } from "../theme/Theme"
import { LocaleContext } from "../i18n/LocaleContext"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

export type TextSize = FontSize

export type TextVariant = TextColor

export interface TextProps extends MarginProps, DangerousProps, TestProps {
  size?: TextSize
  variant?: TextVariant
  bold?: boolean
  italic?: boolean
  id?: string
}

/**
 * Text component.
 *
 * By default inherits text/background color and font size/weight/style.
 */
export class Text extends React.PureComponent<TextProps> {
  public render() {
    const { size, variant, bold, italic, dangerousClassName, dangerousStyle, id } = this.props

    const className = "text"
      + (size ? ` text--size-${size}` : "")
      + (variant ? ` text--variant-${variant}` : "")
      + (bold ? " font-weight-bold" : "")
      + (italic ? " font-italic" : "")
      + (dangerousClassName ? ` ${dangerousClassName}` : "")

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <LocaleContext.Consumer>
            {({ direction }) => {
              const style: React.CSSProperties = Object.assign(
                convertMarginPropsToStyle(this.props, theme, direction),
                dangerousStyle,
              )

              return (
                <span
                  id={id}
                  className={className}
                  style={style}
                  data-testid={renderTestID(this.props.testID)}
                >
                  {this.props.children}
                </span>
              )
            }}
          </LocaleContext.Consumer>
        )}
      </ThemeContext.Consumer>
    )
  }
}
