// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import conveyorImage from "../../resources/images/conveyor.i.svg"
import conveyorImageReflected from "../../resources/images/conveyor-reflected.i.svg"
import toteImage from "../../resources/images/tote.i.svg"

import yellow_tote from "../../resources/images/yellow-tote.i.svg"
import black_tote from "../../resources/images/black-tote.i.svg"
import blue_tote from "../../resources/images/blue-tote.i.svg"
import gray_tote from "../../resources/images/gray-tote.i.svg"
import green_tote from "../../resources/images/green-tote.i.svg"
import red_tote from "../../resources/images/red-tote.i.svg"
import white_tote from "../../resources/images/white-tote.i.svg"
import package_csX from "../../resources/images/package-csX.i.svg"

import { ContainerVariant } from "./ContainerVariant"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface ConveyorImageProps {
  /**
   * Item to render on conveyor.
   */
  item?: ContainerVariant | "none"

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Tote on conveyor image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="conveyor" | name="tote-on-conveyor").
 */
export class ConveyorImage extends React.PureComponent<ConveyorImageProps> {
  public render() {
    const { item, altText, translator } = this.props
    const componentImages = getImagesForItemOnConveyor(item)
    const itemSource = componentImages[0]
    const conveyorSource = componentImages[1]

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <div className="conveyor-image">
        <img
          className="conveyor-image__conveyor"
          src={conveyorSource}
          alt={imageAltText}
        />
        {itemSource && (
          <img className="conveyor-image__item" src={itemSource} alt=""/>
        )}
      </div>
    )
  }
}

function getImagesForItemOnConveyor(item?: ContainerVariant | "none") {
  switch (item) {
    case "tote":
      return [toteImage, conveyorImage]
    case "yellow_tote":
      return [yellow_tote, conveyorImageReflected]
    case "black_tote":
      return [black_tote, conveyorImageReflected]
    case "blue_tote":
      return [blue_tote, conveyorImageReflected]
    case "gray_tote":
      return [gray_tote, conveyorImageReflected]
    case "green_tote":
      return [green_tote, conveyorImageReflected]
    case "red_tote":
      return [red_tote, conveyorImageReflected]
    case "white_tote":
      return [white_tote, conveyorImageReflected]
    case "package_csX":
      return [package_csX, conveyorImage]
    default:
      return [undefined, conveyorImage]
  }
}
