import React from "react"

import { IconName } from "../icon/IconName"
import { Icon } from "../icon/Icon"
import { LocaleContext } from "../i18n/LocaleContext"
import { LocalizedString } from "../i18n/Translator"
import { LocalizedText } from "../text/LocalizedText"
import { TextLayout } from "../layout/text/TextLayout"
import { AlertParagraph } from "./AlertParagraph"
import { AlertLink } from "./AlertLink"
import { LimitLayout } from "../layout/limit/LimitLayout"
import { SlideLayoutContext } from "../layout/slide/SlideLayoutContext"
import { AlertContext, AlertUpdateCallback } from "./AlertContext"
import { ViewportCapsContext } from "../layout/viewport/ViewportCapsContext"
import { IconSize } from "../icon/IconSize"
import { ViewportCaps } from "../layout/viewport/ViewportCaps"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

export type AlertVariant = "info" | "error" | "warning" | "success"

export type AlertDensity = "dense" | "normal" | "expanded"

export interface AlertProps extends DangerousProps, TestProps {
  /**
   * Alert variant, affects color and icon.
   */
  variant?: AlertVariant

  /**
   * Resizes component to fit into parent container if set to `true`.
   */
  flex?: boolean

  /**
   * Will override the icon that comes with the `AlertVariant`.
   */
  customIcon?: IconName

  /**
   * Will hide the icon (Either the icon from `variant` or the one provided by `customIcon`)
   */
  noIcon?: boolean

  /**
   * Alert title.
   */
  title?: LocalizedString

  /**
   * Alert density, affects spacing and layout.
   */
  density?: AlertDensity

  /**
   * Wraps content into LimitLayout if enabled.
   */
  limit?: boolean

  /**
   * Mark alert as inactive, alert status doesn't propagate in that case.
   */
  inactive?: boolean

  /**
   * Render corners rounded.
   */
  rounded?: boolean
}

type AlertInternalProps = AlertProps & {
  /**
   * The `ViewportCaps` to be used by the `AlertInternal` component to decice on it's rendering
   * logic. Right now it uses it to make its content ultra-densed for smaller screens so that
   * the conent could fit on the screen.
   */
  viewPortCaps: ViewportCaps

  /**
   * Triggered on initial render and on any update if mounted and active.
   *
   * Needed to make NavBar variant to follow Alert variant.
   */
  onUpdate?: AlertUpdateCallback,
}

/**
 * Alert component (internal part).
 */
class AlertInternal extends React.PureComponent<AlertInternalProps> {
  private readonly defaultVariant = "info"

  private readonly defaultDensity = "normal"

  private readonly variantToIconMap: Record<AlertVariant, IconName | null> = {
    info: "info-solid",
    success: "check-solid",
    error: "error",
    warning: "alert",
  }

  public componentDidUpdate(prevProps: AlertInternalProps) {
    if (this.props.onUpdate && !this.props.inactive) {
      const prevVariant = prevProps.variant || this.defaultVariant
      const nextVariant = this.props.variant || this.defaultVariant

      if (prevProps.inactive || prevVariant !== nextVariant) {
        this.props.onUpdate(nextVariant)
      }
    }
  }

  public componentDidMount() {
    if (this.props.onUpdate && !this.props.inactive) {
      const nextVariant = this.props.variant || this.defaultVariant
      this.props.onUpdate(nextVariant)
    }
  }

  public componentWillUnmount() {
    if (this.props.onUpdate && !this.props.inactive) {
      this.props.onUpdate(undefined)
    }
  }

  private iconSize(density: AlertDensity | "ultradense"): IconSize {
    // title has a larger font so icon should be larger too to be aligned with title
    return density === "expanded" ?
      64 :
      (
        this.props.title ? 24 : 20
      )
  }

  public render() {
    const variant = this.props.variant || this.defaultVariant
    const density = this.props.density || this.defaultDensity

    const className = "alert"
      + ` alert--variant-${variant}`
      + ` theme--variant-${variant}`
      + ` alert--density-${density}`
      + (this.props.rounded ? ` alert--rounded` : "")
      + (this.props.flex ? " alert--flex" : "")
      + (this.props.dangerousClassName ? ` ${this.props.dangerousClassName}` : "")

    const resolvedIcon = this.props.customIcon || this.variantToIconMap[variant]
    const iconName = this.props.noIcon ? undefined : resolvedIcon
    const iconPos = iconName
      ? (density === "expanded" ? "separate" : (this.props.title ? "title" : "content"))
      : "none"

    return (
      <LocaleContext.Consumer>
        {({ direction }) => {
          const iconWrapperClass = "alert__icon-wrap"
            + ` alert__icon-wrap--${direction}`

          const iconClass = `alert__icon-wrap__icon`

          const titleClass = `alert__title alert__title--density-${density}`
            + (iconPos === "title" ? ` alert__title--with-icon-${direction}` : "")

          const contentClass = `alert__content alert__content--density-${density}`
            + (iconPos === "content" ? ` alert__content--with-icon-${direction}` : "")

          const icon = iconName && (
            <div className={iconWrapperClass}>
              <Icon
                name={iconName}
                size={this.iconSize(density)}
                dangerousClassName={iconClass}
              />
            </div>
          )

          const inner = (
            <div className="alert__inner">
              {iconPos === "separate" && icon}
              {this.props.title && (
                <div className={titleClass}>
                  {iconPos === "title" && icon}
                  <LocalizedText {...this.props.title} testID={renderTestID(this.props.testID, "title")}/>
                </div>
              )}
              {(this.props.children || iconPos === "content") && (
                <div className={contentClass}>
                  {iconPos === "content" && icon}
                  {this.props.children}
                </div>
              )}
            </div>
          )

          let body = density === "expanded"
            ? <TextLayout vAlign="middle" hAlign="center">{inner}</TextLayout>
            : inner

          if (this.props.limit) {
            body = <LimitLayout flex={this.props.flex}>{body}</LimitLayout>
          }

          return (
            <div
              className={className}
              style={this.props.dangerousStyle}
              data-testid={renderTestID(this.props.testID)}
              role="alert"
            >
              {body}
            </div>
          )
        }}
      </LocaleContext.Consumer>
    )
  }
}

/**
 * Alert component.
 */
export class Alert extends React.PureComponent<AlertProps> {
  public static Paragraph = AlertParagraph

  public static Link = AlertLink

  public render() {
    return (
      <AlertContext.Consumer>
        {(onUpdate) => (
          <SlideLayoutContext.Consumer>
            {(insideActiveSlide) => (
              <ViewportCapsContext.Consumer>
                {(viewportCaps) => {
                  return (
                    <AlertInternal
                      {...this.props}
                      viewPortCaps={viewportCaps}
                      inactive={!insideActiveSlide || this.props.inactive}
                      onUpdate={onUpdate}
                    />
                  )
                }}
              </ViewportCapsContext.Consumer>
            )}
          </SlideLayoutContext.Consumer>
        )}
      </AlertContext.Consumer>
    )
  }
}
