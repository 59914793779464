import React from "react"

import { ChromeConfirmationModal } from "./ChromeConfirmationModal"
import { CountdownTimer } from "../datetime/CountdownTimer"
import { HotKeyCode } from "../keyboard/HotKeyCode"

type ChromePresenceModalStringKey =
  "caption1" | "caption2" | "caption3" | "caption4" | "caption5" | "body" | "bodyHiddenSignOut" | "continue" | "signOut"

export type ChromePresenceModalStrings = Partial<Record<ChromePresenceModalStringKey, string>>

interface ChromePresenceModalProps {
  /**
   * Date timestamp when modal was initiated.
   *
   * Needed to make sure that timeout timer will be synced with the clock.
   */
  initialTime?: number

  /**
   * Automatic sign out is performed after this timeout (in seconds).
   */
  timeout: number // in seconds

  /**
   * If sign out button should be hidden.
   */
  hideSignOut?: boolean

  /**
   * Callback executed when user manually confirms sign out.
   */
  onSignOut?: () => void

  /**
   * Callback executed when user manually cancels sign out and confirms presence.
   */
  onContinue?: () => void

  /**
   * Called automatically when user did not respond in time (means sign out).
   */
  onTimeout?: () => void
}

/**
 * Presence confirmation modal that is displayed to user when he is idle.
 */
export class ChromePresenceModal extends React.PureComponent<ChromePresenceModalProps> {
  // override this value to customize strings and provide translations
  public static defaultStrings: ChromePresenceModalStrings = {}

  public static readonly defaultTexts: Record<ChromePresenceModalStringKey, string> = {
    caption1: "Hello?",
    caption2: "Are you still there?",
    caption3: "Hey?",
    caption4: "Where are you?",
    caption5: "Ready?",
    body: "Your session will time out {remainingTime, plural, =0 {now} =1 {in <b>#</b> second} other {in <b>#</b> seconds}}.",
    bodyHiddenSignOut: "Sign out is not available.<br>Please complete the current task.",
    continue: "Continue Session",
    signOut: "Sign Out",
  }

  public static readonly captionCount =
    Object.keys(ChromePresenceModal.defaultTexts)
      .filter((key) => key.startsWith("caption"))
      .length

  public static readonly hotKeys: Record<"continue" | "signOut", HotKeyCode> = {
    continue: "Enter",
    signOut: "KeyQ",
  }

  public render() {
    const { defaultTexts, defaultStrings, captionCount, hotKeys } = ChromePresenceModal
    const { timeout, initialTime } = this.props

    // random caption
    const captionKeyStr = "caption" + Math.round(Math.random() * (captionCount - 1) + 1)
    const captionKey = captionKeyStr as ChromePresenceModalStringKey

    return (
      <CountdownTimer
        initialTime={initialTime}
        initialValue={timeout}
        onFinish={this.props.onTimeout}
      >
        {(remainingTime) => (
          <ChromeConfirmationModal
            id="presence"

            caption={{
              stringID: defaultStrings[captionKey],
              defaultString: defaultTexts[captionKey],
            }}

            body={{
              stringID: defaultStrings.body,
              defaultString: defaultTexts.body,
              parameters: { remainingTime },
            }}

            secondaryBody={!this.props.hideSignOut ? undefined : {
              stringID: defaultStrings.bodyHiddenSignOut,
              defaultString: defaultTexts.bodyHiddenSignOut
            }}

            primaryButtonHotKey={hotKeys.continue}
            primaryButtonLabel={{
              stringID: defaultStrings.continue,
              defaultString: defaultTexts.continue,
            }}
            onPrimaryButtonClick={this.props.onContinue}

            secondaryButtonHotKey={!this.props.hideSignOut ? hotKeys.signOut : undefined}
            secondaryButtonLabel={!this.props.hideSignOut ? {
              stringID: defaultStrings.signOut,
              defaultString: defaultTexts.signOut,
            } : undefined}
            onSecondaryButtonClick={!this.props.hideSignOut ? this.props.onSignOut : undefined}
          />
        )}
      </CountdownTimer>
    )
  }
}
