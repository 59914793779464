import React from "react"

export interface CountdownTimerProps {
  /**
   * Pass timestamp that will be used to ensure that countdown timer will be
   * synced with clock.
   */
  initialTime?: number

  /**
   * Initial remaining time value in seconds.
   */
  initialValue: number

  /**
   * Called each time clock ticks.
   */
  onTick?: (remainingTime: number) => void

  /**
   * Called when countdown reached 0 value.
   */
  onFinish?: () => void

  /**
   * Render property that is called with remaining time in seconds.
   */
  children: (remainingTime: number) => React.ReactNode
}

export interface CountdownTimerState {
  startTime: number
  currentValue: number
}

/**
 * Countdown timer provides state wrapper for remaining time in seconds.
 *
 * State is automatically updated each second.
 */
export class CountdownTimer extends React.PureComponent<CountdownTimerProps, CountdownTimerState> {
  private intervalID?: number

  constructor(props: CountdownTimerProps) {
    super(props)

    const startTime = props.initialTime ? props.initialTime : Date.now()
    const currentValue = this.getCurrentValue(startTime, this.props.initialValue)

    this.state = { startTime, currentValue }

    this.tick = this.tick.bind(this)
  }

  public startTimer() {
    this.stopTimer()
    this.intervalID = window.setInterval(this.tick, 1000)
  }

  public stopTimer() {
    if (this.intervalID) {
      clearInterval(this.intervalID)
      this.intervalID = undefined
    }
  }

  public tick() {
    const startTime = this.state.startTime

    const currentValue = this.getCurrentValue(startTime, this.props.initialValue)

    const isFinished = currentValue <= 0

    if (isFinished) {
      this.stopTimer()
    }

    this.setState({ currentValue }, () => {
      if (this.props.onTick) {
        this.props.onTick(currentValue)
      }
      if (isFinished && this.props.onFinish) {
        this.props.onFinish()
      }
    })
  }

  public componentDidMount() {
    if (this.state.currentValue > 0) {
      this.startTimer()
    } else {
      if (this.props.onFinish) {
        this.props.onFinish()
      }
    }
  }

  public componentWillUnmount() {
    this.stopTimer()
  }

  public getCurrentValue(initialTime: number, initialValue: number) {
    const secondsSinceStart = (Date.now() - initialTime) / 1000
    return Math.max(0, Math.round(initialValue - secondsSinceStart))
  }

  public render() {
    return this.props.children(this.state.currentValue)
  }
}
