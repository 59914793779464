import React from "react"

import { memoizeOne } from "../../../utilities/memoizeOne"
import { ViewportCaps } from "./ViewportCaps"
import { ViewportCapsContext } from "./ViewportCapsContext"
import { LayoutSizeContext } from "../flex/LayoutSizeContext"
import { LocaleContext } from "../../i18n/LocaleContext"
import { BrowserUtils } from "../../../utilities/BrowserUtils"

export interface ViewportState {
  width: number
  height: number
}

export class Viewport extends React.PureComponent<{}, ViewportState> {
  private rootRef = React.createRef<HTMLDivElement>()

  constructor(props: {}) {
    super(props)

    this.state = { width: 0, height: 0 }

    this.handleWindowResize = this.waitForAnimationFrame(this.handleWindowResize.bind(this))

    this.getViewportCaps = memoizeOne(this.getViewportCaps.bind(this))
    this.getLayoutSize = memoizeOne(this.getLayoutSize.bind(this))
    this.wrapBody = memoizeOne(this.wrapBody.bind(this))
  }

  public waitForAnimationFrame(callback: FrameRequestCallback) {
    let timeout: number | undefined
    return () => {
      if (timeout) {
        cancelAnimationFrame(timeout)
      }
      timeout = requestAnimationFrame(callback)
    }
  }

  public handleWindowResize() {
    if (this.rootRef.current && this.rootRef.current.parentNode) {
      const parentElement = this.rootRef.current.parentNode as Element
      const { width, height } = BrowserUtils.getBoundingClientRect(parentElement)
      this.setState({ width, height })
    }
  }

  public componentDidMount() {
    this.handleWindowResize()

    BrowserUtils.addEventListener(window, "resize", this.handleWindowResize)
  }

  public componentWillUnmount() {
    BrowserUtils.removeEventListener(window, "resize", this.handleWindowResize)
  }

  public getViewportCaps(width: number, height: number) {
    return new ViewportCaps(width, height)
  }

  public getLayoutSize(width: number, height: number) {
    return { width, height }
  }

  public wrapBody(body: React.ReactNode, width: number, height: number) {
    if (!width || !height) {
      return null
    }

    const viewportCaps = this.getViewportCaps(width, height)
    const layoutSize = this.getLayoutSize(width, height)

    return (
      <ViewportCapsContext.Provider value={viewportCaps}>
        <LayoutSizeContext.Provider value={layoutSize}>
          {body}
        </LayoutSizeContext.Provider>
      </ViewportCapsContext.Provider>
    )
  }

  public render() {
    const { width, height } = this.state

    return (
      <LocaleContext.Consumer>
        {({ direction }) => (
          <div className="viewport" dir={direction} ref={this.rootRef}>
            {this.wrapBody(this.props.children, width, height)}
          </div>
        )}
      </LocaleContext.Consumer>
    )
  }
}
