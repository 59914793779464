import { hex2rgb } from "./hex2rgb"
import { luminance } from "./luminance"

/**
 * Evaluates the proper text color to be used for the provided background color
 * based on the luminance of the background color.
 *
 * @param background The background color, must have css hex format: #RGB or #RRGGBB.
 */
export function textColor(background: string): "light" | "dark" | "unknown" {
  try {
    const [r, g, b] = hex2rgb(background)
    const luma = luminance(r, g, b)
    return luma > 0.5 ? "dark" : "light"
  } catch (e) {
    console.warn(`Invalid color provided to textColor function: ${background}`)
    return "unknown"
  }
}
