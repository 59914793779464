import { SoundName } from "./SoundName"
import { SoundProvider } from "./SoundProvider"

/**
 * Sound provider that do nothing.
 *
 * Can be used to turn off the sound.
 */
export class NullSoundProvider implements SoundProvider {
  public preload() {
    // noop
  }

  public unload() {
    // noop
  }

  public play(sound: SoundName) {
    // noop
  }

  public stop() {
    // noop
  }
}
