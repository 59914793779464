export type ViewportOrientation = "portrait" | "landscape"

export type ViewportHSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

export interface HBreakpointMap { [key: string]: number }

// based on screen width
const breakpointMap: { [key: string]: number } = {
  xs:     0,  // 0-379
  sm:   380,  // 380-719
  md:   720,  // 720-959
  lg:   960,  // 960-1099
  xl:  1100,  // 1100-1599
  xxl: 1600,  // 1600-max
} as HBreakpointMap

// provided by AFT UX - needed for applciations running on stations
const zoomScaleMap: Record<ViewportHSize, number> = {
  xs:  1.0,
  sm:  1.0,
  md:  1.5,
  lg:  1.5,
  xl:  1.5,
  xxl: 2.0,
}

export class ViewportCaps {
  public readonly width: number

  public readonly height: number

  public readonly hSize: ViewportHSize

  public readonly orientation: ViewportOrientation

  public constructor(width: number, height: number) {
    this.width = width
    this.height = height
    this.hSize = this.detectHorizontalSize()
    this.orientation = this.detectOrientation()
  }

  public hSizeAtLeast(size: ViewportHSize) {
    return breakpointMap[this.hSize] >= breakpointMap[size]
  }

  public hSizeAtMost(size: ViewportHSize) {
    return breakpointMap[this.hSize] <= breakpointMap[size]
  }

  public getZoomScale() {
    return zoomScaleMap[this.hSize]
  }

  private detectHorizontalSize(): ViewportHSize {
    let prevBreakpoint
    let prevName
    for (const name in breakpointMap) {
      const breakpoint = breakpointMap[name]
      if (prevBreakpoint !== undefined
        && this.width >= prevBreakpoint
        && this.width < breakpoint
      ) {
        return prevName as ViewportHSize
      }
      prevBreakpoint = breakpoint
      prevName = name
    }
    return prevName as ViewportHSize
  }

  private detectOrientation(): ViewportOrientation {
    return this.height >= this.width ? "portrait" : "landscape"
  }
}
