// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import { ContainerVariant } from "./ContainerVariant"

import yellow_tote from "../../resources/images/yellow-tote.i.svg"
import black_tote from "../../resources/images/black-tote.i.svg"
import blue_tote from "../../resources/images/blue-tote.i.svg"
import gray_tote from "../../resources/images/gray-tote.i.svg"
import green_tote from "../../resources/images/green-tote.i.svg"
import red_tote from "../../resources/images/red-tote.i.svg"
import white_tote from "../../resources/images/white-tote.i.svg"
import package_csX from "../../resources/images/package-csX.i.svg"
import pick_cart from "../../resources/images/pick-cart.i.svg"
import cage from "../../resources/images/isometric-cage-na.i.svg"
import pallet from "../../resources/images/pallet.i.svg"

import { LocalizedString, Translator } from "../i18n/Translator"

export interface ContainerImageProps {
  /**
   * The container type that defines the container image to be rendered.
   */
  containerVariant: ContainerVariant

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Container image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="container").
 */
export class ContainerImage extends React.PureComponent<ContainerImageProps> {
  public render() {
    const { altText, translator } = this.props

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={getContainerSource(this.props.containerVariant)}
        className="container-image"
        alt={imageAltText}
      />
    )
  }
}

function getContainerSource(containerVariant: ContainerVariant): string | undefined {

  switch (containerVariant) {
    case "tote":
    case "yellow_tote": {
      return yellow_tote
    }
    case "black_tote": {
      return black_tote
    }
    case "blue_tote": {
      return blue_tote
    }
    case "gray_tote": {
      return gray_tote
    }
    case "green_tote": {
      return green_tote
    }
    case "red_tote": {
      return red_tote
    }
    case "white_tote": {
      return white_tote
    }
    case "package_csX": {
      return package_csX
    }
    case "pick_cart": {
      return pick_cart
    }
    case "cage": {
      return cage
    }
    case "pallet": {
      return pallet
    }
    default: {
      return undefined
    }
  }
}
