import React from "react"

import { ListItem, ListItemProps } from "./ListItem"

export interface ListSubheaderProps {
  hidden?: boolean
}

export class ListSubheader extends React.PureComponent<ListItemProps> {
  public render() {
    return (
      <ListItem {...this.props} _subheader={true} />
    )
  }
}
