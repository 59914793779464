import React from "react"

import { LocalizedString } from "../i18n/Translator"
import { Alert } from "../alert/Alert"

/**
 * Type of Notification, same as Alert types but including "default" which results in "info".
 */
export type NotificationType = "success" | "error" | "warning" | "info" | "default"

/**
 * Notification to be shown with tasks.
 */
export interface Notification {
  /**
   * The type of the notification.
   *
   * Defaults to info.
   */
  type?: NotificationType
  message: LocalizedString
}

interface ChromeNotificationBarProps {
  /**
   * Notification to be shown
   */
  notification: Notification
}

/**
 * Display notification bar to the user.
 *
 * Designed to display task Notification messages but can be used for other messages.
 */
export class ChromeNotificationBar extends React.PureComponent<ChromeNotificationBarProps> {
  public constructor(props: ChromeNotificationBarProps) {
    super(props)
  }

  public render() {
    const notification = this.props.notification
    const notificationType = notification?.type === "default" ? "info" : notification?.type

    return (
        <Alert
          variant={notificationType}
          title={notification.message}
          density="dense"
        />
    )
  }
}
