import React, { TransitionEvent } from "react"

import { LocaleContext } from "../../i18n/LocaleContext"
import { SlideLayoutContext } from "./SlideLayoutContext"
import { HotKeyActivityContext } from "../../keyboard/HotKeyActivityContext"

export type SlideDirection = "forward" | "backward"

export interface SlideLayoutProps {
  /**
   * Slide direction. Could be forward or backward. Default is forward.
   */
  direction?: SlideDirection

  /**
   * Unique view identifier that can be used to deduplicate slide animation
   * requests if the same view is updated.
   *
   * Any update triggers new slide animation if not set.
   */
  viewID?: string

  /**
   * Function to handle completion after slide animation
   */
  onTransitionCompleted?: () => void
}

interface SlideLayoutState {
  // curIndex is used to assign unique key for each slide during transition.
  // Without unique key React can destroy the state of component during transition and this can
  // cause double sounds, double printed labels or flickering in stateful components
  curIndex: number
  prevChildren: React.ReactNode
  transitionCompleted: boolean
}

/**
 * Provides layout that shows slide forward/backward transition on updates.
 *
 * Also prevents state reuse between slides.
 */
export class SlideLayout extends React.PureComponent<SlideLayoutProps, SlideLayoutState> {
  public constructor(props: SlideLayoutProps) {
    super(props)

    this.state = {
      curIndex: 1,
      prevChildren: null,
      transitionCompleted: true,
    }

    this.handleTransitionEnd = this.handleTransitionEnd.bind(this)
  }

  private handleTransitionEnd(event: TransitionEvent) {
    if (event.propertyName !== "margin-left" && event.propertyName !== "margin-right") {
      // Will also receive the events for opacity so far
      return
    }

    this.setState({
      transitionCompleted: true,
      prevChildren: null,
    }, () => {
      if (this.props.onTransitionCompleted) {
        this.props.onTransitionCompleted()
      }
    })
  }

  public componentDidMount(): void {
    // Triggering the callback because
    // componentWillReceiveProps() won't be triggered for the first appearance.
    this.props.onTransitionCompleted?.()
  }

  public UNSAFE_componentWillReceiveProps(nextProps: React.PropsWithChildren<SlideLayoutProps>) {
    const prevChildren = this.props.children
    const nextChildren = nextProps.children
    const isForward = nextProps.direction !== "backward"

    const viewHasUpdated = !nextProps.viewID || (this.props.viewID !== nextProps.viewID)

    if (viewHasUpdated && nextChildren !== prevChildren) {
      this.setState(({ curIndex }) => ({
        transitionCompleted: false,
        prevChildren,
        curIndex: (isForward ? curIndex + 1 : curIndex - 1),
      }), () => {
        if (document.activeElement) {
          // @ts-ignore assume that blur() is available
          document.activeElement.blur()
        }
      })
    }
  }

  public wrapInContext(node: React.ReactNode, active: boolean, hotKeyActive: boolean) {
    return (
      <HotKeyActivityContext.Provider value={hotKeyActive && active}>
        <SlideLayoutContext.Provider value={active}>
          {node}
        </SlideLayoutContext.Provider>
      </HotKeyActivityContext.Provider>
    )
  }

  public render() {
    return (
      <HotKeyActivityContext.Consumer>
        {(hotKeyActive) => (
          <LocaleContext.Consumer>
            {({ direction }) => {
              const { direction: transitionDirection = "forward" } = this.props
              const { transitionCompleted, curIndex } = this.state

              let prevBody: React.ReactNode = null
              let currentBody: React.ReactNode = null
              let nextBody: React.ReactNode = null
              let active: string = "current"
              let prevKey: number
              let currentKey: number
              let nextKey: number

              if (transitionCompleted) {
                currentBody = this.wrapInContext(this.props.children, true, hotKeyActive)
                prevKey = curIndex - 1
                currentKey = curIndex
                nextKey = curIndex + 1
              } else if (transitionDirection === "forward") {
                active = "next"
                currentBody = this.wrapInContext(this.state.prevChildren, false, hotKeyActive)
                nextBody = this.wrapInContext(this.props.children, true, hotKeyActive)
                prevKey = curIndex - 2
                currentKey = curIndex - 1
                nextKey = curIndex
              } else {
                active = "prev"
                currentBody = this.wrapInContext(this.state.prevChildren, false, hotKeyActive)
                prevBody = this.wrapInContext(this.props.children, true, hotKeyActive)
                prevKey = curIndex
                currentKey = curIndex + 1
                nextKey = curIndex + 2
              }

              const bodyClassName = `slide-layout__body`
                + ` slide-layout__body--active-${active}`
                + ` slide-layout__body--active-${active}-${direction}`

              return (
                <div className="slide-layout">
                  <div
                    className={bodyClassName}
                    onTransitionEnd={this.handleTransitionEnd}
                  >
                    <div key={prevKey} className="slide-layout__prev" aria-hidden="true">
                      {prevBody}
                    </div>
                    <div key={currentKey} className="slide-layout__current">
                      {currentBody}
                    </div>
                    <div key={nextKey} className="slide-layout__next" aria-hidden="true">
                      {nextBody}
                    </div>
                  </div>
                </div>
              )
            }}
          </LocaleContext.Consumer>
        )}
      </HotKeyActivityContext.Consumer>
    )
  }
}
