import React from "react"

import { Icon } from "../icon/Icon"
import { LocaleContext } from "../i18n/LocaleContext"
import { HIGState } from "../../config/HIGState"
import { SoundManager } from "../../utilities/sound/SoundManager"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

export interface AlertLinkProps extends TestProps {
  href: string
}

export class AlertLink extends React.PureComponent<AlertLinkProps> {
  private readonly soundManager: SoundManager

  private readonly clickAudioFeedbackEnabled: boolean

  public constructor(props: AlertLinkProps) {
    super(props)

    this.soundManager = HIGState.soundManager
    this.clickAudioFeedbackEnabled = HIGState.clickAudioFeedbackEnabled

    this.handleClick = this.handleClick.bind(this)
  }

  public handleClick() {
    if (this.clickAudioFeedbackEnabled) {
      this.soundManager.play("confirmation")
    }
  }

  public render() {
    return (
      <LocaleContext.Consumer>
        {({ direction }) => (
          <a
            className="alert-link"
            href={this.props.href}
            onClick={this.handleClick}
            target="_blank"
            data-testid={renderTestID(this.props.testID)}
          >
            {this.props.children}
            <span className={`alert-link__icon-wrap alert-link__icon-wrap--${direction}`}>
              <Icon
                name="external-link"
                size={16}
              />
            </span>
          </a>
        )}
      </LocaleContext.Consumer>
    )
  }
}
