import React from "react"

import { ToteImage } from "./ToteImage"
import { BarcodeScanImage } from "./BarcodeScanImage"
import { ConveyorImage } from "./ConveyorImage"
import { ShippingLabelImage } from "./ShippingLabelImage"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"
import { getContainerVariant, ContainerVariant } from "./ContainerVariant"
import { ContainerImage } from "./ContainerImage"
import { getTierVariant, TierVariant } from "./TierVariant"
import { TierImage } from "./TierImage"
import { getPalletVariant, PalletVariant } from "./PalletVariant"
import { PalletImage } from "./PalletImage"
import { getDropZoneVariant, DropZoneVariant } from "./DropZoneVariant"
import { DropZoneImage} from "./DropZoneImage"
import { CageImage } from "./CageImage"
import { LocalizedString, Translator } from "../i18n/Translator"
import { withTranslator } from "../i18n/withTranslator"
import { DockImage } from "./DockImage"
import { getDockVariant, DockVariant} from "./DockVariant"
import { QRCodeScanImage } from "./QRCodeScanImage"

export type ImageName =
  "conveyor"
  | "tote"
  | "tote-on-conveyor"
  | "barcode-scan"
  | "qr-code-scan"
  | "shipping-label"
  | "tier"
  | "pallet"
  | "drop-zone"
  | "cage"
  | "package-csX-on-conveyor"
  | "dock"
// To add more variant options just do:
// export type ImageVariant = ContainerVariant | <NewVariantOption>
export type ImageVariant = ContainerVariant | TierVariant | PalletVariant |
  DropZoneVariant | DockVariant

export interface ImageProps extends TestProps {
  /**
   * Spacing between image and container, default is "25%".
   *
   * Only percentage units are supported. Option has an effect only if resizeMode is "contain".
   */
  spacing?: string

  /**
   * Resize mode, mimics CSS object-fit (default is "contain").
   *
   * @see https://css-tricks.com/on-object-fit-and-object-position/
   */
  resizeMode?: "none" | "contain"

  /**
   * Image name to display.
   */
  name: ImageName

  /**
   * More granular option for displaying images
   */
  variant?: ImageVariant

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator

  /**
   * Localized string for image alt text.
   *
   * Alt text is presentational if set to "".
   */
   altText?: LocalizedString | ""

  /**
   * Represent children as presentation. (Purely decorative content)
   *
   * Default is false.
   */
   presentationChildren?: boolean
}

/**
 * Image component that can show different images dependong on `name`.
 *
 * For some images like `barcode-scan` children of this component are passed
 * down to the image, for this example, as `barcode` text.
 *
 * This component is flexible and is designed to be used inside FlexLayout.Item.
 * By default it stretches to available space but keeps 25% padding.
 */
export class ImageBase extends React.PureComponent<ImageProps> {
  private readonly defaultSpacing = 0.25 // 25%

  private readonly bodyRef = React.createRef<HTMLDivElement>()

  public readSpacing() {
    const isValid = this.props.spacing && this.props.spacing.endsWith("%")
    // zero could be set without unit
    if (this.props.spacing === "0") {
      return 0
    }
    return isValid ? parseFloat(this.props.spacing!) / 100 : this.defaultSpacing
  }

  public handleResize() {
    const resizeMode = this.props.resizeMode || "contain"

    // this implementation has a limitation: it doesn't track container resize
    // if container resize tracking is needed then ResizeObserver can be used (with polyfill)
    if (this.bodyRef.current && resizeMode === "contain") {
      const rootEl = this.bodyRef.current
      const imageEl = this.bodyRef.current.firstChild as HTMLElement

      // To avoid dividing by 0 we will wait for the image to load,
      // before reading the offset dimensions.
      if (imageEl.offsetHeight === 0 && imageEl.offsetHeight === 0) {
        imageEl.onload = () => this.adjustScaling(imageEl, rootEl)
      } else {
        this.adjustScaling(imageEl, rootEl)
      }
    }
  }

  private adjustScaling(imageEl: HTMLElement, rootEl: HTMLElement) {
    const targetRatio = 1 - this.readSpacing()

    const vScale = rootEl.clientHeight * targetRatio / imageEl.offsetHeight
    const hScale = rootEl.clientWidth * targetRatio / imageEl.offsetWidth

    const scale = Math.min(vScale, hScale)

    imageEl.style.transform = `scale(${scale})`
  }

  public componentDidUpdate() {
    this.handleResize()
  }

  public componentDidMount() {
    this.handleResize()
  }

  public createImageComponent(
    presentationChildren: boolean,
    translator: Translator,
    altText?: LocalizedString | ""
  ) {
    switch (this.props.name) {
      case "conveyor":
        return <ConveyorImage altText={altText} translator={translator}/>

      case "tote":
        return this.props.variant ? (
          <ContainerImage
            altText={altText}
            containerVariant={getContainerVariant(this.props.variant)}
            translator={translator}
          />
        ) : <ToteImage altText={altText} translator={translator}/>

      case "tote-on-conveyor":
        return (
          <ConveyorImage
            altText={altText}
            item={getContainerVariant(this.props.variant || "tote")}
            translator={translator}
          />
        )

      case "package-csX-on-conveyor":
        return (
          <ConveyorImage
            altText={altText}
            item={getContainerVariant(this.props.variant || "package_csX")}
            translator={translator}
          />
        )

      case "barcode-scan":
        return (
          <BarcodeScanImage
            altText={altText}
            presentationChildren={presentationChildren}
            translator={translator}
          >
            {this.props.children}
          </BarcodeScanImage>
        )

      case "qr-code-scan":
        return (
          <QRCodeScanImage
            altText={altText}
            translator={translator}
          />
        )

      case "shipping-label":
        return (
          <ShippingLabelImage
            altText={altText}
            translator={translator}
          />
        )

      case "tier":
        return (
          <TierImage
            altText={altText}
            tierVariant={getTierVariant(this.props.variant || "full_tier")}
            translator={translator}
          />
        )

      case "pallet":
        return (
          < PalletImage
            altText={altText}
            palletVariant={getPalletVariant(this.props.variant || "pallet_height")}
            translator={translator}
          />
        )

      case "drop-zone":
        return (
          < DropZoneImage
            altText={altText}
            dropZoneVariant={getDropZoneVariant(this.props.variant || "drop_zone")}
            translator={translator}
          />
        )
      case "cage":
        return (
          <CageImage
            altText={altText}
            translator={translator}
          />
        )
      case "dock":
        return (
          < DockImage
            altText={altText}
            dockVariant={getDockVariant(this.props.variant || "door_closed")}
            translator={translator}
          />
        )

      default:
        throw new Error(`Unsupported image name: ${this.props.name}`)
    }
  }

  public render() {
    const { altText, testID, translator, presentationChildren } = this.props
    const image = this.createImageComponent(
      presentationChildren ? true : false,
      translator,
      altText,
    )

    return (
      <div
        className="image"
        ref={this.bodyRef}
        data-testid={renderTestID(testID)}
      >
        {image}
      </div>
    )
  }
}

export const Image = Object.assign(
  withTranslator(ImageBase), {
    displayName: "Image",
  }
)
