// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />
import React from "react"

import cases_per_full_tier from "../../resources/images/cases-per-full-tier.i.svg"
import cases_per_partial_tier from "../../resources/images/cases-per-partial-tier.i.svg"
import full_tier from "../../resources/images/full-tier.i.svg"
import { LocalizedString, Translator } from "../i18n/Translator"
import { TierVariant } from "./TierVariant"

export interface TierImageProps {
  /**
   * The tier type that defines the tier image to be rendered.
   */
  tierVariant: TierVariant

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Tier image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="tier").
 */
export class TierImage extends React.PureComponent<TierImageProps> {
  public render() {
    const { altText, translator } = this.props

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={getTierSource(this.props.tierVariant)}
        className="tier-image"
        alt={imageAltText}
      />
    )
  }
}

function getTierSource(tierVariant: TierVariant): string | undefined {

  switch (tierVariant) {
    case "cases_per_partial_tier": {
      return cases_per_partial_tier
    }
    case "cases_per_full_tier": {
      return cases_per_full_tier
    }
    case "full_tier": {
      return full_tier
    }
    default: {
      return undefined
    }
  }
}
