import React from "react"

import { ViewportCapsContext } from "../viewport/ViewportCapsContext"
import { ViewportCaps } from "../viewport/ViewportCaps"
import { LocaleContext } from "../../i18n/LocaleContext"
import { safeReactChildrenOnly } from "../../../utilities/react/safeReactChildrenOnly"

export interface ZoomLayoutProps {
  /**
   * Zoom scale value.
   *
   * By default it is unset and it causes auto scale based on screen size.
   * Specific float value can be set to enforce the scale.
   */
  value?: number | "auto" | "none"
}

/**
 * Zoom Layout.
 *
 * Zooms content based on scale map relative to viewport size.
 */
export class ZoomLayout extends React.PureComponent<ZoomLayoutProps> {
  public getScale(viewportCaps: ViewportCaps) {
    if (!this.props.value || this.props.value === "auto") {
      return viewportCaps.getZoomScale()
    }
    return this.props.value === "none" ? 1.0 : this.props.value
  }

  public render() {
    const child = safeReactChildrenOnly(this.props.children)

    return (
      <ViewportCapsContext.Consumer>
        {(viewportCaps) => (
          <LocaleContext.Consumer>
            {({ direction }) => {
              const scale = this.getScale(viewportCaps)

              // passthrough if scaling is not needed
              if (scale === 1.0) {
                return child
              }

              // size of container need to be reduced proportionally to scale
              // otherwise it will be scaled also and will fall out from layout
              const style: React.CSSProperties = {
                width: `${100 / scale}%`,
                height: `${100 / scale}%`,
                transform: `scale(${scale})`,
              }

              return (
                <div className={`zoom-layout zoom-layout--${direction}`} style={style}>
                  {child}
                </div>
              )
            }}
          </LocaleContext.Consumer>
        )}
      </ViewportCapsContext.Consumer>
    )
  }
}
