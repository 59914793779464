import React from "react"

import {
  DocumentIDType,
  DocumentType,
  PDFChildrenCallback,
  PrintDocFlowBaseOptions,
  PrintDocFlowOptions,
  ProblemSolveExtraArgs
} from "./PrintDocFlowLike"
import { PDFRequestState, PrintDocFlowRequest } from "./PrintDocFlowRequest"
import { withTranslator } from "../i18n/withTranslator"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface CustomLabelRequestProps extends PrintDocFlowBaseOptions {
  shippingID: string
  problemSolveExtraArgs: ProblemSolveExtraArgs
  children: PDFChildrenCallback

  // auto injected
  translator: Translator
}

export class PrintCustomLabelRequestBase
  extends React.PureComponent<CustomLabelRequestProps, PDFRequestState> {

    public constructor(props: CustomLabelRequestProps) {
      super(props)
    }

    private prepareProblemSolveExtraArgs() {
      const args = {...this.props.problemSolveExtraArgs}

      const potentialLocalizedStringKeys: Array<keyof ProblemSolveExtraArgs> = [
        "problemDescription",
        "problemInstructionsPre",
        "problemInstructionsPost",
        "shipmentExSDPre",
        "shipmentExSDPost"
      ]

      potentialLocalizedStringKeys.forEach((key) => {
        if (typeof args[key] === "object") {
          args[key] = this.props.translator.lookup(args[key] as LocalizedString)
        } else if (!args[key]) {
          args[key] = ""
        }
      })
      return JSON.stringify(args)
    }

    public render() {
      const printDocFlowOptions: PrintDocFlowOptions = {
        ...this.props,
        documentID: {
          type: DocumentIDType.SHIPMENT_ID,
          id: this.props.shippingID
        },
        metaData: {
          PROBLEM_SOLVE_EXTRA_ARGS: // Amazon ION Encoding
            "ProblemSolveExtraArgs::" + this.prepareProblemSolveExtraArgs()
        },
        documentType: DocumentType.PROBLEM_SOLVE
      }

      return (
        <PrintDocFlowRequest {...printDocFlowOptions}>
          {(status, errorDetail) => {
            return this.props.children(status, errorDetail)
          }}
        </PrintDocFlowRequest>
      )
    }

}

export const PrintCustomLabelRequest = Object.assign(
  withTranslator(PrintCustomLabelRequestBase), {
    displayName: "PrintCustomLabelRequest"
  }
)
