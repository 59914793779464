/**
 * Helper that holds device-specific metadata that could be used to apply custom
 * behavior targeting specific platform.
 */
export class DeviceDetector {
  public readonly isMC32W: boolean
  public readonly isFire: boolean
  public readonly isNative: boolean

  constructor() {
    const hasNavigator = typeof navigator !== "undefined"
    const hasUserAgent = hasNavigator && typeof navigator.userAgent !== "undefined"

    this.isMC32W = hasUserAgent && navigator.userAgent.indexOf("IEMobile/7.0") !== -1
    this.isFire = hasUserAgent && navigator.userAgent.indexOf("Silk") !== -1
    this.isNative = hasNavigator && navigator.product === "ReactNative"
  }

  /**
   * Inject device-specific CSS class names on document body to have an option
   * to override styles in CSS for specific target platform.
   */
  public injectBodyClassNames() {
    if (typeof document === "undefined") {
      throw new TypeError("DOM is not available")
    }

    const deviceClasses = []

    if (this.isMC32W) {
      deviceClasses.push("mc32w")
    }

    if (this.isFire) {
      deviceClasses.push("fire")
    }

    if (deviceClasses.length) {
      document.body.className += " " + deviceClasses.join(" ")
    }
  }

  /**
   * Check if device has physical keyboard.
   *
   * Keyboard shortcuts are useless if there is no physical keyboard.
   */
  public hasPhysicalKeyboard() {
    // Assume that physical keyboard is attached to all devices but not to Kindle Fire.
    return !this.isFire && !this.isNative
  }
}

export const deviceDetector = new DeviceDetector()
