import React from "react"

import { renderTestID } from "../helpers/renderTestID"
import { TestProps } from "../helpers/TestProps"
import { LocalizedString, Translator } from "../i18n/Translator"
import { withTranslator } from "../i18n/withTranslator"
import { Icon } from "../icon/Icon"
import { TextLayout } from "../layout/text/TextLayout"

export type CustomImageSpacing = "none" | "default"

interface Props extends TestProps {
  /**
   * Custom image spacing.
   *
   * - `none` - 0px
   * - `default` - 5%
   */
  spacing?: CustomImageSpacing

  /**
   * Image URL.
   */
  url?: string

  /**
   * Translatable
   */
  translator: Translator

  /**
   * Custom image alt text.
   */
  altText?: LocalizedString
}

interface State {
  status: "loading" | "loaded" | "failed"
}

/**
 * Renders image from any URL.
 *
 * Only absolute URLs are accepted with schema "http://", "https://", "//" (preferred),
 * or "/" (referenced from domain root, used internally).
 *
 * Schema "//" is preferred because it allows to avoid unsecure calls on secured pages.
 */
class CustomImageBase extends React.PureComponent<Props, State> {
  private readonly imageRef = React.createRef<HTMLImageElement>()

  public constructor(props: Props) {
    super(props)

    this.state = {
      status: props.url ? "loading" : "failed",
    }

    this.handleImageError = this.handleImageError.bind(this)
    this.handleImageLoad = this.handleImageLoad.bind(this)
  }

  public handleImageError() {
    this.setState({ status: "failed" })
  }

  public handleImageLoad() {
    this.setState({ status: "loaded" })
  }

  public componentDidUpdate(prevProps: Props) {
    // invalidate status if image url has changed
    if (prevProps.url !== this.props.url && this.state.status !== "loading") {
      this.setState({ status: "loading" })
    }
  }

  public parseSpacing(spacing?: string) {
    return spacing === "none" ? 0 : "5%"
  }

  public render() {
    const { translator, altText, url, spacing } = this.props
    const { status } = this.state

    const className = "custom-image"
      + ` custom-image--${status}`
      + ` custom-image--spacing-${spacing || "default"}`

    const isValidURL = url && /^((https?:)?\/\/|\/)/.test(url)

    return (
      <div
        className={className}
        data-testid={renderTestID(this.props.testID)}
      >
        {isValidURL && this.state.status !== "failed" && (
          <img
            ref={this.imageRef}
            src={url}
            alt={altText ? translator.lookup(altText) : ""}
            className="custom-image__inner"
            onError={this.handleImageError}
            onLoad={this.handleImageLoad}
          />
        )}
        {isValidURL && this.state.status === "failed" && (
          <TextLayout hAlign="center" vAlign="middle">
            <Icon name="x" size={32} color="light-gray" />
          </TextLayout>
        )}
        {!isValidURL && !url && (
          <TextLayout hAlign="center" vAlign="middle">
            <Icon name="image" size={32} color="light-gray" />
          </TextLayout>
        )}
        {!isValidURL && url && (
          <TextLayout hAlign="center" vAlign="middle">
            <Icon name="x" size={32} color="light-gray" />
          </TextLayout>
        )}
      </div>
    )
  }
}

export const CustomImage = Object.assign(
  withTranslator(CustomImageBase), {
    displayName: "CustomImage"
  }
)
