import { BrowserUtils } from "./BrowserUtils"

/**
 * Checks if position: sticky is natively supported.
 */
export function isNativeStickySupported() {
  if (!document.head) {
    return false
  }
  document.head.style.position = "sticky"
  const supported = document.head.style.position === "sticky"
  document.head.style.position = ""
  return supported
}

interface StickyOptions {
  stickyClassName?: string
}

/**
 * Provides sticky headers emulation.
 *
 * Usage:
 *
 * ```html
 * <div id="container">
 *   <div class="sticky">Header 1</div>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 *   <div>Item 4</div>
 *   <div>Item 5</div>
 *   <div class="header sticky">Header 2</div>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 *   <div>Item 4</div>
 *   <div>Item 5</div>
 *   <div class="header sticky">Header 3</div>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 *   <div>Item 4</div>
 *   <div>Item 5</div>
 * </div>
 * ```
 *
 * ```js
 * // obtain link to container
 * const container = document.getElementById("container")
 * // install
 * const removeSticky = sticky(container)
 * // uninstall
 * removeSticky()
 * ```
 */
export function sticky(container: HTMLDivElement, options: StickyOptions = {}) {
  const stickyClassName = options.stickyClassName || "sticky"

  const stickyRegExp = new RegExp("\\b" + stickyClassName + "\\b")

  let stickyElement: HTMLDivElement | null = null
  let stubElement: HTMLDivElement | null = null

  const scroll = () => {
    const scrollTop = container.scrollTop

    let item: HTMLDivElement | null = container.firstChild as HTMLDivElement
    let prevItem: HTMLDivElement | null = null

    while (item) {
      if (stickyRegExp.test(item.className)) {
        if (item !== stickyElement && item.offsetTop >= scrollTop) {
          let nonStickyElement = item
          const newStickyElement = prevItem

          if (stickyElement && newStickyElement !== stickyElement) {
            if (nonStickyElement === stubElement) {
              nonStickyElement = stickyElement
            }

            const stickyElementStyles = stickyElement.style
            stickyElementStyles.position = ""
            stickyElementStyles.width = ""
            stickyElementStyles.top = ""
            stickyElement = null

            if (stubElement && stubElement.parentNode) {
              stubElement.parentNode.removeChild(stubElement)
              stubElement = null
            }
          }

          if (newStickyElement && !stickyElement) {
            stickyElement = newStickyElement

            const stickyElementStyles = stickyElement.style
            stickyElementStyles.position = "absolute"
            stickyElementStyles.width = "100%"

            if (stickyElement.parentNode) {
              stubElement = document.createElement("div")
              stubElement.className = stickyElement.className
              stickyElement.parentNode.insertBefore(stubElement, stickyElement)
            }
          }

          if (stickyElement) {
            const stickyElementBox = stickyElement.getBoundingClientRect()
            const stickyElementHeight = stickyElementBox.bottom - stickyElementBox.top
            const beforeNonStickyTop = nonStickyElement.offsetTop - stickyElementHeight
            const stickyTop = beforeNonStickyTop > scrollTop ? scrollTop : beforeNonStickyTop
            const stickyElementStyles = stickyElement.style
            stickyElementStyles.top = `${stickyTop}px`
          }

          return
        }

        prevItem = item
      }

      item = item.nextSibling as HTMLDivElement
    }
  }

  const scrollWrapper = () => {
    requestAnimationFrame(scroll)
  }

  const install = () => {
    container.style.position = "relative"
    BrowserUtils.addEventListener(container, "scroll", scrollWrapper)
  }

  const uninstall = () => {
    container.style.position = ""
    BrowserUtils.removeEventListener(container, "scroll", scrollWrapper)
  }

  install()

  return uninstall
}
