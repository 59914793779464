// tslint:disable: deprecation strict-type-predicates triple-equals

/**
 * Retieves key code from KeyboardEvent (normalized across different browsers).
 */
export function getEventKeyCode(event: KeyboardEvent) {
  return event.which != null && event.which != 0
    ? event.which
    : (event.charCode != null && event.charCode != 0 ? event.charCode : event.keyCode)
}
