import React from "react"

import {
  PDFChildrenCallback,
  PDFRequestStatus,
  PrintDocFlowError,
  PrintDocFlowLike,
  PrintDocFlowOptions
} from "./PrintDocFlowLike"
import { PrintDocFlowContext } from "./PrintDocFlowContext"

/**
 * Print Document Flow printing status.
 *
 * The status is set to READY once the client is created.
 * It is then updated as the requests go through
 */

export interface PDFRequestProps extends PrintDocFlowOptions {
  /**
   * Render callback that provides print/hide status to children.
   */
  children: PDFChildrenCallback
}

export interface PDFRequestState {
  status: PDFRequestStatus
  errorDetail?: PrintDocFlowError
}

/**
 * Executes print request using PrintDocumentFlow context, maintains printing status
 * (state) and provides status (state) as render callback.
 */
export class PrintDocFlowRequest
  extends React.PureComponent<PDFRequestProps, PDFRequestState> {

  private PDFClient?: PrintDocFlowLike

  public constructor(props: PDFRequestProps) {
    super(props)
    this.state = {
      status: PDFRequestStatus.READY
    }
  }

  private sanitizeData(): PrintDocFlowOptions {
    const pdfOpts = this.props

    return {
      printerName: pdfOpts.printerName,
      printRequestID: pdfOpts.printRequestID,
      documentID: pdfOpts.documentID,
      documentType: pdfOpts.documentType,
      metaData: pdfOpts.metaData,
      printerConfiguration: pdfOpts.printerConfiguration
    }
  }

  public componentDidMount() {
    if (this.state.status !== PDFRequestStatus.READY) {
      return
    }
    this.setState({ status: PDFRequestStatus.NOT_STARTED }, () =>
      this.PDFClient?.print(
        this.sanitizeData(),
        (status, errorDetail) => this.setState({
          status,
          errorDetail
        })
      )
    )
  }

  public componentDidUpdate() {
    if (this.state.status !== PDFRequestStatus.READY) {
      return
    }
    this.setState({ status: PDFRequestStatus.NOT_STARTED }, () =>
      this.PDFClient?.print(
        this.sanitizeData(),
        (status, errorDetail) => this.setState({
          status,
          errorDetail
        })
      )
    )
  }

  public render() {
    return (
      <PrintDocFlowContext.Consumer>
        {(PDFClient) => {
          this.PDFClient = PDFClient

          return this.props.children(this.state.status, this.state.errorDetail)
        }}
      </PrintDocFlowContext.Consumer>
    )
  }
}
