import React from "react"

import { PaddingProps, convertPaddingPropsToStyle } from "../../helpers/parsePadding"
import { LocaleContext, Direction } from "../../i18n/LocaleContext"
import { ThemeContext, Theme, BackgroundColor } from "../../theme/ThemeContext"
import { Size, parseSize } from "../../helpers/parseSize"
import { reactTypeFlagCheck } from "../../helpers/reactTypeFlagCheck"
import { LimitLayout } from "../limit/LimitLayout"
import { safeReactChildrenOnly } from "../../../utilities/react/safeReactChildrenOnly"
import { DangerousProps } from "../../helpers/DangerousProps"
import { TestProps } from "../../helpers/TestProps"
import { renderTestID } from "../../helpers/renderTestID"

// used by FlexLayout - prohibited from using outside of this file
export interface FlexLayoutItemBaseInternalProps {
  isColumn?: boolean
  direction?: Direction
  theme?: Theme
}

export interface FlexLayoutItemBaseProps extends FlexLayoutItemBaseInternalProps {
  /**
   * Set size of the item.
   *
   * @see parseSize() for details
   */
  size?: Size

  /**
   * Hide item.
   */
  hidden?: boolean
}

export interface FlexLayoutItemProps extends FlexLayoutItemBaseProps,
  PaddingProps, DangerousProps, TestProps {

  /**
   * Wrap content into LimitLayout that will prevent item to grow beyound
   * automatically detected width.
   *
   * Usefull for text areas to prevent growing on ultra wide screens.
   */
  limit?: boolean

  /**
   * Item background color.
   * `backgroundColor` is on deprecation path. Use `Segment`.
   */
  backgroundColor?: BackgroundColor

  /**
   * Round corners.
   */
  rounded?: boolean

  /**
   * Run custom callback on layout item click.
   *
   * Needed for compatibility with older code. Will be removed in future.
   *
   * @deprecated
   */
  onClick?: () => void
}

export class FlexLayoutItem extends React.PureComponent<FlexLayoutItemProps> {
  public static isFlexLayoutItem = true

  public static defaultProps = {
    size: { unit: "fr", value: 1 },
  }

  public createStyle() {
    const size = parseSize(this.props.size!, this.props.theme!)

    const style: React.CSSProperties =
      convertPaddingPropsToStyle(this.props, this.props.theme!, this.props.direction!)

    if (size.unit === "auto") {
      // do nothing, css flexbox will figure out width/height
    } else if (size.unit === "px" || size.unit === "rem") {
      style[this.props.isColumn ? "height" : "width"] = `${size.value}${size.unit}`
    } else if (size.unit === "fr") {
      style.flex = size.value
    }

    if (this.props.dangerousStyle) {
      Object.assign(style, this.props.dangerousStyle)
    }

    return style
  }

  public render() {
    const className = "flex-layout__item"
      + (this.props.hidden ? " flex-layout__item--hidden" : "")
      + (this.props.rounded ? " flex-layout__item--rounded" : "")
      + (this.props.backgroundColor ? ` bg-${this.props.backgroundColor}` : "")
      + (this.props.dangerousClassName ? ` ${this.props.dangerousClassName}` : "")

    let content = safeReactChildrenOnly(this.props.children)

    if (this.props.limit) {
      content = <LimitLayout flex={true}>{content}</LimitLayout>
    }

    return (
      <div
        className={className}
        style={this.createStyle()}
        onClick={this.props.onClick}
        data-testid={renderTestID(this.props.testID)}
      >
        {content}
      </div>
    )
  }
}

export interface FlexLayoutSpacerProps extends FlexLayoutItemBaseProps {
  children?: undefined
}

export class FlexLayoutSpacer extends React.PureComponent<FlexLayoutSpacerProps> {
  public static isFlexLayoutSpacer = true

  public static defaultProps = {
    size: { unit: "px", value: 0 },
  }

  public createStyle() {
    const size = parseSize(this.props.size!, this.props.theme!)

    const style: React.CSSProperties = {}

    if (size.unit === "px" || size.unit === "rem") {
      style[this.props.isColumn ? "height" : "width"] = `${size.value}${size.unit}`
    } else if (size.unit === "fr") {
      style.flex = size.value
    }

    return style
  }

  public render() {
    const className = "flex-layout__spacer"
      + (this.props.hidden ? " flex-layout__spacer--hidden" : "")

    return (
      <div className={className} style={this.createStyle()} />
    )
  }
}

export interface FlexLayoutSplitterProps extends FlexLayoutItemBaseProps {
  children?: undefined
}

export class FlexLayoutSplitter extends React.PureComponent<FlexLayoutSplitterProps> {
  public static isFlexLayoutSplitter = true

  public static defaultProps = {
    size: { unit: "px", value: 1 },
  }

  public createStyle() {
    const size = parseSize(this.props.size!, this.props.theme!)

    const style: React.CSSProperties = {}

    if (size.unit === "px" || size.unit === "rem") {
      style[this.props.isColumn ? "height" : "width"] = `${size.value}${size.unit}`
    } else if (size.unit === "fr") {
      style.flex = size.value
    }

    return style
  }

  public render() {
    const className = "flex-layout__splitter"
      + (this.props.hidden ? " flex-layout__splitter--hidden" : "")

    return <div className={className} style={this.createStyle()} />
  }
}

export interface FlexLayoutProps extends PaddingProps, DangerousProps, TestProps {
  /**
   * Flex direction.
   *
   * Similar to css `flex-direction`.
   */
  direction: "row" | "column" | "column-reverse" | "row-reverse" | "row-ltr" | "row-rtl"

  /**
   * Render it hidden if needed.
   */
  hidden?: boolean

  /**
   * @deprecated
   * Background color.
   * `backgroundColor` is on deprecation path. Use `Segment`.
   */
  backgroundColor?: BackgroundColor

  /**
   * Round corners.
   */
  rounded?: boolean

  /**
   * Vertical alignment
   */
  vAlign?: "top" | "bottom" | "middle"
}

type FlexLayoutAnyItemProps = FlexLayoutItemProps | FlexLayoutSpacerProps | FlexLayoutSplitterProps

type FlexboxDirection = "row" | "row-reverse" | "column" | "column-reverse"

export class FlexLayout extends React.PureComponent<FlexLayoutProps> {
  public static Item = FlexLayoutItem
  public static Spacer = FlexLayoutSpacer
  public static Splitter = FlexLayoutSplitter

  public static defaultProps = {
    direction: "row",
  }

  public createStyle(direction: Direction, theme: Theme) {
    const style: React.CSSProperties =
      convertPaddingPropsToStyle(this.props, theme, direction)

    if (this.props.dangerousStyle) {
      Object.assign(style, this.props.dangerousStyle)
    }

    return style
  }

  public createChildren(direction: Direction, theme: Theme) {
    const isColumn =
      this.props.direction === "column"
      || this.props.direction === "column-reverse"

    return React.Children.map(this.props.children, (child) => {
      if (React.isValidElement<FlexLayoutAnyItemProps>(child)
        && (reactTypeFlagCheck(child.type, "isFlexLayoutItem")
          || reactTypeFlagCheck(child.type, "isFlexLayoutSpacer")
          || reactTypeFlagCheck(child.type, "isFlexLayoutSplitter"))
      ) {
        return React.cloneElement(child, { isColumn, direction, theme })
      }
    })
  }

  public getCssFlexDirection(direction: Direction): FlexboxDirection {
    switch (this.props.direction) {
      case "row":
      case "row-reverse":
      case "column":
      case "column-reverse":
        return this.props.direction

      case "row-ltr":
        return (direction === "ltr") ? "row" : "row-reverse"

      case "row-rtl":
        return (direction === "ltr") ? "row-reverse" : "row"
    }
  }

  public render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <LocaleContext.Consumer>
            {({ direction }) => {
              const className = "flex-layout"
                + ` flex-layout--${this.getCssFlexDirection(direction)}`
                + (this.props.hidden ? " flex-layout--hidden" : "")
                + (this.props.rounded ? " flex-layout--rounded" : "")
                + (this.props.vAlign ? ` flex-layout--v-align-${this.props.vAlign}` : "")
                + (this.props.backgroundColor ? ` bg-${this.props.backgroundColor}` : "")
                + (this.props.dangerousClassName ? ` ${this.props.dangerousClassName}` : "")

              return (
                <div
                  className={className}
                  style={this.createStyle(direction, theme)}
                  data-testid={renderTestID(this.props.testID)}
                >
                  {this.createChildren(direction, theme)}
                </div>
              )
            }}
          </LocaleContext.Consumer>
        )}
      </ThemeContext.Consumer>
    )
  }
}
