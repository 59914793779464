import React from "react"

import { ListOptionsContext } from "./ListOptionsContext"
import { ScrollableLayout } from "../layout/scrollable/ScrollableLayout"
import { HIGState } from "../../config/HIGState"
import { SoundManager } from "../../utilities/sound/SoundManager"
import { HotKeyResponder, HotKeySet } from "../keyboard/HotKeyResponder"
import { memoizeOne } from "../../utilities/memoizeOne"
import { ListItemHotKey } from "./ListItemHotKey"
import { ListItemIcon } from "./ListItemIcon"
import { ListItemSecondaryIcon } from "./ListItemSecondaryIcon"
import { addEventListener, removeEventListener } from "../../utilities/browser/addEventListener"
import { getEventKeyCode } from "../../utilities/browser/getEventKeyCode"
import { KeyCode } from "../keyboard/KeyCode"
import { HotKey } from "../keyboard/HotKey"

export interface ListItemProps {
  selected?: boolean
  menu?: boolean
  hidden?: boolean
  button?: boolean
  onClick?: () => void
  _subheader?: boolean
  hasChildren?: boolean
}

export class ListItem extends React.PureComponent<ListItemProps> {
  private readonly soundManager: SoundManager

  private readonly clickAudioFeedbackEnabled: boolean

  public constructor(props: ListItemProps) {
    super(props)

    this.soundManager = HIGState.soundManager
    this.clickAudioFeedbackEnabled = HIGState.clickAudioFeedbackEnabled

    this.handleClick = this.handleClick.bind(this)
    this.handleHotKey = this.handleHotKey.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)

    this.getInferredHotKey = memoizeOne(this.getInferredHotKey.bind(this))
    this.reorderChildren = memoizeOne(this.reorderChildren.bind(this))
  }

  public handleClick() {
    if (!this.props.button) {
      return
    }

    if (this.clickAudioFeedbackEnabled) {
      this.soundManager.play("confirmation")
    }

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  public handleKeyDown(srcEvent: Event) {
    const event = srcEvent as KeyboardEvent
    const keyCode = getEventKeyCode(event)
    if (keyCode === KeyCode.Enter || keyCode === KeyCode.Space) {
      // Enter and Space key activation are necessary for A11y
      event.preventDefault()
      this.handleClick()
    }
  }

  public reorderChildren(children: React.ReactNode) {
    const sideItems: React.ReactNode[] = []
    const otherItems: React.ReactNode[] = []

    React.Children.forEach(children, (item) => {
      if (React.isValidElement(item)
        && (item.type === ListItemIcon
          || item.type === ListItemSecondaryIcon)
      ) {
        sideItems.push(item)
      } else {
        otherItems.push(item)
      }
    })

    return [sideItems, otherItems]
  }

  public handleHotKey() {
    // explicitly mark hot key as not handled
    if (this.props.hidden) {
      return false
    }

    this.handleClick()
  }

  public getInferredHotKey(children: React.ReactNode): HotKeySet | undefined {
    const items = React.Children.toArray(children)

    for (const item of items) {
      if (React.isValidElement(item)
        && (item.type === ListItemHotKey)
        && item.props.keyCode
      ) {
        return item.props.keyCode
      }
    }
  }

  // Leverage focus/blur events to register/deregister key events
  // so handleKeyDown won't be triggered unnecessarily
  public onFocus() {
    addEventListener(document, "keydown", this.handleKeyDown)
  }

  public onBlur() {
    removeEventListener(document, "keydown", this.handleKeyDown)
  }

  public componentWillUnmount() {
    removeEventListener(document, "keydown", this.handleKeyDown)
  }

  public render() {
    return (
      <ListOptionsContext.Consumer>
        {({ itemSize, stickyHeaders }) => {
          const className = "list-item"
            + ` list-item--size-${itemSize}`
            + (this.props.selected ? " list-item--selected" : "")
            + (this.props.button ? " list-item--button" : "")
            + (this.props.hidden ? " list-item--hidden" : "")
            + (this.props._subheader ? " list-item--subheader" : "")
            + (this.props._subheader && stickyHeaders
                ? ` ${ScrollableLayout.nativeSticky ? "list-item--sticky" : "sticky"}`
                : "")

          const innerClassName = "list-item__inner"
            + (this.props.button ? " list-item__inner--button" : "")
            + (this.props.menu ? " list-item__inner--menu" : "")

          const [sideItems, otherItems] = [this.props.children, null]

          // only clickable (like button) items are allowed to respond on hot keys
          const hotKey = this.props.button
            ? this.getInferredHotKey(this.props.children)
            : undefined

          // Have to use div instead of button cause arbitrary elements can appear as children
          const content = (
            <div
              className={className}
              role={this.props.menu ? "menuitem" : "listitem"}
            >
              <div
                className={innerClassName}
                onClick={this.handleClick}
                role={this.props.button ? "button" : undefined}
                tabIndex={this.props.button ? -1 : undefined}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                accessKey={hotKey ? HotKey.describeHotKeySet(hotKey) : undefined}
                aria-haspopup={this.props.hasChildren}
              >
                {sideItems}
                {otherItems}
              </div>
            </div>
          )

          if (hotKey) {
            return (
              <HotKeyResponder
                keyCode={hotKey}
                onKeyPress={this.handleHotKey}
              >
                {content}
              </HotKeyResponder>
            )
          }

          return content
        }}
      </ListOptionsContext.Consumer>
    )
  }
}
