import React from "react"

import { LocaleContext } from "../i18n/LocaleContext"

import { reactTypeFlagCheck } from "../helpers/reactTypeFlagCheck"
import { isReactPlaceholder } from "../helpers/isReactPlaceholder"

import { CartSlotColor } from "../cart/CartSlotColor"
import { StatusBarProgress } from "./StatusBarProgress"
import { textColor } from "../../utilities/color/textColor"
import { memoizeOne } from "../../utilities/memoizeOne"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

/**
 * The semantic color variants (blue, red, orange, and green) come from `CartSlotVariant`.
 */
export type StatusBarVariant =
  "default"
  | "warning"
  | "error"
  | CartSlotColor

export interface StatusBarItemProps extends TestProps {
  align?: "natural" | "reverse"
  variant?: StatusBarVariant
  color?: string // css hex format, will override variant.
  bold?: boolean
  italic?: boolean
  hidden?: boolean
}

export class StatusBarItem extends React.PureComponent<StatusBarItemProps> {
  public static isStatusBarItem = true

  public static defaultProps = {
    align: "natural",
  }

  constructor(props: StatusBarItemProps) {
    super(props)
    this.getStyle = memoizeOne(this.getStyle.bind(this))
  }

  public getStyle(color: string | undefined): React.CSSProperties | undefined {
    if (color) {
      const style: React.CSSProperties = {}
      style.backgroundColor = color
      return style
    } else {
      return undefined
    }
  }

  public render() {
    let className = "status-bar-item"
      + ` status-bar-item--align-${this.props.align}`
      + (this.props.bold ? ` status-bar-item--bold` : "")
      + (this.props.italic ? ` status-bar-item--italic` : "")
      + (this.props.hidden ? ` status-bar-item--hidden` : "")

    if (this.props.color) {
      className += ` status-bar-item--foreground-${textColor(this.props.color)}`
    } else {
      className += (this.props.variant ? ` status-bar-item--variant-${this.props.variant}` : "")
    }

    return (
      <div
        className={className}
        style={this.getStyle(this.props.color)}
        data-testid={renderTestID(this.props.testID)}
      >
        {this.props.children}
      </div>
    )
  }
}

export interface StatusBarProps extends TestProps {
  variant?: StatusBarVariant
  color?: string // css hex format, will override variant.
}

export class StatusBar extends React.PureComponent<StatusBarProps> {
  public static defaultHeight = 24

  public static Item = StatusBarItem
  public static Progress = StatusBarProgress

  public static defaultProps = {
    variant: "default",
  }

  constructor(props: StatusBarProps) {
    super(props)
    this.getStyle = memoizeOne(this.getStyle.bind(this))
  }

  public getReorderedChildren() {
    const naturalChildren: React.ReactChild[] = []
    const reverseChildren: React.ReactChild[] = []
    const progressChildren: React.ReactChild[] = []

    React.Children.forEach(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        if (reactTypeFlagCheck(child.type, "isStatusBarItem")) {
          const childProps = child.props as StatusBarItemProps
          if (childProps.align === "natural") {
            naturalChildren.push(child)
          } else {
            reverseChildren.push(child)
          }
        } else if (reactTypeFlagCheck(child.type, "isStatusBarProgress")) {
          if (this.props.color) {
            progressChildren.push(
              React.cloneElement(child as React.ReactElement, {
                color: child.props.color || textColor(this.props.color)
              })
            )
          } else {
            progressChildren.push(child)
          }

          if (progressChildren.length > 1) {
            console.warn("More than one StatusBar.Progress is not supported on StatusBar!")
          }
        } else {
          console.warn("Unsupported child passed to StatusBar component:", child)
        }
      } else if (!isReactPlaceholder(child)) {
        console.warn("Invalid child passed to StatusBar component:", child)
      }
    })

    // reverse items should be also reversely placed in DOM due to reverse css
    // float applied to them
    reverseChildren.reverse()

    return [naturalChildren, reverseChildren, progressChildren]
  }

  public getStyle(color: string | undefined): React.CSSProperties | undefined {
    if (color) {
      const style: React.CSSProperties = {}
      style.backgroundColor = color
      return style
    } else {
      return undefined
    }
  }

  public render() {
    const children = this.getReorderedChildren()

    return (
      <LocaleContext.Consumer>
        {({ direction }) => {
          let className = "status-bar"
            + ` status-bar--dir-${direction}`

          if (this.props.color) {
            className += ` status-bar--foreground-${textColor(this.props.color)}`
          } else {
            className += ` status-bar--variant-${this.props.variant}`
          }

          return (
            <div
              className={className}
              style={this.getStyle(this.props.color)}
              data-testid={renderTestID(this.props.testID)}
            >
              {children}
            </div>
          )
        }}
      </LocaleContext.Consumer>
    )
  }
}
