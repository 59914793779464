/**
 * List of locale fallback rules.
 *
 * Keep in mind, en_US should be always included in list of locale fallback list.
 *
 * While it is possible to have multiple fallback locales for each locale
 * is is highly recommended to not use this feature to save bandwidth and
 * make application startup faster.
 */
const localeFallbackRules: { [locale: string]: string[] } = {
  // French reverse fallback
  fr_FR: ["fr_CA"],
  fr_CA: ["fr_FR"],
  // Arabic reverse fallback
  ar_AE: ["ar_SA"],
  ar_SA: ["ar_AE"],
  // Spanish reverse fallback
  es_ES: ["es_MX"],
  es_US: ["es_MX"],
  es_MX: ["es_ES"],
  es_CO: ["es_ES"],
  es_CL: ["es_ES"],
  es_AR: ["es_ES"],
  // Protuguese reverse fallback
  pt_PT: ["pt_BR"],
  pt_BR: ["pt_PT"],
}

/**
 * Detects recommended fallback locales for passed locale in the order of priority.
 */
export function detectFallbackLocales(currentLocale: string, defaultLocale: string) {
  if (currentLocale === defaultLocale) {
    return []
  }
  const fallbackLocales = localeFallbackRules[currentLocale] || []
  return fallbackLocales.findIndex((locale) => locale === defaultLocale) === -1 ?
   [...fallbackLocales, defaultLocale] :
   [...fallbackLocales]
}
