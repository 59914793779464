import React from "react"

import { PrintMonOptions, PrintMonLike } from "./PrintMonLike"
import { PrintMonContext } from "./PrintMonContext"

/**
 * PrintMon printing status.
 *
 * Status `ready` is momentary updated to `inProgress` so the same translation
 * string could be used for both `ready` and in `inProgress` statuses.
 */
export type PrintMonRequestStatus = "ready" | "inProgress" | "ok" | "failed"

export interface PrintMonRequestProps extends PrintMonOptions {
  /**
   * Enable additional `hidden` status that is triggered after some time
   * after successful print. Designed to auto hide print status message
   * once printing is completed.
   */
  autoHide?: boolean

  /**
   * Render callback that provides print/hide status to children.
   */
  children?: (status: PrintMonRequestStatus, hidden: boolean) => React.ReactNode | null
}

export interface PrintMonRequestState {
  status: PrintMonRequestStatus
  hidden: boolean
}

/**
 * Executes print request using PrintMon context, maintains printing status
 * (state) and provides status (state) as render callback.
 */
export class PrintMonRequest
  extends React.PureComponent<PrintMonRequestProps, PrintMonRequestState> {

  private readonly hideDelay = 3000 // as per AFT UX

  private lastPrintMon?: PrintMonLike

  private hideTimeout?: number

  private mounted = false

  public constructor(props: PrintMonRequestProps) {
    super(props)
    this.state = { status: "ready", hidden: false }
  }

  public print() {
    if (this.lastPrintMon) {
      this.lastPrintMon.print(this.props)
        .then(() => {
          if (this.mounted) {
            this.setState({ status: "ok" }, () => {
              if (this.props.autoHide && this.mounted) {
                this.hideTimeout = window.setTimeout(() => {
                  if (this.mounted) {
                    this.setState({ hidden: true })
                  }
                }, this.hideDelay)
              }
            })
          }
        })
        .catch(() => {
          if (this.mounted) {
            this.setState({ status: "failed" })
          }
        })
    }
  }

  public componentWillUnmount() {
    this.mounted = false

    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout)
    }
  }

  public componentDidMount() {
    this.mounted = true

    if (this.state.status === "ready") {
      this.setState({ status: "inProgress", hidden: false }, () => this.print())
    }
  }

  public componentDidUpdate() {
    if (this.state.status === "ready") {
      this.setState({ status: "inProgress", hidden: false }, () => this.print())
    }
  }

  public render() {
    return (
      <PrintMonContext.Consumer>
        {(printMon) => {
          this.lastPrintMon = printMon

          if (!this.props.children) {
            return null
          }

          return this.props.children(this.state.status, this.state.hidden)
        }}
      </PrintMonContext.Consumer>
    )
  }
}
