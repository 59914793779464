// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />
import React from "react"

import shippingLabelImage from "../../resources/images/shipping-label.i.svg"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface ShippingLabelImageProps {

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Shipping Label image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="shipping-label").
 */
export class ShippingLabelImage extends React.PureComponent<ShippingLabelImageProps> {
  public render() {
    const { altText, translator } = this.props

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={shippingLabelImage}
        className="shipping-label-image"
        alt={imageAltText}
      />
    )
  }
}
