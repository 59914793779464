/**
 * Function to return first focusable element
 */
export function getFirstFocusableElement(
  focusableSelectors: string,
  container: Element | Document
) {
  return container.querySelector(focusableSelectors) as HTMLElement
}

/**
 * Function to return last focusable element
 */
export function getLastFocusableElement(
  focusableSelectors: string,
  container: Element | Document
) {
  const focusableElements = container.querySelectorAll(focusableSelectors)
  return focusableElements[focusableElements.length - 1] as HTMLElement
}
