import React from "react"
import { Switch as BaseSwitch } from "@amzn/alchemy-components-react"
import { IconSize } from "../icon/IconSize"
import { Icon } from "../icon/Icon"
import { generateID } from "../../utilities/uuid"
import { LocalizedString, Translator } from "../i18n/Translator"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"
import { LocalizedText } from "../text/LocalizedText"
import { HotKey } from "../keyboard/HotKey"
import { HotKeyResponder, HotKeySet } from "../keyboard/HotKeyResponder"
import { TranslatorContext } from "../i18n/TranslatorContext"
import { LocaleContext, Locale } from "../i18n/LocaleContext"

BaseSwitch.displayName = "AlchemySwitch"

export type SwitchSize = "sm" | "md" | "lg"

export type SwitchVariant =
  "switch" | "lock"

export type SwitchVisualState =
  "default" | "hover" | "checked" | "focused" | "disabled"

export const iconSizeMap: Record<SwitchSize, IconSize> = {
  sm: 16,
  md: 20,
  lg: 28,
}

interface SwitchProps extends TestProps {
  /**
   * Defaults to switch
   */
  variant?: SwitchVariant
  /**
   * The key for the Switch's state when submitting the containing form
   */
  name?: string

  size?: SwitchSize
  /**
   * The default state
   */
  initialChecked?: boolean
  /**
   * The label for screen reader
   */
  srOnlyLabel?: LocalizedString
  /**
   * Hot key or set of hot keys that toggles the checked state
   */
  hotKey?: HotKeySet

  disabled?: boolean

  id?: string
  onChange?: (checked: boolean) => void
  /**
   * Force visual state for demo purposes. Not supported any more.
   */
  forceState?: SwitchVisualState
}

interface SwitchState {
  id: string
  checked: boolean
}

export class Switch extends React.PureComponent<SwitchProps, SwitchState> {
  private readonly defaultSize = "md"

  constructor(props: SwitchProps) {
    super(props)

    this.state = {
      id: this.props.id || generateID(),
      checked: this.props.initialChecked || false
    }

    this.inputHandler = this.inputHandler.bind(this)
    this.handleHotKey = this.handleHotKey.bind(this)
  }

  public inputHandler(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.disabled) {
      return
    }

    const checked = e.target.checked
    this.setState({ checked }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.checked)
      }
    })
  }

  public handleHotKey() {
    if (this.props.disabled) {
      return false
    }

    this.setState((prevState) => ({ checked: !prevState.checked }), () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.checked)
      }
    })
  }

  private renderWithAlchemy(translator: Translator, { direction }: Locale) {

    const checked = this.state.checked
    const size = this.props.size || this.defaultSize
    const iconSize = iconSizeMap[size]
    const wrapperClassName = `switch-wrapper switch-wrapper--size-${size}`

    const lockClassName = "switch switch--variant-lock"

    const labelClassName = "switch--label"
      + (checked ? ` switch--variant-lock-checked` : "")
      + (this.props.disabled ? ` switch--variant-lock-disable` : "")

    let content = this.props.variant === "lock" ? (
      <span
        className={wrapperClassName}
      >
        <input
          id={this.state.id}
          type="checkbox"
          className={lockClassName}
          data-type="switch"
          disabled={this.props.disabled}
          checked={this.state.checked}
          value={this.state.checked ? "true" : "false"}
          onChange={this.inputHandler}
          name={this.props.name}
          data-testid={renderTestID(this.props.testID)}
          accessKey={this.props.hotKey ?
          HotKey.describeHotKeySet(this.props.hotKey) : undefined}
        />
        <label htmlFor={this.state.id} className={labelClassName}>
          <Icon
            name={this.state.checked ? "lock" : "lock-unlock"}
            size={iconSize}
            color={this.props.disabled ? "light-gray" : "black"}
          />
          {this.props.srOnlyLabel && (
            <span className="sr-only">
              <LocalizedText {...this.props.srOnlyLabel} />
            </span>
          )}
        </label>
      </span>
    ) :
      (
        <BaseSwitch
          data-type="switch"
          className="switch switch--variant-switch"
          size={this.props.size}
          id={this.state.id}
          disabled={this.props.disabled}
          checked={this.state.checked}
          onChange={this.inputHandler}
          name={this.props.name}
          data-testid={renderTestID(this.props.testID)}
          accessKey={this.props.hotKey ?
          HotKey.describeHotKeySet(this.props.hotKey) : undefined}
          label={this.props.srOnlyLabel && translator.lookup(this.props.srOnlyLabel)}
          labelSrOnly={true}
        />
      )

    if (this.props.hotKey) {
      content = (
        <HotKeyResponder
          keyCode={this.props.hotKey}
          onKeyPress={this.handleHotKey}
        >
          {content}
        </HotKeyResponder>
      )
    }

    return content
  }

  public render() {
    return (
      <TranslatorContext.Consumer>
        {(translator) => (
          <LocaleContext.Consumer>
            {(locale) => {
              return this.renderWithAlchemy(translator, locale)
            }}
          </LocaleContext.Consumer>
        )}
      </TranslatorContext.Consumer>
    )
  }
}
