import React from "react"

import { PlaySound } from "../sound/PlaySound"
import { PrintMonRequestStatus } from "./PrintMonRequest"
import { Alert } from "../alert/Alert"
import { LocalizedString } from "../i18n/Translator"

export interface PrintMonAlertStrings {
  ready?: string
  inProgress?: string
  ok?: string
  failed?: string
}

export interface PrintMonAlertProps {
  /**
   * PrintMon status provided by `PrintMonRequest`.
   */
  status: PrintMonRequestStatus

  /**
   * Mark alert as inactive, alert status doesn't propagate to chrome in that case.
   */
  inactive?: boolean

  /**
   * Localization string IDs for each PrintMon status.
   *
   * Initialize `PrintMonAlert.defaultStrings` with default translation
   * strings for different states or explicitly pass them as `strings` property.
   */
  strings?: PrintMonAlertStrings
}

/**
 * PrintMon status alert component.
 *
 * Has visual and audio indication of print status.
 */
export class PrintMonAlert extends React.PureComponent<PrintMonAlertProps> {
  public static defaultStrings?: PrintMonAlertStrings

  public render() {
    const { status, inactive } = this.props

    const sound = status === "failed" ? "error" : "confirmation"
    const mute = !(status === "failed" || status === "ok")
    const variant = status === "failed" ? "error" : "info"
    const strings = this.props.strings || PrintMonAlert.defaultStrings || {}

    const title: LocalizedString = {
      stringID: strings[status],
      defaultString: `Printing: ${status}`,
    }

    return (
      <PlaySound sound={sound} mute={mute}>
        <Alert density="dense" variant={variant} title={title} inactive={inactive} />
      </PlaySound>
    )
  }
}
