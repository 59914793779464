import React from "react"

import { HIGState } from "../../config/HIGState"
import { safeReactChildrenOnly } from "../../utilities/react/safeReactChildrenOnly"
import { WLSProGloveCommands } from "../../utilities/proglove/WLSProGloveCommands"

import { withDisableProGloveDisplay } from "./withDisableProGloveDisplay"

const { progloveDisplayManager } = HIGState

interface ProGloveDisplayProps {
  /**
   * WLS action
   *
   * TIP - Trigger display before Haptic for a
   * more timely display together with
   * vibration/beep/visual LED indication
   */
  wlsProGloveCommand: WLSProGloveCommands

  /**
   * WLS action parameters
   */

  wlsProGloveCommandParameters: string

  /**
   * Unique ID that can be used to skip display if the same view
   * is updated.
   *
   * Without ID any rerender will trigger display.
   */
  id?: string

  /**
   * DisableDisplayMessages disables display feature.
   *
   * Could be used to prevent sending display values when component is rendered but not visible.
   */
   disableProGloveDisplay: boolean
}

/**
 * Sends PGDisplay WLS command to device
 */
class ProGloveDisplayBase extends React.PureComponent<ProGloveDisplayProps> {
  public constructor(props: ProGloveDisplayProps) {
    super(props)
    this.display = this.display.bind(this)
  }

  public componentDidMount() {
    const disableDisplay = this.props.disableProGloveDisplay

    if (!disableDisplay) {
      this.display()
    }
  }

  public componentDidUpdate(prevProps: ProGloveDisplayProps) {
    const disableDisplay = this.props.disableProGloveDisplay
    const changed = !this.props.id || this.props.id !== prevProps.id

    if (!disableDisplay && changed) {
      this.display()
    }
  }

  public display() {
    progloveDisplayManager.display(
      this.props.wlsProGloveCommand,
      this.props.wlsProGloveCommandParameters
    )
  }

  public render() {
    return safeReactChildrenOnly(this.props.children)
  }
}

export const ProGloveDisplay = Object.assign(
  withDisableProGloveDisplay(ProGloveDisplayBase),
  {
      displayName: "ProGloveDisplay",
  },
)
