import React from "react"

import { FlexLayout } from "../../layout/flex/FlexLayout"
import { TextLayout } from "../../layout/text/TextLayout"
import { Text } from "../../text/Text"
import { Size } from "../../helpers/parseSize"
import { LocalizedText } from "../../text/LocalizedText"

import { ProductId } from "../ProductId"
import { ProductImage } from "../ProductImage"
import { ProductInfo } from "../ProductInfo"
import { ProductQuantityLabel } from "../ProductQuantityLabel"
import { ProductBoxStrings } from "../ProductBox"
import { TestProps } from "../../helpers/TestProps"
import { renderTestID } from "../../helpers/renderTestID"
import { FontSize } from "../../theme/Theme"

export interface BaseProductBoxProps extends TestProps {
  productInfo: ProductInfo

  strings: ProductBoxStrings
}

/**
 * Provides common methods for all variants
 */
export class BaseProductBox<T extends BaseProductBoxProps>
     extends React.PureComponent<T> {

  protected renderPackOfN() {
    if (!this.props.productInfo.packOfN || this.props.productInfo.packOfN <= 1) {
      return undefined
    }

    return (
      <FlexLayout.Item backgroundColor="red" size="auto" key="packOfN">
        <TextLayout vAlign="middle" hAlign="center">
          <LocalizedText
            parameters={{ packOfN: this.props.productInfo.packOfN }}
            variant="white"
            size="lg"
            attributed={true}
            stringID={this.props.strings.packOfN}
            defaultString="Pack of <b>{packOfN}</b> units"
            testID={renderTestID(this.props.testID, "packOfN")}
          />
        </TextLayout>
      </FlexLayout.Item>
    )
  }

  protected renderProductIdAndQuantity(size: FontSize) {
    if (!this.props.productInfo.identifier) {
      return
    }

    const productInfo = this.props.productInfo
    const showQuantity = productInfo.quantity && productInfo.quantity > 1

    return (
      <FlexLayout.Item size="auto" key="identifier">
        <TextLayout vAlign="middle">
          {showQuantity && (
            <ProductQuantityLabel
              size={size}
              quantity={productInfo.quantity!}
              testID={renderTestID(this.props.testID, "quantity")}
            />
          )}
          {showQuantity && " "}

          {(
            <ProductId
              size={size}
              productID={productInfo.identifier!}
              expanded={true}
              testID={renderTestID(this.props.testID, "productId")}
            />
          )}
        </TextLayout>
      </FlexLayout.Item>
    )
  }

  protected renderProductImage(size: Size) {
    return (
      <FlexLayout.Item size={size} key="image">
          <ProductImage
            url={this.props.productInfo.imageUrl || ""}
            testID={renderTestID(this.props.testID, "image")}
          />
      </FlexLayout.Item>
    )
  }

  protected renderProductBinding() {
    if (!this.props.productInfo.binding) {
      return
    }

    return (
      <FlexLayout.Item size="auto" key="binding">
        <Text
          italic={true}
          size="sm"
          testID={renderTestID(this.props.testID, "binding")}
        >
          {this.props.productInfo.binding}
        </Text>
      </FlexLayout.Item>
    )
  }

  protected renderProductTitle() {
    if (!this.props.productInfo.title) {
      return
    }

    return (
      <FlexLayout.Item size="auto" key="title">
        <Text
          bold={true}
          testID={renderTestID(this.props.testID, "title")}
        >
          {this.props.productInfo.title}
        </Text>
      </FlexLayout.Item>
    )
  }

  protected renderProductBrand() {
    if (!this.props.productInfo.brand) {
      return
    }

    return (
      <FlexLayout.Item size="auto" key="brand">
        <Text
          size="sm"
          testID={renderTestID(this.props.testID, "brand")}
        >
          {this.props.productInfo.brand}
        </Text>
      </FlexLayout.Item>
    )
  }

  /**
   * It's a bit like `join`, but we just insert a Spacer between the components
   * @param components
   */
  protected interweaveComponentsWithSpacer(components: JSX.Element[]): JSX.Element[] {
    if (components.length <= 1) {
      return components
    }
    const result = [components[0]]
    for (let i = 1; i < components.length; i++) {
      result.push(<FlexLayout.Spacer size="xxs" key={`spacer-${i}`} />)
      result.push(components[i])
    }
    return result
  }
}
