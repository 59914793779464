/**
 * Extract form data as object.
 */
export function getFormData(form: HTMLFormElement): Record<string, string> {
  const data: Record<string, string> = {}

  for (let i = 0, len = form.elements.length; i < len; i++) {
    const element = form.elements[i]
    if (element.nodeName === "INPUT"
      || element.nodeName === "SELECT"
      || element.nodeName === "TEXTAREA"
    ) {
      const input = element as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      // unnamed and disabled elements should not be included in the form
      if (input.name && !input.disabled) {
        // normalize non-unux line endings to the unix format if needed
        data[input.name] = input.value ? input.value.replace(/\r\n|\r/g, "\n") : ""
      }
    }
  }

  return data
}
