import React from "react"

import { FlexLayout } from "../layout/flex/FlexLayout"
import { Alert, AlertVariant } from "../alert/Alert"
import { Button } from "../button/Button"
import { AddHotKey } from "../keyboard/AddHotKey"
import { HotKeyCode } from "../keyboard/HotKeyCode"
import { LocalizedString } from "../i18n/Translator"
import { PlaySound } from "../sound/PlaySound"
import { SoundName } from "../../utilities/sound/SoundName"
import { LocalizedText } from "../text/LocalizedText"
import { ViewportCapsContext } from "../layout/viewport/ViewportCapsContext"

interface ChromeAlertModalProps {
  /**
   * Unique ID of modal.
   *
   * Required to properly skip play sound on rerenders.
   */
  id: string

  /**
   * Alert variant.
   */
  variant?: AlertVariant

  /**
   * Modal caption.
   */
  caption?: LocalizedString

  /**
   * Primary button label.
   */
  primaryButtonLabel?: LocalizedString

  /**
   * Primary button hot key.
   */
  primaryButtonHotKey?: HotKeyCode

  /**
   * Primary button click callback.
   */
  onPrimaryButtonClick?: () => void

  /**
   * Pass to be able to lookup component during test run.
   *
   * Passed as `data-testid={value}` to the alert message
   */
  testIDForAlert?: string

  /**
   * Pass to be able to lookup component during test run.
   *
   * Passed as `data-testid={value}` to the button
   */
  testIDForPrimaryButton?: string
}

/**
 * Chrome alert modal with semi-transparent outline.
 *
 * Designed to be passed as `modal` parameter of chrome.
 */
export class ChromeAlertModal extends React.PureComponent<ChromeAlertModalProps> {
  public static variantToSoundMap: Record<AlertVariant, SoundName> = {
    info: "confirmation",
    error: "error",
    warning: "attention",
    success: "success",
  }

  public render() {
    const {
      id,
      variant = "info",
      caption,
      primaryButtonLabel,
      primaryButtonHotKey,
      onPrimaryButtonClick,
      testIDForAlert,
      testIDForPrimaryButton,
    } = this.props

    const soundName = ChromeAlertModal.variantToSoundMap[variant]

    return (
      <ViewportCapsContext.Consumer>
        {(viewportCaps) => (
          <PlaySound id={id} sound={soundName}>
            <FlexLayout direction="column">
              <FlexLayout.Item
                padding="sm"
              >
                <Alert
                  variant={variant}
                  flex={true}
                  density="expanded"
                  inactive={true} // no propagation to NavBar
                  rounded={true}
                  title={caption}
                  testID={testIDForAlert}
                />
              </FlexLayout.Item>

              <FlexLayout.Item
                size="auto"
                padding="sm"
                backgroundColor="white"
              >
                <Button
                  variant="primary"
                  size={viewportCaps.hSizeAtMost("md") ? "md" : "xl"}
                  fluid={true}
                  onClick={onPrimaryButtonClick}
                  testID={testIDForPrimaryButton}
                >
                  <AddHotKey keyCode={primaryButtonHotKey}>
                    <LocalizedText {...primaryButtonLabel} />
                  </AddHotKey>
                </Button>
              </FlexLayout.Item>
            </FlexLayout>
          </PlaySound>
        )}
      </ViewportCapsContext.Consumer>
    )
  }
}
