import { Theme } from "../theme/ThemeContext"

export interface ParsedSize {
  // If you update the units, you might also want to update the regexp on line 15.
  unit: "px" | "fr" | "vu" | "auto" | "rem"
  value?: number
}

export type Size = string | number | ParsedSize

const unitRegExp = /^\d+(?:\.\d+)?\s*(px|fr|rem|vu)$/i

/**
 * parse the `size` to an object: `{value: numeric, unit: string}`.
 * When the `size` is a numeric value, we treat it as the pixel value.
 * If `size` is a string, we will try parse it as `{value}{unit}` format such as `0.5rem`.
 * If `size` does not follow the format, it should be one of the sizing strings such as `xs`, `lg`.
 * Finally, `size` can also be an object `{value: numeric, unit: string}`. In this case, this
 * function does not thing.
 * Otherwise, Error will be thrown.
 * @param size The size to be parsed. Support: string, number, or ParsedSize object.
 * @param theme The theme being used.
 * @returns The parsed result.
 */
export function parseSize(size: Size, theme: Theme): ParsedSize {
  // fast path for often used value
  if (size === "1fr") {
    return { unit: "fr", value: 1 }
  }

  // fast path for often used value
  if (size === "1px") {
    return { unit: "px", value: 1 }
  }

  // fast path for often used value
  if (size === "1rem") {
    return { unit: "rem", value: 1 }
  }

  // long path for other values
  switch (typeof size) {
    case "object":
      return size
    case "number":
      return { unit: "px", value: size }
    case "string":
      if (size === "auto") {
        return { unit: "auto" }
      } else {
        const match = unitRegExp.exec(size)
        let unit
        if (match) {
          unit = match[1].toLowerCase()
        }

        if (unit === "px" || unit === "fr" || unit === "rem") {
          // We honour the input from the user. So if they decided to use `px`, then keep it.
          const value = parseFloat(size)
          if (isNaN(value)) {
            throw new TypeError("Invalid size value: " + size)
          }
          return { unit, value }
        } else if (unit === "vu") {
          // For vu, we convert it to rem.
          const value = parseInt(size, 10)
          if (isNaN(value)) {
            throw new TypeError("Invalid size value: " + size)
          }
          const remValue = value * theme.verticalUnitSizeRem
          return { unit: "rem", value: remValue }
        } else {
          // We will use rem instead of px for `xs`, `sm`, etc.
          const spacing: number | undefined = (theme.spacing as {[key: string]: number})[size]
          if (spacing === undefined) {
            throw new TypeError("Invalid size value: " + size)
          }
          return { unit: "rem", value: spacing }
        }
      }
    default:
      throw new TypeError("Invalid size value: " + size)
  }
}
