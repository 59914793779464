/**
 * Cross-platform addEventListener().
 *
 * Normalized across modern browsers.
 */
export function addEventListener(element: EventTarget, type: string, listener: EventListener) {
  if (element.addEventListener) {
    element.addEventListener(type, listener, false)
    // @ts-ignore
  }
}

/**
 * Cross-platform removeEventListener().
 *
 * Normalized across modern browsers.
 */
export function removeEventListener(element: EventTarget, type: string, listener: EventListener) {
  if (element.removeEventListener) {
    element.removeEventListener(type, listener)
    // @ts-ignore
  }
}
