import React from "react"

/**
 * Ghost element that helps to set vertical align for any sibling elements.
 *
 * This very old and simple way to get vertical align in the way it compatible with all browsers.
 *
 * On IE7 platform parent element must have explicitly set height to make it work.
 *
 * There is only one disadvantage: ellipsis won't be properly centered.
 *
 * Usage:
 *
 * ```jsx
 * <div>
 *   <VerticalAligner />
 *   <span style={{ verticalAlign: "center" }}>
 *     Text
 *   </span>
 * </div>
 * ```
 *
 * @see https://css-tricks.com/centering-in-the-unknown/
 */
export const VerticalAligner = () => (
  <span className="vertical-aligner" />
)
