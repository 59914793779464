import React from "react"

import { FlexLayout } from "../layout/flex/FlexLayout"
import { ViewportCapsContext } from "../layout/viewport/ViewportCapsContext"
import { DefaultNavBar } from "../nav/DefaultNavBar"
import { OverlayLayout } from "../layout/overlay/OverlayLayout"
import { ChromeMainMenuContent } from "./ChromeMainMenuContent"
import { ChromeProblemMenuContent } from "./ChromeProblemMenuContent"
import { LocalizedString } from "../i18n/Translator"
import { NavBar } from "../nav/NavBar"
import { ChromeMenuEntry } from "./ChromeMenuEntry"
import { ChromeMenu } from "./ChromeMenu"
import { ZoomLayout } from "../layout/zoom/ZoomLayout"
import { safeReactChildrenOnly } from "../../utilities/react/safeReactChildrenOnly"
import { ChromeModal } from "./ChromeModal"

export type ChromeMenuType = "mainMenu" | "problemMenu"

export interface ChromeStrings {
  mainMenu?: string
  problemMenu?: string
  noReportableProblems?: string
  signOut?: string
  signOutNotAvailable?: string
  signOutInProgressMessage?: string
  menuBack?: string
  cancel?: string
}

// uppercase for compatibility with TaskTrackingService's ViewType
export type ChromeViewType = "NORMAL" | "PROBLEM"

export type ChromeVariant = "default" | "error" | "success" | "warning"

export type ChromeModalSide = "top" | "bottom"

interface Props {
  /**
   * Controls zoom of chrome content.
   *
   * By default zoom is disabled, can be set to "auto" to scale content
   * depending on screen size or to specific float value.
   */
  zoom?: "auto" | "none" | number

  /**
   * Chrome variant impacts color of navigation bar.
   */
  variant?: ChromeVariant

  /**
   * Chrome title that is displayed in navigation bar.
   */
  title?: LocalizedString

  /**
   * With PROBLEM value it shows cross icon in navigation bar and makes it possible
   * to go back to NORMAL value.
   */
  viewType?: ChromeViewType

  /**
   * Localization strings that are using chrome.
   *
   * @deprecated Use Chrome.defaultStrings instead.
   */
  strings?: ChromeStrings

  /**
   * Show this react element as modal.
   */
  modal?: React.ReactNode

  /**
   * Show modal animation from this side.
   */
  modalSide?: ChromeModalSide

  /**
   * Disable sign out?
   *
   * It make sense to disable sign out for non interruptable tasks.
   */
  disableSignOut?: boolean

  /**
   * Triggered on sign out button click in main menu.
   */
  onSignOutClick?: () => void

  /**
   * Triggered on any menu entry click in main and problem menus.
   */
  onMenuEntryClick?: (entry: ChromeMenuEntry) => void

  /**
   * Triggered when user dismisses the dismissible work flow.
   */
  onDismissClick?: () => void

  /**
   * Pass a tree of main menu entries.
   */
  mainMenuEntries?: ChromeMenuEntry[]

  /**
   * Pass a tree on problem menu entries.
   */
  problemMenuEntries?: ChromeMenuEntry[]
  viewID?: string
}

interface State {
  activeMenuType?: ChromeMenuType
  activeMenuEntry?: ChromeMenuEntry
}

/**
 * Universal application chrome with look and feel based on AFT guidelines.
 */
export class Chrome extends React.PureComponent<Props, State> {
  // override this value to customize strings and provide translations
  public static defaultStrings?: ChromeStrings

  private readonly menuWidth = 320  // MC32 max width

  // needed to make sure that empty object has the same reference on each render
  // to avoid wasted render cycles that have a significant impact on IE7 platform
  private readonly emptyStrings: ChromeStrings = {}

  public constructor(props: Props) {
    super(props)

    this.handleProblemMenuClick = this.handleProblemMenuClick.bind(this)
    this.handleMainMenuClick = this.handleMainMenuClick.bind(this)
    this.handleMenuEntryClick = this.handleMenuEntryClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleSignOut = this.handleSignOut.bind(this)
    this.handleDismissClick = this.handleDismissClick.bind(this)

    this.state = {
      activeMenuType: undefined,
      activeMenuEntry: undefined,
    }
  }

  public handleMainMenuClick() {
    this.setState(({ activeMenuType }) => ({
      activeMenuType: activeMenuType !== "mainMenu" ? "mainMenu" : undefined,
      activeMenuEntry: undefined,
    }))
  }

  public handleProblemMenuClick() {
    this.setState(({ activeMenuType }) => ({
      activeMenuType: activeMenuType !== "problemMenu" ? "problemMenu" : undefined,
      activeMenuEntry: undefined,
    }))
  }

  public handleDismissClick() {
    this.setState({
      activeMenuType: undefined,
      activeMenuEntry: undefined,
    }, () => {
      if (this.props.onDismissClick) {
        this.props.onDismissClick()
      }
    })
  }

  public handleMenuEntryClick(entry: ChromeMenuEntry | undefined, actionable: boolean) {
    if (actionable && entry) {
      this.setState({
        activeMenuType: undefined,
        activeMenuEntry: undefined,
      }, () => {
        if (this.props.onMenuEntryClick) {
          this.props.onMenuEntryClick(entry)
        }
      })
    } else {
      this.setState({
        activeMenuEntry: entry,
      })
    }
  }

  public handleMenuClose() {
    this.setState({
      activeMenuType: undefined,
      activeMenuEntry: undefined,
    })
  }

  public handleSignOut() {
    this.setState({
      activeMenuType: undefined,
      activeMenuEntry: undefined,
    }, () => {
      if (this.props.onSignOutClick) {
        this.props.onSignOutClick()
      }
    })
  }

  public render() {
    const { activeMenuEntry } = this.state

    const strings = this.props.strings || Chrome.defaultStrings || this.emptyStrings

    const modalSide = this.props.modalSide || "top"

    // active modal overrides any other active menu
    const activeMenuType = this.props.modal
      ? (modalSide === "top" ? "topModal" : "bottomModal")
      : this.state.activeMenuType

    const mainMenuTitle = activeMenuEntry && activeMenuType === "mainMenu"
      ? activeMenuEntry.label
      : { stringID: strings.mainMenu, defaultString: "Menu" }

    const problemMenuTitle = activeMenuEntry && activeMenuType === "problemMenu"
      ? activeMenuEntry.label
      : { stringID: strings.problemMenu, defaultString: "Problem" }

    const content = safeReactChildrenOnly(this.props.children)

    const zoom = this.props.zoom || "none"

    // We need separate slots for top and bottom modals otherwise transition
    // animations could not work well when modalSide changes between modals.
    const renderTopModalContent = activeMenuType === "topModal"
    const renderBottomModalContent = activeMenuType === "bottomModal"
    const renderMainMenuContent = activeMenuType === "mainMenu"
    const renderProblemMenuContent = activeMenuType === "problemMenu"

    const menuOpen = renderTopModalContent || renderBottomModalContent ||
      renderMainMenuContent || renderProblemMenuContent

    return (
      <ViewportCapsContext.Consumer>
        {(viewportCaps) => {
          const expanded = viewportCaps.hSizeAtLeast("md")
          const headerSize = !expanded ? "standard" : "expanded"
          const navbarHeight = `${expanded ? NavBar.expandedHeightRem : NavBar.defaultHeightRem}rem`

          return (
            <OverlayLayout
              activeMenu={activeMenuType}
              onMenuClose={this.handleMenuClose}
            >
              <OverlayLayout.Content ariaHidden={menuOpen}>
                <FlexLayout direction="column">
                  <FlexLayout.Item size={navbarHeight}>
                    <DefaultNavBar
                      variant={this.props.variant}
                      title={this.props.title}
                      strings={strings}
                      dismissible={this.props.viewType === "PROBLEM"}
                      onMainMenuClick={this.handleMainMenuClick}
                      onProblemMenuClick={this.handleProblemMenuClick}
                      onDismissClick={this.handleDismissClick}
                      mainMenuExpanded={renderMainMenuContent}
                      problemMenuExpanded={renderProblemMenuContent}
                    />
                  </FlexLayout.Item>
                  <FlexLayout.Item backgroundColor="white">
                    <ZoomLayout value={zoom}>
                      {content}
                    </ZoomLayout>
                  </FlexLayout.Item>
                </FlexLayout>
              </OverlayLayout.Content>

              <OverlayLayout.Menu
                name="mainMenu"
                side="natural"
                size={this.menuWidth}
              >
                <ChromeMainMenuContent
                  headerTitle={mainMenuTitle}
                  headerSize={headerSize}
                  strings={strings}
                  onCancel={this.handleMenuClose}
                  onSignOut={this.handleSignOut}
                  disableSignOut={this.props.disableSignOut}
                >
                  <ChromeMenu
                    itemSize={headerSize}
                    strings={strings}
                    entries={this.props.mainMenuEntries}
                    onMenuEntryClick={this.handleMenuEntryClick}
                    viewID={this.props.viewID}
                  />
                </ChromeMainMenuContent>
              </OverlayLayout.Menu>

              <OverlayLayout.Menu
                name="problemMenu"
                side="reverse"
                size={this.menuWidth}
              >
                <ChromeProblemMenuContent
                  headerTitle={problemMenuTitle}
                  headerSize={headerSize}
                  strings={strings}
                  onCancel={this.handleMenuClose}
                >
                  <ChromeMenu
                    itemSize={headerSize}
                    strings={strings}
                    entries={this.props.problemMenuEntries}
                    onMenuEntryClick={this.handleMenuEntryClick}
                    viewID={this.props.viewID}
                  />
                </ChromeProblemMenuContent>
              </OverlayLayout.Menu>

              <OverlayLayout.Menu
                name="topModal"
                side="top"
                transparent={true}
                // buffering is needed to show content when modal is hiding
                buffered={true}
                role="dialog"
                ariaModal={true}
              >
                {renderTopModalContent && (
                  <ZoomLayout value={zoom}>
                    <ChromeModal>
                      {this.props.modal}
                    </ChromeModal>
                  </ZoomLayout>
                )}
              </OverlayLayout.Menu>

              <OverlayLayout.Menu
                name="bottomModal"
                side="bottom"
                transparent={true}
                // buffering is needed to show content when modal is hiding
                buffered={true}
                role="dialog"
                ariaModal={true}
              >
                {renderBottomModalContent && (
                  <ZoomLayout value={zoom}>
                    <ChromeModal>
                      {this.props.modal}
                    </ChromeModal>
                  </ZoomLayout>
                )}
              </OverlayLayout.Menu>
            </OverlayLayout>
          )
        }}
      </ViewportCapsContext.Consumer>
    )
  }
}
