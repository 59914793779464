import { TestProps } from "../helpers/TestProps"
import { Text } from "../text/Text"
import React from "react"
import qrCodeImage from "../../resources/images/qrcode.i.svg"
import { renderTestID } from "../helpers/renderTestID"
import { ARBinID } from "./BinID"

const binCodeRegex = /^[0-9][A-M]$/

interface ARBinProps extends TestProps {
  binCode: string
}

export class ARBin extends React.PureComponent<ARBinProps> {

  private getValidationError(binCode: string): string | null {
    if (!binCodeRegex.test(binCode)) {
      if (binCode.length !== 2) {
        return "Bin code must be of length 2."
      }
      if (!/[0-9]+/.test(binCode)) {
        return `Invalid bin code: "${binCode}"\n Bin code must start with a number.`
      }
      if (!/[A-M]$/.test(binCode)) {
        return `Invalid bin code: "${binCode}"\n Bin code must end with a letter in A-M`
      }
      return `Invalid bin code: "${binCode}"\n Bin code format is incorrect.`
    }

    return null // No error
  }

  private getBorderColor(binID: ARBinID): string {
    const binCodeColorMapping: { [key: string]: string } = {
      A: "#006b3f", // A - Green
      B: "#72166b", // B - Purple
      C: "#ffd100", // C - Yellow
      D: "#9bc4e2", // D - Light Blue
      E: "#919693", // E - Gray
      F: "#d81e05", // F - Red
      G: "#00337f", // G - Medium Blue
      H: "#fcbfc9", // H - Pink
      I: "#f99b0c", // I - Orange
      J: "#b28260", // J - Brown
      K: "#00b2aa", // K - Teal
      L: "#bad80a", // L - Chartreuse
      M: "#e838bf", // M - Magenta
      }
    return binCodeColorMapping[binID.row] || "#666670"
  }

  public render() {
    const { binCode } = this.props
    const binID = ARBinID.from(binCode)

    if (!binID) {
      const validationError = this.getValidationError(binCode)
      return (
        <div className="ar-bin">
          <div className="ar-bin__bin-code-error">{validationError}</div>
        </div>
      )
    }

    const borderColor = this.getBorderColor(binID)

    return (
      <div
        className="ar-bin"
        style={{ borderColor }}
        data-testid={renderTestID(this.props.testID)}
      >
        <div className="ar-bin__bin-code">
          <Text size="7xl" bold={true} >{binCode}</Text>
        </div>
        <img
          className="ar-bin__qr-code-image"
          src={qrCodeImage}
        />
      </div>
    )
  }
}
