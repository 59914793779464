import React from "react"

import {
  DocumentIDType,
  DocumentType,
  PDFChildrenCallback,
  PrintDocFlowBaseOptions,
  PrintDocFlowOptions
} from "./PrintDocFlowLike"
import { PDFRequestState, PrintDocFlowRequest } from "./PrintDocFlowRequest"

export interface SHIPRequestProps extends PrintDocFlowBaseOptions {
  scannablePackageID: string

  // The following are all PDF metadata keys in uppercase and _ spacing
  packStationID?: string // PACK_STATION_ID
  boxCode?: string // BOX_CODE
  isMAVEnabled?: string // IS_MAV_ENABLED
  labelArgs?: string // LABEL_ARGS
  customDestination?: string // CUSTOM_DESTINATION
  includeEmployeeLogin?: boolean // employee login will be injected if true (default)

  children: PDFChildrenCallback
}

export class PrintSHIPRequest extends React.PureComponent<SHIPRequestProps, PDFRequestState> {

    private static defaultProps: Partial<SHIPRequestProps> = {
      includeEmployeeLogin: true,
    }

    public constructor(props: SHIPRequestProps) {
      super(props)
    }

    private createOptions() {
      const printDocFlowOptions: PrintDocFlowOptions = {
        ...this.props,
        documentID: {
          type: DocumentIDType.PACKAGE_SCANNABLE_ID,
          id: this.props.scannablePackageID
        },
        documentType: DocumentType.SHIPPING_LABEL,
        metaData: {}
      }

      if (this.props.packStationID) {
        const metaDataKey = "PACK_STATION_ID"
        printDocFlowOptions.metaData![metaDataKey] = this.props.packStationID
      }
      if (this.props.boxCode) {
        const metaDataKey = "BOX_CODE"
        printDocFlowOptions.metaData![metaDataKey] = this.props.boxCode
      }
      if (this.props.isMAVEnabled) {
        const metaDataKey = "IS_MAV_ENABLED"
        printDocFlowOptions.metaData![metaDataKey] = this.props.isMAVEnabled
      }
      if (this.props.labelArgs) {
        const metaDataKey = "LABEL_ARGS"
        printDocFlowOptions.metaData![metaDataKey] = this.props.labelArgs
      }
      if (this.props.customDestination) {
        const metaDataKey = "CUSTOM_DESTINATION"
        printDocFlowOptions.metaData![metaDataKey] = this.props.customDestination
      }
      if (this.props.includeEmployeeLogin) {
        const metaDataKey = "EMPLOYEE_LOGIN"
        printDocFlowOptions.metaData![metaDataKey] = ""
      }

      return printDocFlowOptions
    }

    public render() {
      return (
        <PrintDocFlowRequest {...this.createOptions()}>
          {(status, errorDetail) => {
            return this.props.children(status, errorDetail)
          }}
        </PrintDocFlowRequest>
      )
    }

}
