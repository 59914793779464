import React from "react"

import { CartSlotVariant } from "./CartSlotVariant"
import { textColor } from "../../utilities/color/textColor"
import { Icon } from "../icon/Icon"
import { memoizeOne } from "../../utilities/memoizeOne"
import { IconSize } from "../icon/IconSize"
import { LayoutSize } from "../layout/flex/LayoutSizeContext"
import { LocalizedString, Translator } from "../i18n/Translator"
import { withTranslator } from "../i18n/withTranslator"
import { withLayoutSize } from "../layout/flex/withLayoutSize"
import { CartSlotConfig } from "./CartSlotConfig"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

export interface CartSlotProps extends TestProps {
  /**
   * Label to be presented on the header of the CartSlot component.
   */
  label?: string

  /**
   * The location of the CartSlot.
   */
  location?: string

  /**
   * Description to display at the bottom of the tote.
   */
  description?: LocalizedString

  /**
   * Variant to be used, which covers inactive, pre-defined colors.
   */
  variant?: CartSlotVariant

  /**
   * CartSlot custom color overrides variant and also affects background and text color.
   *
   * Text color will be automatically detected based on luminance of passed background color.
   *
   * Color must have css hex format: #RGB or #RRGGBB (other css color formats are not supported).
   */
  color?: string

  /**
   * The size used to display the location (or perhaps the label as well?).
   */
  size?: CartSlotSize

  /**
   * Whether or not this instance of the CartSlot component is prepared.
   * If set, all that will be shown is a checkmark on an inactive gray
   * background.
   */
  prepared?: boolean

  /**
   * Whether or not the tote is selectable.
   * If this property is set, then the checkbox will on the tote will be interactable.
   */
  selectable?: boolean

  /**
   * Whether or not the tote is selected.
   * If this property is set, but the `selectable` property above is unset.
   * Then a selected-but-disabled state will be presented visually.
   * Please refer to the guide pages to see examples.
   */
  selected?: boolean

  /**
   * Handler to be called when the checkbox is clicked on.
   */
  onSelect?: () => void

  /**
   * Translator is used to translate aria labels.
   *
   * Injected automatically.
   */
  translator: Translator

  /**
   * Injected automatically.
   */
  layoutSize: LayoutSize
}

export type CartSlotSize = "sm" | "lg"

class CartSlotBase extends React.PureComponent<CartSlotProps> {

  private readonly borderRadius = 3

  private readonly cartSlotId: string

  private readonly iconSizes: Record<CartSlotSize, IconSize> = {
    sm: 24,
    lg: 32,
  }

  public constructor(props: CartSlotProps) {
    super(props)
    this.getStyle = memoizeOne(this.getStyle.bind(this))

    this.handleClick = this.handleClick.bind(this)

    this.cartSlotId = `cart-slot-${Math.round(Math.random() * 100000)}`
  }

  public handleClick() {
    const {
      selectable = false,
      onSelect,
    } = this.props

    if (selectable && onSelect) {
      onSelect()
    }
  }

  public getStyle(color: string | undefined): React.CSSProperties {
    const style: React.CSSProperties = {
      borderRadius: this.borderRadius,
    }

    if (color) {
      style.backgroundColor = color
    }

    return style
  }

  public getFallbackLocationAssistiveLabel() {
    const {
      selectable,
      selected,
      location,
      description,
      translator,
    } = this.props

    if (!selectable && !selected) {
      return undefined
    }

    let label = ""

    if (location) {
      label += location
    }

    if (description) {
      if (label.length !== 0) {
        label += ", "
      }
      label += translator.lookup({
        stringID: CartSlotConfig.defaultStrings.locationAssistiveLabel
      })
    }

    return label.length > 0 ? label : undefined
  }

  public getLocationAssistiveLabel() {
    const {
      location,
      description,
      translator,
    } = this.props

    if (!location || !description) {
      return this.getFallbackLocationAssistiveLabel()
    }

    const descriptionLookup = translator.lookup(description)

    return translator.lookup({
      stringID: CartSlotConfig.defaultStrings.locationAssistiveLabel,
      parameters: {
        location,
        description: descriptionLookup
      },
      defaultString: `Location ${location}: ${descriptionLookup}`
    })
  }

  public renderPrepared() {
    const {
      label,
      size = "sm",
    } = this.props

    const locationClassName = "cart-slot__prepared-icon"
      + (label ? " cart-slot__prepared-icon--padded" : "")

    return (
        <div className={locationClassName}>
          <Icon name="check-solid" size={this.iconSizes[size]} color="gray" />
        </div>
    )
  }

  public renderRegular(height: number) {
    const {
      label,
      location,
      description,
      size = "sm",
      selectable = false,
      selected = false,
      translator,
    } = this.props

    const locationClassName = "cart-slot__location"
      + ` cart-slot__location--size-${size}`

    const descriptionClassName = "cart-slot__description"
      + ` cart-slot__description--size-${size}`

    const checkboxWrapperClassName = "cart-slot__checkbox-wrapper"
      + (label ? " cart-slot__checkbox-wrapper--padded" : "")

    const checkboxLabel = this.getLocationAssistiveLabel()

    return (
      <div className={locationClassName}>
        { location && (
          <span
            className="cart-slot__location-span"
            aria-hidden={checkboxLabel !== undefined}
          >
            {location}
          </span>
        )}
        { description && (
          <div className={descriptionClassName} aria-hidden={checkboxLabel !== undefined}>
            {translator.lookup(description)}
          </div>
        )}
        { (selectable || selected) && (
          <div className={checkboxWrapperClassName}>
            <input
              id={this.cartSlotId}
              className={`cart-slot__checkbox cart-slot__checkbox--${ selectable ? "enabled" : "disabled" }`}
              type="checkbox"
              checked={selected}
              readOnly={true}
              tabIndex={!selectable ? -1 : undefined}
            />
            { checkboxLabel && (
              <label htmlFor={this.cartSlotId} className="sr-only">
                {checkboxLabel}
              </label>
            )}
            { selectable && (
              <div className="cart-slot__checkbox-focus"/>
            )}
            <div className="cart-slot__checkbox-image"/>
          </div>
        )}
      </div>
    )
  }

  public render() {
    const {
      label,
      variant = "inactive",
      color,
      prepared,
      layoutSize,
      selectable,
    } = this.props

    let className = "cart-slot"

    if (prepared) {
      className += ` cart-slot--variant-inactive`
    } else if (color) {
      className += ` cart-slot--foreground-${textColor(color)}`
    } else {
      className += ` cart-slot--variant-${variant}`
    }

    if (!prepared && selectable) {
      className += " cart-slot--selectable"
    }

    const locationWrapperClassName = "cart-slot__location-wrapper"
      + (label ? " cart-slot__location-wrapper--with-label" : "")

    return (
      <div
        className={className}
        style={this.getStyle(color)}
        onClick={selectable ? this.handleClick : undefined}
        data-testid={renderTestID(this.props.testID)}
      >
        {(label) && (
          <div className="cart-slot__label">
            {label}
          </div>
        )}
        <div className={locationWrapperClassName}>
          {prepared ? (
            this.renderPrepared()
          ) : (
            this.renderRegular(layoutSize.height)
          )}
        </div>
      </div>
    )
  }

}

export const CartSlot = Object.assign(
  withLayoutSize(
    withTranslator(CartSlotBase)
  ), {
    displayName: "CartSlot"
  }
)
