import React from "react"

import { renderTestID } from "../helpers/renderTestID"
import { TestProps } from "../helpers/TestProps"
import { LocaleContext } from "../i18n/LocaleContext"
import { ProgressStep } from "./ProgressStep"

export interface ProgressProps extends TestProps {
  /**
   * Show component vertical or horizontal
   * default: horizontal
   */
  vertical?: boolean
}

export class Progress extends React.PureComponent<ProgressProps> {
  public static Step = ProgressStep

  public render() {
    const { vertical } = this.props

    return (
      <LocaleContext.Consumer>
        {({ direction }) => {
          let baseClass = "progress"
          baseClass += ` progress--${direction}`
          baseClass += ` progress--${vertical ? "vertical" : "horizontal"}`

          return (
            <div
              className={baseClass}
              data-testid={renderTestID(this.props.testID)}
            >
              {this.props.children}
            </div>
          )
        }}
      </LocaleContext.Consumer>
    )
  }
}
