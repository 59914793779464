import { SoundName } from "./SoundName"
import { SoundProvider } from "./SoundProvider"

export type VelocityPresetName = "standard" | "legacy" | "hig"

export type VelocityPresetConfig = Record<SoundName, string | undefined>

/**
 * Sound provider for Velocity/Wavelink browser with or without Amazon profile installed.
 *
 * Supports these presets:
 *
 * - standard - no amazon profile installed, works on unprovisioned Velocity
 * - legacy - amazon profile need to be installed, works on provisioned old and new hand scanners
 * - hig - amazon profile need to be installed, works on new hand scanners only
 */
export class VelocitySoundProvider implements SoundProvider {
  private readonly soundLinkID = "hig-sound-wls"

  private readonly soundLink: HTMLAnchorElement

  private readonly soundActions: Record<VelocityPresetName, VelocityPresetConfig> = {
    standard: {
      success:      undefined,  // no sound
      error:        "ida:IDA_ERROR_BEEP",
      attention:    undefined,  // no sound
      confirmation: "ida:IDA_BEEP",
    },

    legacy: {
      success:      "wls:Beep-Major-Scale-Up",
      error:        "wls:Beep",
      attention:    "wls:Beep-Triple-Low",
      confirmation: "ida:IDA_BEEP",
    },

    // rollout is tracked here: https://issues.amazon.com/issues/OTIEMaD-6400
    hig: {
      success:      "wls:FHIGC_success",
      error:        "wls:FHIGC_error",
      attention:    "wls:FHIGC_attention",
      confirmation: "wls:FHIGC_confirmation",
    },
  }

  private preset: VelocityPresetName

  public constructor(preset: VelocityPresetName = "standard") {
    this.preset = preset
    this.soundLink = this.createSoundLink()
  }

  public preload() {
    // noop, assume preloaded
  }

  public unload() {
    this.soundLink.remove()
    // @ts-ignore release link to make IE happy
    this.soundLink = undefined
  }

  public play(sound: SoundName) {
    const action = this.soundActions[this.preset][sound]
    if (action) {
      this.execVelocityAction(action)
    }
  }

  public stop() {
    // noop, not implemented
  }

  private execVelocityAction(action: string) {
    this.soundLink.href = action
    this.soundLink.click()
  }

  private createSoundLink(): HTMLAnchorElement {
    // if not initialized then try to find existing link left from previous instance
    let link = document.getElementById(this.soundLinkID) as HTMLAnchorElement

    // if found then initialize using existing link
    if (link) {
      return link
    }

    // if not initialized and there is no existing link then create new one and initialize
    link = document.createElement("a")
    link.setAttribute("hidden", "hidden")
    link.setAttribute("id", this.soundLinkID)
    document.body.appendChild(link)

    return link
  }
}
