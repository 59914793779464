import React from "react"

import {
  DocumentIDType,
  DocumentType,
  PDFChildrenCallback,
  PrintDocFlowBaseOptions,
  PrintDocFlowOptions
} from "./PrintDocFlowLike"
import { PDFRequestState, PrintDocFlowRequest } from "./PrintDocFlowRequest"

export interface PSLIPRequestProps extends PrintDocFlowBaseOptions {
  shippingID: string
  children: PDFChildrenCallback
}

export class PrintPSLIPRequest
  extends React.PureComponent<PSLIPRequestProps, PDFRequestState> {

    public constructor(props: PSLIPRequestProps) {
      super(props)
    }

    public render() {
      const printDocFlowOptions: PrintDocFlowOptions = {
        ...this.props,
        documentID: {
          type: DocumentIDType.SHIPMENT_ID,
          id: this.props.shippingID
        },
        documentType: DocumentType.PSLIP
      }

      return (
        <PrintDocFlowRequest {...printDocFlowOptions}>
          {(status, errorDetail) => {
            return this.props.children(status, errorDetail)
          }}
        </PrintDocFlowRequest>
      )
    }

}
