import React from "react"

export interface OverlayLayoutContentProps {
  ariaHidden?: boolean
}
/**
 * Overlay layout content.
 */
export class OverlayLayoutContent extends React.PureComponent<OverlayLayoutContentProps> {
  public render() {
    return (
      <div className="overlay-layout-content" aria-hidden={this.props.ariaHidden}>
        {this.props.children}
      </div>
    )
  }
}
