import React from "react"

import { safeReactChildrenOnly } from "../../../utilities/react/safeReactChildrenOnly"

interface BufferLayoutProps {
  /**
   * Set to true to flush buffer contents.
   */
  flush?: boolean
}

interface BufferLayoutState {
  bufferedChildren?: React.ReactNode
}

/**
 * Primitive buffer component that flushes content on demand.
 *
 * Mostly useful in animations to buffer content until transition is completed.
 */
export class BufferLayout extends React.PureComponent<BufferLayoutProps, BufferLayoutState> {
  public state: BufferLayoutState = {}

  public componentDidUpdate() {
    if (this.props.flush
      && this.props.children !== this.state.bufferedChildren
    ) {
      this.setState({ bufferedChildren: this.props.children })
    }
  }

  public render() {
    const content = this.props.flush ? this.props.children : this.state.bufferedChildren
    return safeReactChildrenOnly(content)
  }
}
