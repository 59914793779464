import React from "react"

import { HotKey } from "./HotKey"
import { AddHotKey } from "./AddHotKey"
import { HotKeySet } from "./HotKeyResponder"

export function getInferredHotKey(children: React.ReactNode): HotKeySet | undefined {
  const items = React.Children.toArray(children)

  for (const item of items) {
    if (React.isValidElement(item)
      && (item.type === HotKey || item.type === AddHotKey)
      && item.props.keyCode
    ) {
      return item.props.keyCode
    }
  }
}
