import React from "react"

import { TestProps } from "../helpers/TestProps"
import { ResourceId, ResourceIdBaseProps } from "../resource/ResourceId"
import { FontSize } from "../theme/Theme"

export type ContainerIdSize = FontSize

interface ContainerIdProps extends ResourceIdBaseProps, TestProps {
  /**
   * Container id.
   */
  containerID: string
}

/**
 * Renders container identifier.
 */
export const ContainerId: React.FunctionComponent<ContainerIdProps>
  = React.memo((props: ContainerIdProps) => {
  const { containerID, ...resourceIdProps } = props

  return (
    <ResourceId
      {...resourceIdProps}
      resourceID={containerID}
      variant="asin"
    />
  )
})

ContainerId.displayName = "ContainerId"
