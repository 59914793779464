import React, {Component} from 'react';
import * as styles from './IdPSelection.module.css';
import {Button, NavBar, NavText, Text} from "@amzn/fulfillment-hig-components";
import qs from 'query-string';
import buildUrl from 'build-url';

interface State {
    idp_list: IdPList[];
    redirect_uri: string;
    state?: string;
    scope: string;
    nonce?: string;
    client_id: string;
    response_type: string;
    authorize_url: string;
    user_code?: string;
    code_challenge?: string;
    code_challenge_method?: string;
    referrer?: string;
    ui_locales?: string;
    otp?: string;
}

interface IdPList {
    id: string;
    displayString: string;
}

const valid_display_strings = [
    "",
    "it service login",
    "mma cognito+userpool",
    "login with authgard",
    "login with authportal",
    "login with federate",
    "login with fitpis",
    "login with fitpis beta",
    "login with fuse authportal",
    "login with fuse authportal pool",
    "login with idprism",
    "login with maask",
    "login with maask badge",
    "login with maask alpha",
    "login with maask prod na",
    "login with midway",
    "login with milo",
    "login with milo test",
    "login with milo integ",
    "login with testidp1",
    "login with yubikey"
]

class IdPSelection extends Component<{}, State> {
    state: State = {
        idp_list: [],
        redirect_uri: '',
        state: '',
        scope: '',
        nonce: '',
        client_id: '',
        response_type: '',
        authorize_url: '',
        user_code: '',
        code_challenge: '',
        code_challenge_method: '',
        referrer: '',
        ui_locales: '',
        otp: ''
    };

    // React lifecycle method. Gets called when the component output has been rendered to the DOM.
    componentDidMount() {
        const query_parameters = qs.parse(window.location.search)

        if (this.hasValidJsonStructure(query_parameters.data)) {
            this.setState({
                idp_list: JSON.parse(query_parameters.data as string),
                redirect_uri: query_parameters.redirect_uri as string,
                state: query_parameters.state as string,
                scope: query_parameters.scope as string,
                nonce: query_parameters.nonce as string,
                client_id: query_parameters.client_id as string,
                response_type: query_parameters.response_type as string,
                authorize_url: query_parameters.authorize_url as string,
                user_code: query_parameters.user_code as string,
                code_challenge: query_parameters.code_challenge as string,
                code_challenge_method: query_parameters.code_challenge_method as string,
                referrer: query_parameters.referrer as string,
                ui_locales: query_parameters.ui_locales as string,
                otp: query_parameters.otp as string
            })
        }
    }

    hasValidJsonStructure(str?: string[] | string | null) {
        try {
            if (str == undefined || Array.isArray(str)) {
                return false;
            } else {
                JSON.parse(str);
            }
        } catch (error) {
            return false
        }
        return true
    }

    render() {
        const {idp_list, redirect_uri, state, scope, nonce, client_id, response_type, authorize_url, user_code, code_challenge, code_challenge_method, referrer, ui_locales, otp} = this.state

        const authorize_redirect_url = isValidAuthorizeUrl(authorize_url) ? buildUrl(authorize_url, {
            queryParams: {
                redirect_uri: redirect_uri,
                state: state ?? '',
                scope: scope,
                nonce: nonce ?? '',
                response_type: response_type,
                client_id: client_id,
                user_code: user_code ?? '',
                code_challenge: code_challenge ?? '',
                code_challenge_method: code_challenge_method ?? '',
                referrer: referrer ?? '',
                ui_locales : ui_locales ?? '',
                otp: otp ?? '',
                login_hint: ''
                // login_hint must be last parameter, so the IdP id can be appended to it below in renderIdPs
            }
        }) : "";

        function isValidAuthorizeUrl(authorize_url: string) : boolean {
            const valid_authorize_urls = [
                "alpha.us-west-2.federated-auth.aft.a2z.com",
                "beta.us-west-2.federated-auth.aft.a2z.com",
                "prod.us-east-1.federated-auth.aft.a2z.com",
                "prod.eu-west-1.federated-auth.aft.a2z.com",
                "prod.us-west-2.federated-auth.aft.a2z.com",
                "prod.ap-northeast-1.federated-auth.aft.a2z.com"
            ]
            let pathArray = authorize_url.split('/')
            if (pathArray.length > 2 && valid_authorize_urls.indexOf(pathArray[2]) > -1) {
                return true
            }
            return false
        }

        function isValidDisplayStringList() : boolean {
            return idp_list.every(val => valid_display_strings.includes(val.displayString.toLowerCase()))
        }
        const renderIdps = () => {
            if (idp_list && idp_list.length && authorize_redirect_url.length > 0 && isValidDisplayStringList()) {
                return (
                    idp_list.map((value, index) => {
                        return (
                            <div className={styles.selection} key={index}>
                                <div className={styles.button}>
                                    <Button
                                        size={"lg"} fluid={true}
                                        // Redirecting to /authorize endpoint with login_hint parameter
                                        onClick={() => {
                                            window.location.href = authorize_redirect_url + value.id
                                        }}>
                                        {value.displayString}
                                    </Button>
                                </div>
                            </div>
                        )
                    })
                )
            } else {
                return <Text>There seems to be a issue with the request. Kindly try logging in from the application again. If the problem
                    persists, Cut a ticket to CTI:Distribution Center/FAAS/Authentication</Text>
            }
        }

        return (
            <div>
                <NavBar>
                    <NavText>
                        <Text size="lg">IdPSelection</Text>
                    </NavText>
                </NavBar>
                <div className={styles.parent}>
                    <div className={styles.hero_image}>
                        {/*Hiding the hero image, since we dont have any image to display.
                        After discussion with AFT UX team we can re-enable it*/}
                        <p style={{display: 'none'}}>[Hero Image Here]</p>
                    </div>
                    <div className={styles.options_container}>
                        <Text size="lg">Sign in using one of the options below.</Text>
                        <br/>
                        {renderIdps()}
                    </div>
                </div>
            </div>
        )
    }
}

export default IdPSelection;