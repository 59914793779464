// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import toteImage from "../../resources/images/tote.i.svg"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface ToteImageProps {

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Tote image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="tote").
 */
export class ToteImage extends React.PureComponent<ToteImageProps> {
  public render() {
    const { altText, translator } = this.props

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={toteImage}
        className="tote-image"
        alt={imageAltText}
      />
    )
  }
}
