// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import qrCodeImage from "../../resources/images/qrcode.i.svg"
import { LocalizedString, Translator } from "../i18n/Translator"
import barcodeScanImage from "../../resources/images/barcode-scan.png"

export interface QRCodeScanImageProps {

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator

}

/**
 * QR Code image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="qr-code").
 */
export class QRCodeScanImage extends React.PureComponent<QRCodeScanImageProps> {
  public render() {
    const { altText, translator } = this.props

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <div className="qr-code-scan-image">
        <img className="qr-code-scan-image__scan" src={barcodeScanImage} alt=""/>
        <img
          className="qr-code-scan-image__qr-code"
          src={qrCodeImage}
          alt={imageAltText}
        />
      </div>
    )
  }
}
