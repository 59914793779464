import React from "react"

import { IconName } from "../icon/IconName"
import { ListItemIcon } from "./ListItemIcon"

export interface ListItemSecondaryIconProps {
  name: IconName
}

export class ListItemSecondaryIcon extends React.PureComponent<ListItemSecondaryIconProps> {
  public render() {
    return (
      <ListItemIcon name={this.props.name} _secondary={true} />
    )
  }
}
