/**
 * Calculates relative luminance as per WCAG specs.
 *
 * @param r Red component, numeric value 0..255
 * @param g Green component, numeric value 0..255
 * @param b Blue component, numeric value 0..255
 *
 * @returns Relative luminance, numeric value 0..1
 *
 * @see https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
 * @see https://www.w3.org/Graphics/Color/sRGB.html
 */
export function luminance(r8: number, g8: number, b8: number) {
  // convert to value 0..1
  const r = r8 / 255
  const g = g8 / 255
  const b = b8 / 255

  // translate color space
  const rt = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)
  const gt = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)
  const bt = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)

  // luminance
  return 0.2126 * rt + 0.7152 * gt + 0.0722 * bt
}
