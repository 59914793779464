import React from "react"

import { HotKey } from "./HotKey"
import { HotKeySet } from "./HotKeyResponder"

interface AddHotKeyProps {
  /**
   * Key code or set of key codes.
   */
  keyCode?: HotKeySet
}

/**
 * Adds hot key to specific content, see HotKey for more details.
 *
 * @see HotKey
 */
export class AddHotKey extends React.PureComponent<AddHotKeyProps> {
  public render() {
    const { keyCode, children } = this.props

    const hasKeyCode = !!((Array.isArray(keyCode) && keyCode.length) || keyCode)

    return (
      <span>
        {children}
        {hasKeyCode && " "}
        {hasKeyCode && <HotKey keyCode={keyCode} />}
      </span>
    )
  }
}
