// tslint:disable:no-reference
/// <reference path="../../../types/png-image.d.ts" />
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import barcodeScanImage from "../../resources/images/barcode-scan.png"
import barcodeImage from "../../resources/images/barcode.i.svg"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface BarcodeScanImageProps {

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Represent children as presentation. (Purely decorative content)
   *
   * Default is false.
   */
  presentationChildren: boolean

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Barcode scan image with barcode text.
 *
 * This is an internal component inside the Image component
 * injected through props (name="barcode-scan").
 */
export class BarcodeScanImage extends React.PureComponent<BarcodeScanImageProps> {
  public render() {
    const { altText, presentationChildren, translator } = this.props

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <div className="barcode-scan-image">
        <img className="barcode-scan-image__scan" src={barcodeScanImage} alt=""/>
        <img
          className="barcode-scan-image__barcode"
          src={barcodeImage}
          alt={imageAltText}
        />
        {this.props.children && (
          <div
            className="barcode-scan-image__text"
            aria-hidden={presentationChildren ? "true" : undefined}
          >
            {this.props.children}
          </div>
        )}
      </div>
    )
  }
}
