import React from "react"

import { DangerousProps } from "../../helpers/DangerousProps"
import { renderTestID } from "../../helpers/renderTestID"
import { TestProps } from "../../helpers/TestProps"

export const FitLayout: React.FunctionComponent<DangerousProps & TestProps> =
  ({
    dangerousClassName,
    dangerousStyle,
    testID,
    children,
  }) => {
    const className = "fit-layout"
      + (dangerousClassName ? ` ${dangerousClassName}` : "")

    return (
      <div
        className={className}
        style={dangerousStyle}
        data-testid={renderTestID(testID)}
      >
        {children}
      </div>
    )
  }
