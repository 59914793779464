import React from "react"

import { BaseProductBox, BaseProductBoxProps } from "./BaseProductBox"

import { FlexLayout } from "../../layout/flex/FlexLayout"
import { Size } from "../../helpers/parseSize"

export interface MainProductBoxProps extends BaseProductBoxProps {
  hideImage?: boolean

  heightMode?: "fit" | "auto"
  imageHeight?: Size
}

export class MainProductBox extends BaseProductBox<MainProductBoxProps> {
  private renderWithImage() {
    let components: JSX.Element[] = []

    if (this.props.heightMode === "fit") {
      const imageHeight = this.props.imageHeight || "2fr"

      let textDescrptions = [
        this.renderProductBinding(),
        this.renderProductTitle(),
        this.renderProductBrand()
      ].filter((v) => !!v) as JSX.Element[]
      textDescrptions = this.interweaveComponentsWithSpacer(textDescrptions)

      components = [
        this.renderPackOfN(),
        this.renderProductIdAndQuantity("lg"),
        this.renderProductImage(imageHeight),
        (
          <FlexLayout.Item size="2fr" key="description">
            <FlexLayout direction="column">
              {textDescrptions}
            </FlexLayout>
          </FlexLayout.Item>
        )
      ].filter((v) => !!v) as JSX.Element[]

      return this.interweaveComponentsWithSpacer(components)
    } else {
      const imageHeight = this.props.imageHeight || "5rem"

      components = [
        this.renderPackOfN(),
        this.renderProductIdAndQuantity("lg"),
        this.renderProductImage(imageHeight),
        this.renderProductBinding(),
        this.renderProductTitle(),
        this.renderProductBrand()
      ].filter((v) => !!v) as JSX.Element[]

      return this.interweaveComponentsWithSpacer(components)
    }
  }

  private renderWithouImage() {
    const components = [
      this.renderPackOfN(),
      this.renderProductIdAndQuantity("lg"),
      this.renderProductBinding(),
      this.renderProductTitle(),
      this.renderProductBrand()
    ].filter((v) => !!v) as JSX.Element[]

    return this.interweaveComponentsWithSpacer(components)
  }

  public render() {
    const flexItems = this.props.hideImage ? this.renderWithouImage() : this.renderWithImage()
    const dangerousClassName =
      this.props.heightMode === "auto" ? "flex-layout--height-auto" : undefined

    return (
      <FlexLayout
        direction="column"
        testID={this.props.testID}
        dangerousClassName={dangerousClassName}
      >
        {flexItems}
      </FlexLayout>
    )
  }
}
