import React from "react"
import { TestProps } from "../helpers/TestProps"

/**
 * Display the modal to the user.
 *
 */
export class ChromeModal extends React.PureComponent<TestProps> {

  public constructor(props: TestProps) {
    super(props)
  }

  public render() {
    return (
      <div
        className="chrome-modal"
      >
        {this.props.children}
      </div>
    )
  }
}
