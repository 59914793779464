import React from "react"

import { CSSTransition } from "react-transition-group"
import { SoundManager } from "../../../utilities/sound/SoundManager"
import { HIGState } from "../../../config/HIGState"
import { OverlayLayoutConfig } from "./OverlayLayoutConfig"

export interface OverlayLayoutShadowProps {
  hidden: boolean
  onClick?: () => void
}

/**
 * Overlay layout shadow makes main view to be perceived as inactive and it
 * als intercepts all the clicks on it.
 *
 * On modern platform it is black shadow with opacity.
 */
export class OverlayLayoutShadow extends React.PureComponent<OverlayLayoutShadowProps> {
  private readonly soundManager: SoundManager

  private readonly clickAudioFeedbackEnabled: boolean

  private readonly rootRef = React.createRef<HTMLDivElement>()

  public constructor(props: OverlayLayoutShadowProps) {
    super(props)

    this.soundManager = HIGState.soundManager
    this.clickAudioFeedbackEnabled = HIGState.clickAudioFeedbackEnabled

    this.handleClick = this.handleClick.bind(this)
  }

  public handleClick() {
    if (this.clickAudioFeedbackEnabled) {
      this.soundManager.play("confirmation")
    }

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  public render() {
    return (
      <CSSTransition
        in={!this.props.hidden}
        timeout={OverlayLayoutConfig.transitionDuration}
        classNames="overlay-layout-shadow-"
      >
        <div
          className="overlay-layout-shadow"
          onClick={this.handleClick}
          ref={this.rootRef}
        />
      </CSSTransition>
    )
  }
}
