import { VelocityWLSActionProvider } from "./VelocityWLSActionProvider"
import { WLSProGloveCommands } from "./WLSProGloveCommands"

/**
 * This is facade for display provider.
 *
 * Can automatically detect sound provider or use explicitly provided.
 */
export class ProGloveDisplayManager {
  private displayProvider: VelocityWLSActionProvider

  public constructor() {

    this.displayProvider = this.createProvider()

    this.preload()

    this.display = this.display.bind(this)
  }

  public preload() {
    this.displayProvider.preload()
  }

  public display(wlsProGloveCommand: WLSProGloveCommands, wlsProGloveCommandParameters: string) {
    console.log(`Sent wlsProGloveCommand: ${wlsProGloveCommand} with parameters: ${wlsProGloveCommandParameters}`)
    this.displayProvider.executeVelocityAction(wlsProGloveCommand, wlsProGloveCommandParameters)
  }

  // eslint-disable-line class-methods-use-this
  private createProvider(): VelocityWLSActionProvider {
      return new VelocityWLSActionProvider()
  }
}
