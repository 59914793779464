// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import pallet_height_img from "../../resources/images/pallet-height.i.svg"
import damaged_pallet_img from "../../resources/images/pallet-damaged.i.svg"
import split_pallet_img from "../../resources/images/pallet-split.i.svg"
import pallet_img from "../../resources/images/pallet.i.svg"
import red_pallet_img from "../../resources/images/red-pallet.i.svg"
import black_pallet_img from "../../resources/images/black-pallet.i.svg"
import pallet_loaded_img from "../../resources/images/pallet-loaded.i.svg"
import red_pallet_loaded_img from "../../resources/images/red-pallet-loaded.i.svg"
import black_pallet_loaded_img from "../../resources/images/black-pallet-loaded.i.svg"
import pallet_downstacking_img from "../../resources/images/pallet-downstacking.i.svg"
import pallet_case_img from "../../resources/images/pallet-case.i.svg"
import pallet_case_item_img from "../../resources/images/pallet-case-item.i.svg"

import { PalletVariant } from "./PalletVariant"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface PalletImageProps {
  /**
   * The pallet type that defines the pallet image to be rendered.
   */
  palletVariant: PalletVariant

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Pallet image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="pallet").
 */
export class PalletImage extends React.PureComponent<PalletImageProps> {
  public render() {
    const { palletVariant, altText, translator } = this.props
    const imageConf = imageConfMap[palletVariant]

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={imageConf.path}
        className="pallet-image"
        alt={imageAltText}
        width={imageConf.width}
        height={imageConf.height}
      />
    )
  }
}

interface ImageConf {
  path: string
  width: number
  height: number
}

const imageConfMap: Record<PalletVariant, ImageConf> = {
  pallet_height: {
    path: pallet_height_img,
    width: 150,
    height: 114,
  },
  damaged_pallet: {
    path: damaged_pallet_img,
    width: 150,
    height: 140,
  },
  split_pallet: {
    path: split_pallet_img,
    width: 150,
    height: 70,
  },
  pallet: {
    path: pallet_img,
    width: 150,
    height: 150,
  },
  red_pallet: {
    path: red_pallet_img,
    width: 150,
    height: 150,
  },
  black_pallet: {
    path: black_pallet_img,
    width: 150,
    height: 150,
  },
  pallet_loaded: {
    path: pallet_loaded_img,
    width: 150,
    height: 150,
  },
  red_pallet_loaded: {
    path: red_pallet_loaded_img,
    width: 150,
    height: 150,
  },
  black_pallet_loaded: {
    path: black_pallet_loaded_img,
    width: 150,
    height: 150,
  },
  pallet_downstacking: {
    path: pallet_downstacking_img,
    width: 150,
    height: 90,
  },
  pallet_case: {
    path: pallet_case_img,
    width: 150,
    height: 90,
  },
  pallet_case_item: {
    path: pallet_case_item_img,
    width: 150,
    height: 90,
  }
}
