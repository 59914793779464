import React from "react"

import { Tag } from "@amzn/alchemy-components-react"
import { BadgeVariant } from "./BadgeVariant"
import { FontSize } from "../theme/Theme"
import { textColor } from "../../utilities/color/textColor"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

export type BadgeSize = FontSize

export interface BadgeProps extends DangerousProps, TestProps {
  /**
   * Badge variant affects background color and text color.
   */
  variant?: BadgeVariant

  /**
   * Badge custom color overrides variant and also affects background and text color.
   *
   * Text color will be automatically detected based on luminance of passed background color.
   *
   * Color must have css hex format: #RGB or #RRGGBB (other css color formats are not supported).
   */
  color?: string

  /**
   * Badge size.
   */
  size?: BadgeSize

  /**
   * Round corners to make badge look like a pill.
   */
  pill?: boolean

  /**
   * Make text bold.
   */
  bold?: boolean

  /**
   * Automatically expands the width of badge to take all available space.
   *
   * Doesn't expand the height.
   */
  fluid?: boolean
}

/**
 * Badge-like text label with different text and background colors.
 */
export class Badge extends React.PureComponent<BadgeProps> {
  public static defaultProps: Partial<BadgeProps> = {
    variant: "default",
    size: "md",
  }

  public render() {
    const {
      variant,
      color,
      size,
      bold,
      pill,
      fluid,
      dangerousClassName,
      dangerousStyle,
    } = this.props

    const className = "badge"
      + ` badge--variant-${variant}`
      + ` badge--size-${size}`
      + (color ? ` badge--text-${textColor(color)}` : "")
      + (bold ? ` badge--bold` : "")
      + (fluid ? ` badge--fluid` : "")
      + (dangerousClassName ? ` ${dangerousClassName}` : "")

    const style: React.CSSProperties = {
      ...dangerousStyle,
    }
    if (color) {
      // @ts-ignore: WebComponent style override.
      style["--tag-default-background-color"] = color
    }

    // @ts-ignore: variant is default to "default".
    const usageColor = UsageColorMap[variant]
    return (
      <Tag
        className={className}
        style={style}
        data-testid={renderTestID(this.props.testID)}
        usageColor={usageColor}
        variant={pill ? "pill" : undefined}
      >
        {this.props.children}
      </Tag>
    )
  }
}

const UsageColorMap: Record<BadgeVariant, string|undefined> = {
  "default": undefined,
  "info": "info",
  "success": "success",
  "error": "error",
  "warning": "warning",
  "gray": undefined,
  "dark-gray": undefined,
}
