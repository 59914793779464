/**
 * Container variant.
 *
 * Can be used to differentiate between container types etc.
 */

 export function getContainerVariant(imageVariant: string): ContainerVariant {
  const containerVariant = containerVariants.find((container) => container === imageVariant)
  if (containerVariant) {
    return containerVariant
  }

  throw new Error(`Unable to find the provided container variant: ${imageVariant}`)
 }

 export const containerVariants = [
  "black_tote",
  "blue_tote",
  "gray_tote",
  "green_tote",
  "red_tote",
  "yellow_tote",
  "white_tote",
  "cage",
  "pick_cart",
  "pallet",
  "tote",
  "reserve_bin",
  "prime_bin",
  "package_csX"] as const
 export type ContainerVariant = typeof containerVariants[number]
