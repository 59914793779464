import React from "react"

import { List } from "../list/List"
import { LocalizedText } from "../text/LocalizedText"
import { ListItemSize } from "../list/ListOptionsContext"
import { SlideLayout, SlideDirection } from "../layout/slide/SlideLayout"
import { ChromeStrings } from "./Chrome"
import { ChromeMenuEntry } from "./ChromeMenuEntry"
import { HotKeyCode } from "../keyboard/HotKeyCode"
import { HIGState } from "../../config/HIGState"
import { SoundManager } from "../../utilities/sound/SoundManager"
import { addEventListener, removeEventListener } from "../../utilities/browser/addEventListener"
import { preventEventDefault } from "../../utilities/browser/preventEventDefault"
import { getEventKeyCode } from "../../utilities/browser/getEventKeyCode"
import { KeyCode } from "../keyboard/KeyCode"
import { getFirstFocusableElement } from "../../utilities/browser/getElementFocus"

export interface ChromeMenuProps {
  /**
   * Unique view identifier that can be used to deduplicate slide animation
   * requests if the same view is updated.
   *
   * Any update triggers new slide animation if not set.
   */
  viewID?: string
  itemSize?: ListItemSize
  strings?: ChromeStrings
  entries?: ChromeMenuEntry[]

  // The entry to show
  initialActiveEntryPath?: string
  // actionable: should the callback trigger taskCompletion
  onMenuEntryClick?: (entry: ChromeMenuEntry | undefined, actionable: boolean) => void
}

export interface ChromeMenuState {
  // TODO: change to a list for better handling
  /**
   * Path to the entry to display.
   *
   * Used to find currently active entry in `entries` hierarchy using entry `id`
   *
   * Contains list of of IDs separated by dot, for example, 1.2.3.4.
   * Values "" or "0" are not permitted as an `id`.
   *
   * Doesn't make sense for flat menus.
   */
  activeEntryPath?: string

  slideDirection?: SlideDirection
}

export class ChromeMenu extends React.PureComponent<ChromeMenuProps, ChromeMenuState> {
  private rootRef = React.createRef<HTMLDivElement>()

  private backHotKeys: HotKeyCode[] = ["KeyB", "Backspace"]

  private readonly soundManager: SoundManager
  private readonly clickAudioFeedbackEnabled: boolean
  private focusIndex: number

  public constructor(props: ChromeMenuProps) {
    super(props)
    this.state = {
      activeEntryPath: this.props.initialActiveEntryPath,
      slideDirection: "forward"
    }

    this.soundManager = HIGState.soundManager
    this.clickAudioFeedbackEnabled = HIGState.clickAudioFeedbackEnabled

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleMenuFocus = this.handleMenuFocus.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.focusIndex = -1
  }

  private handleMenuFocus() {
    if (!this.rootRef.current) {
      return
    }
    // Focus on first menu item after slide transition
    const firstMenuItem = getFirstFocusableElement(".list-item__inner--menu", this.rootRef.current)
    if (firstMenuItem) {
     firstMenuItem.focus()
    }
  }

  public handleBackClick() {
    const entryPath = this.getPreviousEntryPath()
    this.setState({
      activeEntryPath: entryPath,
      slideDirection: "backward"
    }, () => {
      const { activeEntry } = this.findActiveEntry()
      this.props.onMenuEntryClick?.(activeEntry, false)
    })
  }

  public handleEntryClick(entry: ChromeMenuEntry) {
    const hasSubEntries = !!entry.children?.length
    if (hasSubEntries) {
      const entryPath = this.getEntryPath(entry)
      this.setState({
        slideDirection: "forward",
        activeEntryPath: entryPath,
      }, () => {
        this.props.onMenuEntryClick?.(entry, false)
      })
    } else if (this.props.onMenuEntryClick) {
      this.props.onMenuEntryClick?.(entry, true)
    }
  }

  public getPreviousEntryPath() {
    return this.state.activeEntryPath
      ? this.state.activeEntryPath.split(".").slice(0, -1).join(".") || undefined
      : undefined
  }

  public getEntryPath(entry: ChromeMenuEntry) {
    return this.state.activeEntryPath
      ? [this.state.activeEntryPath, entry.id].join(".")
      : entry.id
  }

  public handleKeyDown(srcEvent: Event) {
    if (!this.rootRef.current) {
      return
    }
    const event = srcEvent as KeyboardEvent

    const { activeEntry } = this.findActiveEntry()

    // get direction of document
    // TODO: not accurate; need fixing
    const ltr = !!document.querySelector("[dir='ltr']")

    const list = this.rootRef.current
                  .querySelectorAll(".list-item__inner--menu") as NodeListOf<HTMLElement>

    if (list.length > 0) {
      if (getEventKeyCode(event) === KeyCode.Down ||
          getEventKeyCode(event) === KeyCode.Right ||
          getEventKeyCode(event) === KeyCode.Up ||
          getEventKeyCode(event) === KeyCode.Left) {
        preventEventDefault(event)
        if (ltr) {
          if (getEventKeyCode(event) === KeyCode.Right) {
            this.focusIndex = 0
            list[this.focusIndex].focus()
          }
          if (getEventKeyCode(event) === KeyCode.Left) {
            if (activeEntry) {
              if (this.clickAudioFeedbackEnabled) {
                this.soundManager.play("confirmation")
              }
              // Go back to parent menu
              this.handleBackClick()
            }

            // Focus on the first element in menu when going back from submenu
            this.focusIndex = 0
          }
        } else {
          if (getEventKeyCode(event) === KeyCode.Left) {
            this.focusIndex = 0
          }
          if (getEventKeyCode(event) === KeyCode.Right) {
            if (activeEntry) {
              if (this.clickAudioFeedbackEnabled) {
                this.soundManager.play("confirmation")
              }
              // Go back to parent menu
              this.handleBackClick()
            }
            // Focus on the first element in menu when going back from submenu
            this.focusIndex = 0
          }
        }
        if (getEventKeyCode(event) === KeyCode.Down) {
          this.focusIndex = this.focusIndex + 1
          if (this.focusIndex >= list.length) {
            this.focusIndex = list.length - 1
          }
        }
        if (getEventKeyCode(event) === KeyCode.Up) {
          this.focusIndex = this.focusIndex - 1
          if (this.focusIndex < 0) {
            this.focusIndex = 0
          }
        }
        list[this.focusIndex].focus()
      }
    }
  }

  public componentDidMount() {
    addEventListener(document, "keydown", this.handleKeyDown)
  }

  public componentWillUnmount() {
    removeEventListener(document, "keydown", this.handleKeyDown)
  }

  public findActiveEntry() {
    if (!this.props.entries?.length
      || !this.state.activeEntryPath?.length
    ) {
      return { activeEntry: undefined, previousEntry: undefined }
    }

    const pathParts = this.state.activeEntryPath.split(".")

    let currentChildren = this.props.entries
    let currentEntry: ChromeMenuEntry | undefined
    let previousEntry: ChromeMenuEntry | undefined

    while (currentChildren.length) {
      const currentEntryID = pathParts.shift()
      if (!currentEntryID) {
        break
      }
      previousEntry = currentEntry
      currentEntry = currentChildren.find((entry) => entry.id === currentEntryID)
      currentChildren = currentEntry?.children ?? []
    }

    return { activeEntry: currentEntry, previousEntry }
  }

  public render() {
    const strings = this.props.strings || {}
    const { activeEntry } = this.findActiveEntry()
    const entries = activeEntry ? activeEntry.children : this.props.entries
    const menuViewID = `${this.props.viewID}-${this.state.activeEntryPath}`

    return (
      <div
        className="chrome-menu"
        ref={this.rootRef}
      >
        <SlideLayout
          direction={this.state.slideDirection}
          onTransitionCompleted={this.handleMenuFocus}
          viewID={menuViewID}
        >
          <List
            itemSize={this.props.itemSize}
            menu={true}
            ariaLabel={activeEntry ? activeEntry.label : undefined}
            stickyHeaders={false}
          >
            {activeEntry && (
              <List.Item
                key="back"
                menu={true}
                button={true}
                onClick={this.handleBackClick}
              >
                <List.ItemIcon name="chevron-left" />
                <List.ItemText>
                  <LocalizedText stringID={strings.menuBack} defaultString="Back" />
                </List.ItemText>
                <List.ItemHotKey keyCode={this.backHotKeys} />
              </List.Item>
            )}
            {(entries || []).map((entry) => {
              const actionable = entry.button !== false
              return (
                <List.Item
                  key={entry.id}
                  menu={true}
                  button={actionable}
                  onClick={actionable ? this.handleEntryClick.bind(this, entry) : undefined}
                  hasChildren={actionable && entry.children ? true : undefined}
                >
                  {entry.icon && (
                    <List.ItemIcon name={entry.icon} />
                  )}
                  {actionable && entry.children && (
                    <List.ItemSecondaryIcon name="chevron-right" />
                  )}
                  {actionable && entry.hotKey && (
                    <List.ItemHotKey keyCode={entry.hotKey} />
                  )}
                  <List.ItemText>
                    <LocalizedText
                      attributed={true}
                      stripAttributes={true}
                      enableAttributes={["bold"]}
                      {...entry.label}
                    />
                  </List.ItemText>
                </List.Item>
              )
            })}
          </List>
        </SlideLayout>
      </div>
    )
  }
}
