import React from "react"

import { deviceDetector } from "../../utilities/DeviceDetector"
import { HotKeyCode } from "./HotKeyCode"
import { HotKeySet } from "./HotKeyResponder"

export interface HotKeyProps {
  /**
   * Key code or set of key codes.
   */
  keyCode?: HotKeySet
}

/**
 * Format and display hot key code in user-friendly form.
 *
 * If hot key is not set, then hot key is not rendered. If multiple hot keys are
 * set then only first will be rendered but the rest can be used to infer all
 * possible hot keys by upper level component.
 */
export class HotKey extends React.PureComponent<HotKeyProps> {
  public static readonly CodeToString: Record<HotKeyCode, string> = {
    Digit0: "0",
    Digit1: "1",
    Digit2: "2",
    Digit3: "3",
    Digit4: "4",
    Digit5: "5",
    Digit6: "6",
    Digit7: "7",
    Digit8: "8",
    Digit9: "9",
    KeyA: "A",
    KeyB: "B",
    KeyC: "C",
    KeyD: "D",
    KeyE: "E",
    KeyF: "F",
    KeyG: "G",
    KeyH: "H",
    KeyI: "I",
    KeyJ: "J",
    KeyK: "K",
    KeyL: "L",
    KeyM: "M",
    KeyN: "N",
    KeyO: "O",
    KeyP: "P",
    KeyQ: "Q",
    KeyR: "R",
    KeyS: "S",
    KeyT: "T",
    KeyU: "U",
    KeyV: "V",
    KeyW: "W",
    KeyX: "X",
    KeyY: "Y",
    KeyZ: "Z",
    Enter: "Enter",
    Space: "Space",
    Escape: "Escape",
    Backspace: "Backspace"
  }

  public static describeHotKeySet(keyCode: HotKeySet) {
    const firstKeyCode = Array.isArray(keyCode)
      ? keyCode[0]
      : keyCode

    return HotKey.CodeToString[firstKeyCode] || firstKeyCode
  }

  public render() {
    if (!deviceDetector.hasPhysicalKeyboard() || !this.props.keyCode) {
      return null
    }

    const displayableText = `[${HotKey.describeHotKeySet(this.props.keyCode)}]`

    return (
      <kbd className="hot-key" aria-hidden="true">
        {displayableText}
      </kbd>
    )
  }
}
