import React from "react"

import { renderTestID } from "../helpers/renderTestID"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { KeyCodeString } from "../keyboard/KeyCodeString"
import { getEventKeyCode } from "../../utilities/browser/getEventKeyCode"
import { KeyCode } from "../keyboard/KeyCode"

export type FormValues = Record<string, unknown>

export interface FormProps extends DangerousProps, TestProps {
  /**
   * Triggered when user submits the valid form.
   *
   * All form values are passed as object.
   */
  onSubmit?: (values: FormValues) => void

  /**
   * Triggered when form can't be submitted due to an error.
   */
  onError?: (values: FormValues, invalidValues: FormValues) => void

  /**
   * Triggered when user resets the form.
   */
  onReset?: () => void
}

type FormField = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

/**
 * Standard form component that collects all field values and triggers
 * submit or reset callbacks.
 *
 * If form file name ends with "$json" it will try to safely parse it as JSON
 * before triggering onSubmit event. This is needed for complex components
 * that need to return more complex data structures than just plain string
 * value.
 */
export class Form extends React.PureComponent<FormProps> {
  private readonly jsonSuffix = "$json"

  private readonly formRef = React.createRef<HTMLFormElement>()

  public constructor(props: FormProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  public handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    // prevent processing of form using default handler
    event.preventDefault()

    this.processSubmit()
  }

  public processSubmit() {
    if (this.formRef.current) {
      const form = this.formRef.current
      const fields = this.getFormFields(form)

      // this is a rare use case when
      const invalidFields = this.getInvalidFields(fields)

      if (invalidFields.length === 0) {
        // onSubmit callback
        if (this.props.onSubmit) {
          const formValues = this.getFieldValues(fields)
          this.props.onSubmit(formValues)
        }
      } else {
        // focus first invalid field
        invalidFields[0].focus()

        // onError callback
        if (this.props.onError) {
          const formValues = this.getFieldValues(fields)
          const invalidValues = this.getFieldValues(invalidFields)
          this.props.onError(formValues, invalidValues)
        }
      }
    }
  }

  public getInvalidFields(fields: FormField[]) {
    const invalidFields: FormField[] = []

    for (const field of fields) {
      if ((field.validity && !field.validity.valid)
        // @ts-ignore
        || (field.$validate && !field.$validate())
      ) {
        invalidFields.push(field)
      }
    }

    return invalidFields
  }

  public getFieldValues(fields: FormField[]) {
    // extract raw form values
    const rawFormValues = this.getRawFormValues(fields)

    // parse json values if available
    return Object.entries(rawFormValues)
      .reduce<FormValues>((result, [rawName, rawValue]) => {
        const isJSON = rawName.endsWith(this.jsonSuffix)
        const name = isJSON
          ? rawName.substring(0, rawName.length - this.jsonSuffix.length)
          : rawName
        if (isJSON) {
          result[name] = this.safeJsonParse(rawValue)
        } else if (typeof rawValue === "string") {
          result[name] = this.normalizeLineEndings(rawValue)
        } else {
          result[name] = rawValue
        }
        return result
      }, {})
  }

  /**
   * There are some complications here with the use of WebComponent.
   *
   * For backward compatibility, element-internals-polyfill is used for attachInternals.
   *
   * For browsers not supporting attachInternals, element-internals-polyfill creates a
   * shadow/hidden element. For example, if we have <alchmey-input name="ai">,
   * element-internals-polyfill creates <input name="ai" value="alchemy-input's value"/>
   * alongside the WebComponent. During the iteration, we will visit the inputs with the
   * same name twice, once with invalid value (from WebComponent) and one with correct value
   * (from the hidden input). We need to carefully handle this.
   * On top of this, we need to convert Switch's value to boolean by checking if its
   * data-type is switch, which is lost on the hidden input.
   *
   */
  public getRawFormValues(fields: FormField[]) {
    return fields
      .reduce<Record<string, string | boolean>>((result, field, index) => {
        // If `field` is a shadow element, the alchemy element should just precede it
        let shadowedAlchemyElement
        if (index >= 1
          && !field.nodeName.startsWith("ALCHEMY-")
          && fields[index - 1].nodeName.startsWith("ALCHEMY-")
          && fields[index - 1].name === field.name
          ) {
            shadowedAlchemyElement = fields[index - 1]
        }
        // unnamed and disabled elements should not be included in the form values
        if (field.name && (!field.disabled && (!shadowedAlchemyElement?.disabled))) {
          const isSwitch = (field.getAttribute("data-type") === "switch")
            || (shadowedAlchemyElement?.getAttribute("data-type") === "switch")
          result[field.name] = isSwitch
            // @ts-ignore
            ? (typeof field.value === "boolean" ? field.value : field.value === "true")
            : (field.value || "")  // fallback to empty value
        }
        return result
      }, {})
  }

  public getFormFields(form: HTMLFormElement) {
    const validNodeNames = new Set([
      "INPUT", "SELECT", "TEXTAREA",
      "ALCHEMY-INPUT", "ALCHEMY-TEXTAREA", "ALCHEMY-SWITCH"
    ])
    const fields: FormField[] = []

    for (let i = 0, len = form.elements.length; i < len; i++) {
      const element = form.elements[i]

      if (validNodeNames.has(element.nodeName)) {
        fields.push(element as FormField)
      }
    }

    return fields
  }

  public normalizeLineEndings(value: string | undefined | null) {
    // normalize non-unux line endings \r\n or \r to the unix format \n
    // this is needed for consistent line ending format across different platforms
    return value ? value.replace(/\r\n|\r/g, "\n") : ""
  }

  public safeJsonParse(value: unknown) {
    if (typeof value === "string") {
      try {
        return JSON.parse(value)
      } catch (e) {
        console.warn(`Unable to parse JSON value "${value}":`, e)
      }
    } else {
      console.warn(`Unable to parse type as JSON: "${typeof value}"`)
    }
  }

  public handleReset() {
    if (this.formRef.current) {
      const form = this.formRef.current
      const fields = this.getFormFields(form)
      fields.forEach((field) => this.resetField(field))
    }

    if (this.props.onReset) {
      this.props.onReset()
    }
  }

  public resetField(field: FormField) {
    // @ts-ignore
    if (field.$reset) {
      // @ts-ignore
      field.$reset()
    }
  }

  public handleKeyDown(event: KeyboardEvent) {
    if (getEventKeyCode(event) !== KeyCode.Enter) {
      return
    }
    this.tryAutoSubmitForm(event)
  }

  // With Alchemy, implicit submission of form is no longer working.
  // So we need to implement it ourselves.
  // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#implicit-submission
  // This is not a very technically complete implementation, but should be
  // good enough.
  public tryAutoSubmitForm(event: UIEvent) {
    if (((event.target as HTMLElement).nodeName) !== "ALCHEMY-INPUT") {
      return
    }
    this.formRef.current?.requestSubmit()
  }

  public componentDidMount(): void {
    this.formRef.current?.addEventListener("keydown", this.handleKeyDown)
  }

  public componentWillUnmount(): void {
    this.formRef.current?.removeEventListener("keydown", this.handleKeyDown)
  }

  public render() {
    const { dangerousClassName, dangerousStyle } = this.props

    const className = "form"
      + (dangerousClassName ? ` ${dangerousClassName}` : "")

    return (
      <form
        className={className}
        style={dangerousStyle}
        onSubmit={this.handleSubmit}
        onReset={this.handleReset}
        ref={this.formRef}
        data-testid={renderTestID(this.props.testID)}
      >
        {this.props.children}
      </form>
    )
  }
}
