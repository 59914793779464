/**
 * Only HTTPS links are supported now
 * https://w.amazon.com/bin/view/MSA/HowTo/UsingCorrectImageDomains/#Using_Correct_Image_Domains
 */

const endpoints = {
  prod: "https://m.media-amazon.com",
  corp: "https://internal-cdn.amazon.com/internal-origin-na-1.amazon.com",
}

/**
 * Detect MSA image server based on desired protocol and fabric.
 */
export function getProductImageServer(fabric: "prod" | "corp") {
  return endpoints[fabric]
}
