import React from "react"

import { safeReactChildrenOnly } from "../../utilities/react/safeReactChildrenOnly"
import { HotKeyCode } from "./HotKeyCode"
import { withHotKeyActivity, WithHotKeyActivity } from "./withHotKeyActivity"
import { defaultHotKeyDispatcher } from "./defaultHotKeyDispatcher"

export type HotKeySet = HotKeyCode | HotKeyCode[]

export type HotKeyMatch = HotKeySet | "*"

interface Props {
  /**
   * Single hot key, set of hot keys or wildcard "*".
   *
   * If not set, then responder doesn't perform any actions.
   */
  keyCode?: HotKeyMatch

  /**
   * Triggered when hot key matches the input.
   *
   * If callback doesn't return anything then dispatcher assumes that it was processed.
   *
   * If callback returns false then dispatcher assumes that it was NOT processed
   * and dispatcher will try to process hot key in another responder.
   */
  onKeyPress?: (keyCode: HotKeyCode) => (boolean | void)

  /**
   * Is hot key zone active (true) or inactive (false)?
   *
   * Injected automatically.
   */
  hotKeyActive: boolean
}

/**
 * Responds to hot keys dispatched by HotKeyDispatcher.
 *
 * Filters hot keys based on currently active hot key zone.
 */
class HotKeyResponderBase extends React.PureComponent<Props> {
  private hotKeySubscriptionID?: number

  public constructor(props: Props) {
    super(props)
    this.handleHotKey = this.handleHotKey.bind(this)
  }

  public subscribe() {
    if (!this.props.keyCode || !this.props.onKeyPress) {
      return
    }

    this.hotKeySubscriptionID = defaultHotKeyDispatcher.subscribe(this.handleHotKey)
  }

  public unsubscribe() {
    if (this.hotKeySubscriptionID) {
      defaultHotKeyDispatcher.unsubscribe(this.hotKeySubscriptionID)
      this.hotKeySubscriptionID = undefined
    }
  }

  public componentDidMount() {
    this.subscribe()
  }

  public componentWillUnmount() {
    this.unsubscribe()
  }

  public handleHotKey(keyCode: HotKeyCode) {
    if (!this.props.hotKeyActive || !this.props.onKeyPress || !this.props.keyCode) {
      return false
    }

    if (this.matchesKey(keyCode, this.props.keyCode)) {
      const result = this.props.onKeyPress(keyCode)
      if (result !== false) {
        return true
      }
    }

    return false
  }

  public matchesKey(incomingKey: HotKeyCode, matchKey: HotKeyMatch) {
    return matchKey === incomingKey
      || matchKey === "*"
      || (Array.isArray(matchKey) && matchKey.length && matchKey.includes(incomingKey))
  }

  public render() {
    return safeReactChildrenOnly(this.props.children)
  }
}

export const HotKeyResponder = Object.assign(
  withHotKeyActivity(HotKeyResponderBase),
  {
    displayName: "HotKeyResponder",
  }
)
