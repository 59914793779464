import React from "react"

import { Subtract } from "utility-types"

import { DisableProGloveDisplayContext } from "./DisableProGloveDisplayContext"

export interface DisableProGloveDisplayAware {
  disableProGloveDisplay: boolean
}

/**
 * HoC function that injects DisableDisplayMessagesContext as `disableDisplayMessages` property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withDisableProGloveDisplay = <
  S extends DisableProGloveDisplayAware = DisableProGloveDisplayAware,
  D = Subtract<S, DisableProGloveDisplayAware>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <DisableProGloveDisplayContext.Consumer>
      {(disableProGloveDisplay) => (
        <WrappedComponent
          {...(props as unknown as S)}
          disableProGloveDisplay={disableProGloveDisplay}
        />
      )}
    </DisableProGloveDisplayContext.Consumer>
  )

  wrapper.displayName = `withDisableProGloveDisplay(${displayName})`

  return wrapper
}
