import React from "react"

import { Subtract } from "utility-types"

import { Translator } from "./Translator"
import { TranslatorContext } from "./TranslatorContext"

export interface WithTranslator {
  translator: Translator
}

/**
 * HoC function that injects Translator as `translator` property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withTranslator = <
  S extends WithTranslator = WithTranslator,
  D = Subtract<S, WithTranslator>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <TranslatorContext.Consumer>
      {(translator) => (
        <WrappedComponent
          {...(props as unknown as S)}
          translator={translator}
        />
      )}
    </TranslatorContext.Consumer>
  )

  wrapper.displayName = `withTranslator(${displayName})`

  return wrapper
}
