import React from "react"

import { HotKeyCode } from "../keyboard/HotKeyCode"
import { PlaySound } from "../sound/PlaySound"
import { FlexLayout } from "../layout/flex/FlexLayout"
import { AddHotKey } from "../keyboard/AddHotKey"
import { LocalizedText } from "../text/LocalizedText"
import { Button } from "../button/Button"
import { Text } from "../text/Text"

type ChromeNotificationModalStringKey = "to" | "from" | "continue"

export type ChromeNotificationModalStrings =
  Partial<Record<ChromeNotificationModalStringKey, string>>

interface ChromeNotificationModalProps {
  /**
   * Message ID (passed to confirm callback as it is).
   */
  messageID: string

  /**
   * Submitter ID.
   */
  submitterID: string

  /**
   * Recipient ID.
   */
  recipientID: string

  /**
   * Message text.
   */
  messageText: string

  /**
   * Callback executed when user confirms the message.
   */
  onConfirm?: (messageID: string) => void
}

/**
 * Display notification to the user.
 *
 * Designed to display FANS messages but can be used for any messages.
 */
export class ChromeNotificationModal extends React.PureComponent<ChromeNotificationModalProps> {
  public reminderInterval = 30 // in secs / value is approved by bartolm@

  // override this value to customize strings and provide translations
  public static defaultStrings: ChromeNotificationModalStrings = {}

  public static readonly defaultTexts: Record<ChromeNotificationModalStringKey, string> = {
    to: "To: {recipientID}",
    from: "From: {submitterID}",
    continue: "Continue",
  }

  public static readonly hotKeys: Record<"continue", HotKeyCode> = {
    continue: "Enter",
  }

  // enable scroll if content doesn't fit
  // it is assumed that modal occupies whole screen and there are not other
  // components that could have scroll potentially enabled
  public readonly scrollableStyle: React.CSSProperties = {
    overflow: "auto",
  }

  public constructor(props: ChromeNotificationModalProps) {
    super(props)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  public handleConfirm() {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.props.messageID)
    }
  }

  public render() {
    const { submitterID, recipientID, messageText } = this.props
    const { defaultStrings, defaultTexts, hotKeys } = ChromeNotificationModal

    return (
      <PlaySound
        id="notification"
        sound="attention"
        reminder={this.reminderInterval}
      >
        <FlexLayout direction="column">
          <FlexLayout.Item
            padding="sm"
          >
            <FlexLayout
              direction="column"
              backgroundColor="bright-blue"
              rounded={true}
            >
              <FlexLayout.Item padding="sm" size="auto">
                <div>
                  <LocalizedText
                    variant="white"
                    size="md"
                    stringID={defaultStrings.from}
                    defaultString={defaultTexts.from}
                    parameters={{ submitterID }}
                  />

                  <br />

                  <LocalizedText
                    variant="white"
                    size="md"
                    stringID={defaultStrings.to}
                    defaultString={defaultTexts.to}
                    parameters={{ recipientID }}
                  />
                </div>
              </FlexLayout.Item>

              <FlexLayout.Splitter />

              <FlexLayout.Item padding="sm" dangerousStyle={this.scrollableStyle}>
                <Text
                  variant="white"
                  size="md"
                  bold={true}
                >
                  {messageText}
                </Text>
              </FlexLayout.Item>
            </FlexLayout>
          </FlexLayout.Item>

          <FlexLayout.Item
            size="auto"
            padding="sm"
            backgroundColor="white"
          >
            <Button
              variant="primary"
              size="md"
              fluid={true}
              onClick={this.handleConfirm}
            >
              <AddHotKey keyCode={hotKeys.continue}>
                <LocalizedText
                  stringID={defaultStrings.continue}
                  defaultString={defaultTexts.continue}
                />
              </AddHotKey>
            </Button>
          </FlexLayout.Item>
        </FlexLayout>
      </PlaySound>
    )
  }
}
