import React from "react"

import debounce from "lodash.debounce"

import { LocalizedString } from "../i18n/Translator"
import { LocalizedText } from "../text/LocalizedText"
import { Icon } from "../icon/Icon"
import { LocaleContext } from "../i18n/LocaleContext"
import { HIGState } from "../../config/HIGState"
import { ChromeMenuVariant } from "./ChromeMenuVariant"
import { DirSide, parseDirSideBox } from "../helpers/parseDirSide"
import { SoundManager } from "../../utilities/sound/SoundManager"
import { ChromeMenuHeaderSize } from "./ChromeMenuHeaderSize"
import { VerticalAligner } from "../layout/util/VerticalAligner"
import { HotKeyResponder, HotKeySet } from "../keyboard/HotKeyResponder"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

interface Props extends TestProps {
  /**
   * What side to render close (cross) icon.
   */
  closeIconSide?: DirSide

  /**
   * What is the size of header.
   *
   * Tightly coupled with Chrome navigation bar size.
   */
  size?: ChromeMenuHeaderSize

  /**
   * Header color.
   *
   * Used to differentiate visually main and problem menu.
   */
  variant?: ChromeMenuVariant

  /**
   * Header title.
   */
  title?: LocalizedString

  /**
   * Hot keys used as alternative to click.
   */
  hotKey?: HotKeySet

  /**
   * Callback when header is clicked.
   */
  onClick?: () => void
}

/**
 * Chrome menu header with close icon.
 */
export class ChromeMenuHeader extends React.PureComponent<Props> {
  private readonly soundManager: SoundManager

  private readonly clickAudioFeedbackEnabled: boolean

  private readonly clickDebounceDelay = 100 // in ms

  public constructor(props: Props) {
    super(props)

    this.handleClick = debounce(this.handleClick.bind(this),
      this.clickDebounceDelay, { leading: true, trailing: false })

    this.handleHotKey = this.handleHotKey.bind(this)

    this.soundManager = HIGState.soundManager
    this.clickAudioFeedbackEnabled = HIGState.clickAudioFeedbackEnabled
  }

  public componentWillUnmount() {
    // Debounced methods should be cancelled to prevent setState() after destroy
    // @ts-ignore
    this.handleClick.cancel()
  }

  public handleClick() {
    if (this.clickAudioFeedbackEnabled) {
      this.soundManager.play("confirmation")
    }

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  public handleHotKey() {
    this.handleClick()
  }

  public render() {
    return (
      <LocaleContext.Consumer>
        {({ direction }) => {
          const size = this.props.size || "standard"
          const variant = this.props.variant || "default"
          const closeIconSide = this.props.closeIconSide || "natural"

          const boxSide = parseDirSideBox(closeIconSide, direction)

          const headerClassName = "chrome-menu-header"
            + ` chrome-menu-header--variant-${variant}`
            + ` chrome-menu-header--side-${boxSide}`
            + ` chrome-menu-header--size-${size}`

          const headerIconClassName = "chrome-menu-header__icon"

          // For A11y purpose, we can't nest a div inside a button. So we use span instead
          // and style it as a block element.
          const content = (
            <button
              className={headerClassName}
              onClick={this.handleClick}
              data-testid={renderTestID(this.props.testID)}
              aria-haspopup={true}
              aria-expanded={true}
            >
              <span className={headerIconClassName}>
                <VerticalAligner />
                <Icon
                  name="menu-close"
                  size={32}
                  color="white"
                  dangerousClassName={headerIconClassName}
                />
              </span>
              <span className="chrome-menu-header__title">
                <VerticalAligner />
                <LocalizedText
                  attributed={true}
                  stripAttributes={true}
                  {...this.props.title}
                />
              </span>
            </button>
          )

          if (this.props.hotKey) {
            return (
              <HotKeyResponder
                keyCode={this.props.hotKey}
                onKeyPress={this.handleHotKey}
              >
                {content}
              </HotKeyResponder>
            )
          }

          return content
        }}
      </LocaleContext.Consumer>
    )
  }
}
