export type IconSize = 14 | 16 | 20 | 24 | 28 | 32 | 64

export const IconSizeMap: Record<IconSize,  string> = {
    14: "0.875",
    16: "1",
    20: "1.25",
    24: "1.5",
    28: "1.75",
    32: "2",
    64: "4",
}
