import React from "react"
import { getEventKeyCode } from "../../utilities/browser/getEventKeyCode"
import { preventEventDefault } from "../../utilities/browser/preventEventDefault"
import { addEventListener, removeEventListener } from "../../utilities/browser/addEventListener"
import { ViewportCapsContext } from "../layout/viewport/ViewportCapsContext"
import { KeyCode } from "../keyboard/KeyCode"

export type NavBarVariant = "default" | "error" | "success" | "warning"

export interface NavBarProps {
  variant?: NavBarVariant
}

export class NavBar extends React.PureComponent<NavBarProps> {
  public static defaultHeightRem = 2.875  // in rem. 46 px if font-size is 16px

  public static expandedHeightRem = 4 // in rem. 64 px if font-size is 16px

  private focusIndex: number
  private activeNavBar?: Element
  private prevNavBar?: Element

  private readonly rootRef = React.createRef<HTMLDivElement>()

  public constructor(props: NavBarProps) {
    super(props)
    this.focusIndex = -1
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  public componentDidMount() {
    addEventListener(document, "keydown", this.handleKeyDown)
  }

  public componentWillUnmount() {
    removeEventListener(document, "keydown", this.handleKeyDown)
  }

  public handleFocus(event: React.FocusEvent) {
    if (event.currentTarget === this.rootRef.current) {
      this.prevNavBar = this.activeNavBar
      this.activeNavBar = event.currentTarget
    }
  }

  public handleBlur(event: React.FocusEvent) {
    // If parent element does not contain focused element, set navbar to inactive
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      this.activeNavBar = undefined
    }
  }

  public handleKeyDown(srcEvent: Event) {
    const event = srcEvent as KeyboardEvent

    if (!this.activeNavBar) {
      return
    }

    if (this.prevNavBar !== this.activeNavBar) {
      this.focusIndex = 0
    }

    // get direction of document
    const ltr = document.querySelector("[dir='ltr']") ? true : false

    const navButtonList =
      this.activeNavBar.querySelectorAll(".navbar-button") as NodeListOf<HTMLElement>

    if (navButtonList.length > 0) {
      if (getEventKeyCode(event) === KeyCode.Right ||
          getEventKeyCode(event) === KeyCode.Left) {
        preventEventDefault(event)
        if (ltr) {
          if (getEventKeyCode(event) === KeyCode.Right) {
            this.focusIndex = this.focusIndex + 1
            if (this.focusIndex >= navButtonList.length) {
              this.focusIndex = navButtonList.length - 1
            }
          } else if (getEventKeyCode(event) === KeyCode.Left) {
            this.focusIndex = this.focusIndex - 1
            if (this.focusIndex < 0) {
              this.focusIndex = 0
            }
          }
        } else {
          if (getEventKeyCode(event) === KeyCode.Left) {
            this.focusIndex = this.focusIndex + 1
            if (this.focusIndex >= navButtonList.length) {
              this.focusIndex = navButtonList.length - 1
            }
          } else if (getEventKeyCode(event) === KeyCode.Right) {
            this.focusIndex = this.focusIndex - 1
            if (this.focusIndex < 0) {
              this.focusIndex = 0
            }
          }
        }
        navButtonList[this.focusIndex].focus()
      }
    }
  }

  public render() {
    return (
      <ViewportCapsContext.Consumer>
        {(viewportCaps) => {
          const variant = this.props.variant || "default"

          const size = viewportCaps.hSizeAtLeast("md") ? "expanded" : "standard"

          const className = "navbar"
            + ` navbar--variant-${variant}`
            + ` navbar--size-${size}`

          return (
            <header
              className={className}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={this.rootRef}
            >
              {this.props.children}
            </header>
          )
        }}
      </ViewportCapsContext.Consumer>
    )
  }
}
