import React from "react"

import { Badge } from "../badge/Badge"
import { FontSize } from "../theme/Theme"
import { TestProps } from "../helpers/TestProps"

export interface ProductQuantityLabelProps extends TestProps {
  /**
   * Quantity to be presented by this component.
   */
  quantity: number

  /**
   * The size to render the quantity label with.
   */
  size?: ProductQuantityLabelSize
}

export type ProductQuantityLabelSize = FontSize

export class ProductQuantityLabel extends React.PureComponent<ProductQuantityLabelProps> {

  public render() {
    const {
      size = "sm",
      quantity,
    } = this.props

    if (quantity < 1 || isNaN(quantity)) {
      return null
    }

    return (
      <Badge size={size} variant="info" bold={true} testID={this.props.testID}>
        {quantity} ×
      </Badge>
    )
  }

}
