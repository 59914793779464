
/**
 * MessageFormat only provides one single value for the argument
 * https://messageformat.github.io/messageformat/custom-formatters/.
 * If we need multiple values, we need to define our own format.
 *
 * The format is x:y:z where each component is joined by :
 *
 * @returns a list of values extracted from arg
 */
export function parseFormatterArg(arg?: string) {
  const args: string[] = arg ? arg.split(":") : []
  return args.map((value) => value.trim())
}
