import React from "react"

import { BaseProductBox, BaseProductBoxProps } from "./BaseProductBox"

import { FlexLayout } from "../../layout/flex/FlexLayout"

export interface TwoColumnProductBoxProps extends BaseProductBoxProps {
  hideImage?: boolean
}

export class TwoColumnProductBox extends BaseProductBox<TwoColumnProductBoxProps> {
  private renderWithImage() {
    const packOfN = this.renderPackOfN()
    const nonImageComponents = [
      this.renderProductIdAndQuantity("md"),
      this.renderProductBinding(),
      this.renderProductTitle(),
      this.renderProductBrand()
    ].filter((v) => !!v) as JSX.Element[]

    const mainPart = (
      <FlexLayout
        direction="row"
        testID={!packOfN ? this.props.testID : undefined}
      >
        {this.renderProductImage("1fr")}
        <FlexLayout.Item key="descriptions" size="2fr" padding="xs">
          <FlexLayout direction="column">
            {this.interweaveComponentsWithSpacer(nonImageComponents)}
          </FlexLayout>
        </FlexLayout.Item>
      </FlexLayout>
    )

    if (packOfN) {
      return (
        <FlexLayout
          direction="column"
          testID={this.props.testID}
        >
          {packOfN}
          <FlexLayout.Item>
            {mainPart}
          </FlexLayout.Item>
        </FlexLayout>
      )
    } else {
      return mainPart
    }
  }

  private renderWithouImage() {
    const components = [
      this.renderPackOfN(),
      this.renderProductIdAndQuantity("md"),
      this.renderProductBinding(),
      this.renderProductTitle(),
      this.renderProductBrand()
    ].filter((v) => !!v) as JSX.Element[]

    return (
      <FlexLayout
        direction="column"
        testID={this.props.testID}
      >
        {this.interweaveComponentsWithSpacer(components)}
      </FlexLayout>
    )
  }

  public render() {
    return this.props.hideImage
      ? this.renderWithouImage()
      : this.renderWithImage()
  }
}
