import { Spacing, Theme } from "../theme/Theme"
import { Direction } from "../i18n/LocaleContext"

// This should match `12.34rem`, `0.378rem` and `123rem`.
type RemString = `${number}rem`

/**
 * If you decided to use string, it can only be `{number}rem` or the predefined sizes such as `lg`.
 * Other values are consider invalid and result in `0`.
 */
// `string` is needed for computed value.
export type Padding = Spacing | number | RemString | string

// mimic React Native: https://facebook.github.io/react-native/docs/layout-props#padding
export interface PaddingProps {
  padding?: Padding
  paddingTop?: Padding
  paddingRight?: Padding
  paddingBottom?: Padding
  paddingLeft?: Padding
  paddingStart?: Padding
  paddingEnd?: Padding
  paddingHorizontal?: Padding
  paddingVertical?: Padding
}

export function parsePadding(value: Padding, theme: Theme) {
  if (typeof value === "number") {
    return `${value}px`
  }
  if (typeof value === "string") {
    if (value.endsWith("rem")) {
      return value
    }
    const themeValue = theme.spacing[value as Spacing]
    if (themeValue === undefined) {
      // Fallback to 0 if padding is invalid
      return "0"
    }
    return `${themeValue}rem`
  }
  throw new TypeError("Invalid padding value: " + value)
}

export function convertPaddingPropsToStyle(
  props: PaddingProps,
  theme: Theme,
  direction: Direction,
) {
  const style: React.CSSProperties = {}

  if (props.padding !== undefined) {
    style.padding = parsePadding(props.padding, theme)
  }

  if (props.paddingHorizontal !== undefined) {
    style.paddingLeft = parsePadding(props.paddingHorizontal, theme)
    style.paddingRight = parsePadding(props.paddingHorizontal, theme)
  }

  if (props.paddingVertical !== undefined) {
    style.paddingTop = parsePadding(props.paddingVertical, theme)
    style.paddingBottom = parsePadding(props.paddingVertical, theme)
  }

  if (props.paddingStart !== undefined) {
    if (direction === "rtl") {
      style.paddingRight = parsePadding(props.paddingStart, theme)
    } else {
      style.paddingLeft = parsePadding(props.paddingStart, theme)
    }
  }

  if (props.paddingEnd !== undefined) {
    if (direction === "rtl") {
      style.paddingLeft = parsePadding(props.paddingEnd, theme)
    } else {
      style.paddingRight = parsePadding(props.paddingEnd, theme)
    }
  }

  if (props.paddingTop !== undefined) {
    style.paddingTop = parsePadding(props.paddingTop, theme)
  }

  if (props.paddingBottom !== undefined) {
    style.paddingBottom = parsePadding(props.paddingBottom, theme)
  }

  if (props.paddingLeft !== undefined) {
    style.paddingLeft = parsePadding(props.paddingLeft, theme)
  }

  if (props.paddingRight !== undefined) {
    style.paddingRight = parsePadding(props.paddingRight, theme)
  }

  return style
}
