import React from "react"

import { memoizeOne } from "../../utilities/memoizeOne"
import { withTranslator } from "../i18n/withTranslator"
import { LocalizedString, Translator } from "../i18n/Translator"
import { Locale } from "../i18n/LocaleContext"
import { withLocale } from "../i18n/withLocale"
import { StatusBarProgressConfig } from "./StatusBarProgressConfig"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

export interface StatusBarProgressProps extends TestProps {
  /**
   * Custom localized string to override the aria-label accessibility tag.
   */
  ariaLabel?: LocalizedString,

  /**
   * Custom localized string to override the aria-valuetext accessibility tag.
   */
  ariaValueText?: LocalizedString,

  /**
   * Min value for progress.
   */
  min?: number,

  /**
   * Max value for progress.
   */
  max?: number,

  /**
   * The progress to present for StatusBarProgress component.
   * Should be in the [min, max] range.
   * Values larger than max will display as a full progress bar,
   * and values smaller than min will display an empty progress bar.
   */
  now: number

  /**
   * Color to be used on the progress bar.
   */
  color?: "light" | "dark"

  /**
   * The alignment of the progress bar.
   */
  align?: "natural" | "reverse"

  /**
   * Translator is used to translate aria labels.
   *
   * Injected automatically.
   */
  translator: Translator

  /**
   * Locale is used to detect progress bar direction.
   *
   * Injected automatically.
   */
  locale: Locale
}

/**
 * Component to be used within the StatusBar if a progress is to be presented.
 * Please refer to the StatusBarProgressProps above for details on how it is rendered.
 */
class StatusBarProgressBase extends React.PureComponent<StatusBarProgressProps> {
  constructor(props: StatusBarProgressProps) {
    super(props)
    this.getStyle = memoizeOne(this.getStyle.bind(this))
  }

  public getStyle(progressPercent: number): React.CSSProperties {
    return {
      width: `${progressPercent}%`
    }
  }

  public render() {
    const {
      ariaValueText,
      min = 0,
      max = 100,
      now,
      color = "light",
      align = "natural",
      locale,
      translator,
    } = this.props

    const startDirection: "right" | "left" = (locale.direction === "ltr")
      ? (align === "reverse" ? "right" : "left")
      : (align === "reverse" ? "left" : "right")

    const className = "status-bar-progress"
      + ` status-bar-progress--color-${color}`
      + ` status-bar-progress--direction-${startDirection}`

    const adjustedProgress = Math.min(max, Math.max(min, now))
    const progressPercent = (adjustedProgress - min) / (max - min) * 100

    const progressLabel = this.props.ariaLabel || ({
      stringID: StatusBarProgressConfig.defaultStrings.ariaLabel,
      defaultString: "Status progress",
    })

    return (
      <div
        className={className}
        style={this.getStyle(progressPercent)}
        role="progressbar"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={adjustedProgress}
        aria-label={progressLabel ? translator.lookup(progressLabel) : undefined}
        aria-valuetext={ariaValueText ? translator.lookup(ariaValueText) : undefined}
        data-testid={renderTestID(this.props.testID)}
      />
    )
  }
}

export const StatusBarProgress = Object.assign(
  withLocale(
    withTranslator(StatusBarProgressBase)
  ), {
    displayName: "StatusBarProgress",
    isStatusBarProgress: true,
  }
)
