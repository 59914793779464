import React from "react"

import { renderTestID } from "../helpers/renderTestID"
import { TestProps } from "../helpers/TestProps"

export class AlertParagraph extends React.PureComponent<TestProps> {
  public render() {
    return (
      <p
        className="alert-paragraph"
        data-testid={renderTestID(this.props.testID)}
      >
        {this.props.children}
      </p>
    )
  }
}
