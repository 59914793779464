// The bin ID structure is mostly as defined in
// https://w.amazon.com/bin/view/FC_Space_Management/Bin_Configuration/#Layout_of_an_FC

// Example: P-1-A234B567
const BIN_ID_REGEX = /^([PR])-([0-9]+)-([A-Z])([0-9]+)([A-Z])([0-9]+)$/

export enum BinAreaType {
  Prime = "P",
  Reserve = "R",
}

export interface BinIDProps {
  areaType: BinAreaType
  floor: string
  mod: string
  aisle: string
  shelf: string
  bin: string
}

export class BinID {
  public areaType: BinAreaType
  public floor: string
  public mod: string
  public aisle: string
  public shelf: string
  public bin: string

  public constructor(props: BinIDProps) {
    this.areaType = props.areaType
    this.floor = props.floor
    this.mod = props.mod
    this.aisle = props.aisle
    this.shelf = props.shelf
    this.bin = props.bin
  }

  public toString(): string {
    return `${this.areaType}-${this.floor}-${this.mod}${this.aisle}${this.shelf}${this.bin}`
  }

  public static from(binID: string): BinID | undefined {
    const match = BIN_ID_REGEX.exec(binID)
    if (match) {
      return new BinID({
        areaType: match[1] as BinAreaType,
        floor: match[2],
        mod: match[3],
        aisle: match[4],
        shelf: match[5],
        bin: match[6]
      })
    }
    return undefined
  }
}

// Example: 3C
const AR_BIN_ID_REGEX = /^([0-9])([A-M])$/

export interface ARBinIDProps {
  col: string
  row: string
}

export class ARBinID {
  // The digit part
  public col: string
  // The letter part
  public row: string

  public constructor(props: ARBinIDProps) {
    this.col = props.col
    this.row = props.row
  }

  public toString(): string {
    return `${this.col}${this.row}`
  }

  public static from(binID: string): ARBinID | undefined {
    const match = AR_BIN_ID_REGEX.exec(binID)
    if (match) {
      return new ARBinID({
        col: match[1],
        row: match[2]
      })
    }
    return undefined
  }
}
