import React from "react"

import { CartSlotVariant } from "./CartSlotVariant"
import { FlexLayout } from "../layout/flex/FlexLayout"
import { CartSlot, CartSlotProps, CartSlotSize } from "./CartSlot"
import { Size } from "../helpers/parseSize"
import { LocalizedString } from "../i18n/Translator"
import { renderTestID } from "../helpers/renderTestID"
import { TestProps } from "../helpers/TestProps"

/**
 * Specifications for one of the totes displayed in the grid.
 */
export interface SelectableSlot {
  /**
   * Tote's location.
   */
  location: string

  /**
   * Description presented at the bottom of the tote.
   */
  description?: LocalizedString

  /**
   * The variant applied on the tote.
   */
  variant?: CartSlotVariant

  /**
   * Color of the tote.
   */
  color?: string

  /**
   * Whether or not switching the selection is enabled on this tote.
   */
  selectable?: boolean

  /**
   * Whether or not this tote is selected by default and disabled for unselecting by the user.
   */
  forceSelect?: boolean
}

export interface SelectableMultiSlotCartProps extends TestProps {
  /**
   * The number of rows in the grid.
   */
  rows: number

  /**
   * The number of columns in the grid.
   */
  cols: number

  /**
   * Spacing between the cells in the grid.
   */
  spacingSize?: Size

  /**
   * Size used to render the inner CartSlot components.
   */
  slotSize?: CartSlotSize

  /**
   * The list of the totes to be presented by this component.
   */
  slots: SelectableSlot[]

  /**
   * Optional validator callback, which will be invoked when doing form validation.
   */
  validator?: (selectedSlots: string[]) => boolean
}

interface SelectableMultiSlotCartState {
  /**
   * The list of the slots selected.
   */
  selectedSlots: string[]
}

interface ViewModel {
  rows: ViewModelRow[]
}

type ViewModelRow = "spacer" | { cols: ViewModelColumn[] }
type ViewModelColumn = "spacer" | ViewModelSlot

interface ViewModelSlot extends Omit<CartSlotProps, "translator" | "layoutSize" | "location"> {
  location: string
}

/**
 * Renders a grid of cart slots.
 * A maximum of one slot could be active at a given moment.
 * Any number of slots could be prepared.
 * @deprecated
 */
export class SelectableMultiSlotCart extends React.PureComponent<
  SelectableMultiSlotCartProps,
  SelectableMultiSlotCartState> {

  private readonly inputRef = React.createRef<HTMLInputElement>()

  public constructor(props: SelectableMultiSlotCartProps) {
    super(props)

    this.state = {
      selectedSlots: []
    }
  }

  public componentDidMount() {
    this.attachHooks()
  }

  public componentWillUnmount() {
    this.detachHooks()
  }

  public getSlotSelection(): string[] {
    return this.state.selectedSlots
      .concat(
        this.props.slots
          .filter((slot) => slot.forceSelect)
          .map((slot) => slot.location
          )
      )
  }

  public attachHooks() {
    // used to check if form is valid on form submit stage
    const input = this.inputRef.current
    if (input) {
      // @ts-ignore
      input.$validate = () => {
        return this.props.validator ? this.props.validator(this.getSlotSelection()) : true
      }

      // @ts-ignore
      input.$reset = () => {
        this.setState({ selectedSlots: [] })
      }
    }
  }

  public detachHooks() {
    // clear hooks to prevent resource leak
    const input = this.inputRef.current
    if (input) {
      // @ts-ignore
      input.$validate = undefined
      // @ts-ignore
      input.$reset = undefined
    }
  }

  public handleSelect(location: string) {
    const selectedSlots = this.state.selectedSlots

    if (selectedSlots.includes(location)) {
      this.setState({ selectedSlots: selectedSlots.filter((slot) => slot !== location) })
    } else {
      this.setState({ selectedSlots: selectedSlots.concat(location) })
    }
  }

  /**
   * Evaluates a view model of what is to be rendered.
   */
  private buildViewModel(): ViewModel {
    const {
      slotSize,
      slots,
    } = this.props

    let {
      rows,
      cols,
    } = this.props

    if (rows < 1 || isNaN(rows)) {
      rows = 1
    }

    if (cols < 1 || isNaN(cols)) {
      cols = 1
    }

    if (slots.length < (rows * cols)) {
      console.warn(
        `Invalid slots array size (${slots.length}) ` +
        "passed to to MultiSlotCart component " +
        `with (rows, cols) = (${rows}, ${cols})`,
      )
    }

    const viewModelRows: ViewModelRow[] = []

    Array.from(Array(rows).keys()).forEach((row) => {
      const viewModelColumns: ViewModelColumn[] = []

      Array.from(Array(cols).keys()).forEach((col) => {
        const slotInd = row * cols + col
        const slot = slots[slotInd]
        const selectable = slot.selectable && !slot.forceSelect
        const selected =
          slot.forceSelect
          || this.state.selectedSlots.includes(slot.location)
        const empty = !selected && !selectable

        viewModelColumns.push({
          location: slot.location,
          description: slot.description,
          size: slotSize,
          variant: empty ? "empty" : (selected ? slot.variant : "inactive"),
          color: selected ? slot.color : undefined,
          selectable,
          selected,
        })

        if (col < (cols - 1)) {
          viewModelColumns.push("spacer")
        }
      })

      viewModelRows.push({
        cols: viewModelColumns
      })

      if (row < (rows - 1)) {
        viewModelRows.push("spacer")
      }
    })

    return {
      rows: viewModelRows
    }
  }

  public render() {
    const {
      spacingSize = "sm",
      slotSize,
      slots,
    } = this.props

    let {
      rows,
      cols,
    } = this.props

    if (rows < 1 || isNaN(rows)) {
      rows = 1
    }

    if (cols < 1 || isNaN(cols)) {
      cols = 1
    }

    if (slots.length < (rows * cols)) {
      console.warn(
        `Invalid slots array size (${slots.length}) ` +
        "passed to to MultiSlotCart component " +
        `with (rows, cols) = (${rows}, ${cols})`,
      )
    }

    const viewModel = this.buildViewModel()

    return (
      <div className="select-multi-slot-cart" data-testid={renderTestID(this.props.testID)}>
        <FlexLayout direction="column">
          {viewModel.rows.map((row, rowInd) => (
            (row === "spacer" ? (
              <FlexLayout.Spacer
                key={"row_spacer_" + rowInd}
                size={spacingSize}
              />
            ) : (
              <FlexLayout.Item key={"row_" + rowInd}>
                <FlexLayout direction="row">
                  {row.cols.map((col, colInd) => (
                    (col === "spacer" ? (
                      <FlexLayout.Spacer
                        key={"col_spacer_" + colInd}
                        size={spacingSize}
                      />
                    ) : (
                      <FlexLayout.Item key={"col_" + colInd}>
                        <CartSlot
                          location={col.location}
                          description={col.description}
                          size={slotSize}
                          variant={col.variant}
                          color={col.color}
                          selectable={col.selectable}
                          selected={col.selected}
                          onSelect={this.handleSelect.bind(this, col.location)}
                        />
                      </FlexLayout.Item>
                    ))
                  ))}
                </FlexLayout>
              </FlexLayout.Item>
            ))
          ))}
        </FlexLayout>

        <input
          ref={this.inputRef}
          type="hidden"
          name="selected_slots$json"
          value={JSON.stringify(this.getSlotSelection())}
        />
      </div>
    )
  }
}
