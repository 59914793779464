import React from "react"

import { Direction, LocaleContext } from "./LocaleContext"

// @ts-ignore: Missing $$typeof
const DirectionConsumer: React.Consumer<Direction> = (props) => {
  return (
    <LocaleContext.Consumer>
      {({ direction }) => props.children ? props.children(direction) : false}
    </LocaleContext.Consumer>
  )
}

// @ts-ignore: Missing $$typeof
const DirectionProvider: React.Provider<Direction> = ({ value, children }) => {
  return (
    <LocaleContext.Provider value={{ locale: "", direction: value }}>
      {children || null}
    </LocaleContext.Provider>
  )
}

/**
 * DirectionContext is DEPRECATED in favor of LocaleContext.
 *
 * @deprecated
 */
export const DirectionContext: React.Context<Direction> = {
  Consumer: DirectionConsumer,
  Provider: DirectionProvider,
}

// @ts-ignore: Keep legacy displayName to no break clients.
DirectionContext.displayName = "Consumer"
