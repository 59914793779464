import React from "react"

import { ViewportCaps } from "../viewport/ViewportCaps"
import { ViewportCapsContext } from "../viewport/ViewportCapsContext"

export interface LimitLayoutProps {
  /**
   * Sets max width.
   *
   * By default automatically detects optimal width based on viewport capabilities,
   * but custom value in pixels can be passed.
   */
  maxWidth?: "auto" | number

  /**
   * Set to fit this layout into parent container.
   *
   * Set to `true` if used inside FlexLayout.Item.
   */
  flex?: boolean
}

/**
 * Limits container width to prevent text to be rendered in one single lone line
 * on ultra wide screens.
 */
export class LimitLayout extends React.PureComponent<LimitLayoutProps> {
  public getStyle(viewportCaps: ViewportCaps) {
    const targetMaxWidth = this.props.maxWidth || "auto"
    const maxWidth = targetMaxWidth === "auto" ? 400 : (this.props.maxWidth as number)
    const width = viewportCaps.width > maxWidth ? maxWidth : "100%"
    return { width }
  }

  public render() {
    const className = "limit-layout"
      + (this.props.flex ? " limit-layout--flex" : "")

    return (
      <ViewportCapsContext.Consumer>
        {(viewportCaps) => (
          <div className={className}>
            <div className="limit-layout__inner" style={this.getStyle(viewportCaps)}>
              {this.props.children}
            </div>
          </div>
        )}
      </ViewportCapsContext.Consumer>
    )
  }
}
