import { Theme } from "./Theme"

const spacingBaseRem = 1.5

export const LightTheme: Theme = {
  verticalUnitSizeRem: 1.5,

  spacing: {
    none: 0,
    xxs:  spacingBaseRem * 0.125,
    xs:   spacingBaseRem * 0.25,
    sm:   spacingBaseRem * 0.5,
    md:   spacingBaseRem,
    lg:   spacingBaseRem * 1.5,
    xl:   spacingBaseRem * 2,
    xxl:  spacingBaseRem * 3,
  }
}
