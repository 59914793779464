import React from "react"

import { safeReactChildrenOnly } from "../../utilities/react/safeReactChildrenOnly"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

export class Main extends React.PureComponent<TestProps> {
  public render() {
    const content = safeReactChildrenOnly(this.props.children)
    return (
      <main className="main" data-testid={renderTestID(this.props.testID)}>
        {content}
      </main>
    )
  }
}
