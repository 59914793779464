import React from "react"

import { ChromeConfirmationModal } from "./ChromeConfirmationModal"
import { HotKeyCode } from "../keyboard/HotKeyCode"

type ChromeVoiceAckModalStringKey =
  "caption" | "body" | "acknowledge" | "reject"

export type ChromeVoiceAckModalStrings = Partial<Record<ChromeVoiceAckModalStringKey, string>>

interface ChromePresenceModalProps {
  /**
   * Callback executed when user manually acknowledges speech use.
   */
  onReject?: () => void

  /**
   * Callback executed when user manually refuses speech use.
   */
  onAcknowledge?: () => void
}

/**
 * Acknowledgement modal that is displayed to user when they first log in and speech is enabled.
 */
export class ChromeVoiceAckModal extends React.PureComponent<ChromePresenceModalProps> {
  // override this value to customize strings and provide translations
  public static defaultStrings: ChromeVoiceAckModalStrings = {}

  public static readonly defaultTexts: Record<ChromeVoiceAckModalStringKey, string> = {
    caption: "Acknowledge use of speech?",
    body: "This app uses the microphone to transmit speech to the cloud for the entire duration of the process.",
    acknowledge: "Acknowledge",
    reject: "Reject",
  }

  public static readonly hotKeys: Record<"acknowledge" | "reject", HotKeyCode> = {
    acknowledge: "Enter",
    reject: "KeyX",
  }

  public render() {
    const { defaultTexts, defaultStrings, hotKeys } = ChromeVoiceAckModal

    return (
      <ChromeConfirmationModal
        id="voiceAcknowledgement"

        caption={{
          stringID: defaultStrings.caption,
          defaultString: defaultTexts.caption,
        }}

        body={{
          stringID: defaultStrings.body,
          defaultString: defaultTexts.body,
        }}

        primaryButtonHotKey={hotKeys.acknowledge}
        primaryButtonLabel={{
          stringID: defaultStrings.acknowledge,
          defaultString: defaultTexts.acknowledge,
        }}
        onPrimaryButtonClick={this.props.onAcknowledge}

        secondaryButtonHotKey={hotKeys.reject}
        secondaryButtonLabel={{
          stringID: defaultStrings.reject,
          defaultString: defaultTexts.reject,
        }}
        onSecondaryButtonClick={this.props.onReject}
      />
    )
  }
}
