import React from "react"

import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"

import { ThemeVariant } from "./Theme"

export interface SegmentProps extends TestProps {
  variant?: ThemeVariant
}

/**
 * A container component with theme variant support. You'll get a similar styling as that of Alert,
 * including background-color and font color.
 *
 * The component will cover the full area of its parent.
 */
export class Segment extends React.PureComponent<SegmentProps> {
  private readonly defaultVariant = "none"

  public render() {
    const variant = this.props.variant ?? this.defaultVariant
    const className = ["segment", `theme--variant-${variant}`].join(" ")

    return (
      <div
        className={className}
        data-testid={renderTestID(this.props.testID)}
      >
        {this.props.children}
      </div>
    )
  }
}
