let map = new Map();

//Devo configuration - client_id: edams-cli-fe-integ
map.set('f65e1b16-152a-458c-ada7-1cad19703aeb', 'https://prod.us-west-2.federated-auth.aft.a2z.com/f65e1b16-152a-458c-ada7-1cad19703aeb/validate/userCode/');

//Prod configurations:
//EDAMS prod FE - client_id: edams-cli-jp-prod
map.set('e19d31d5-5c62-4da9-a5d2-383e14a687b4', 'https://prod.us-west-2.federated-auth.aft.a2z.com/e19d31d5-5c62-4da9-a5d2-383e14a687b4/validate/userCode/');
//EDAMS prod NA - client_id: edams-cli-na-prod
map.set('4aa65c0e-34df-4735-9416-3344804b0ec7', 'https://prod.us-east-1.federated-auth.aft.a2z.com/4aa65c0e-34df-4735-9416-3344804b0ec7/validate/userCode/');
//EDAMS prod EU - client_id: edams-cli-eu-prod
map.set('e946b961-e7f7-4e77-8b56-a1694ba49aeb', 'https://prod.eu-west-1.federated-auth.aft.a2z.com/e946b961-e7f7-4e77-8b56-a1694ba49aeb/validate/userCode/');
//EDM Prod NA - client_id: edm-cli-na-prod
map.set('41d6ab12-4a51-4561-ad82-ce5eaffd1d0c', 'https://prod.us-east-1.federated-auth.aft.a2z.com/41d6ab12-4a51-4561-ad82-ce5eaffd1d0c/validate/userCode/');
//EDM Prod EU - client_id: edm-cli-eu-prod
map.set('e8641168-89a2-4810-a755-a8b4d4daae84', 'https://prod.eu-west-1.federated-auth.aft.a2z.com/e8641168-89a2-4810-a755-a8b4d4daae84/validate/userCode/');
//EDM Prod JP - client_id: edm-cli-jp-prod
map.set('6165e2e2-56f0-4eb0-b3a4-9bb4a911110c', 'https://prod.us-west-2.federated-auth.aft.a2z.com/6165e2e2-56f0-4eb0-b3a4-9bb4a911110c/validate/userCode/');


// UWC Integ configuration
map.set('8b20175d-5d9e-4f16-bd28-01485592ee54', 'https://beta.us-west-2.federated-auth.aft.a2z.com/8b20175d-5d9e-4f16-bd28-01485592ee54/validate/userCode/');

// UWC Prod configuration:
map.set('3d92144c-c39d-4689-a925-0e4738c92728', 'https://prod.{{Region}}.federated-auth.aft.a2z.com/3d92144c-c39d-4689-a925-0e4738c92728/validate/userCode/');  // {{Region}} filled in from webpack

export default map;