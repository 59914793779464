import { PrintMonOptions, PrintMonLike } from "./PrintMonLike"

export type PrintMonStubStatus = "good" | "bad"

/**
 * PrintMon stub implementation to test successful and failed print requests
 * without real printer in test environments and locally.
 */
export class PrintMonStub implements PrintMonLike {
  private readonly printDelay = 1000

  private readonly status: PrintMonStubStatus

  public constructor(status: PrintMonStubStatus) {
    this.status = status
  }

  public async print(options: PrintMonOptions): Promise<void> {
    console.log("PrintMon.print()", options)

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.status === "bad") {
          reject(new Error("PrintMon returned wrong status: invalid"))
        } else {
          resolve()
        }
      }, this.printDelay)
    })
  }
}
