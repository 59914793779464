import React from "react"

/**
 * This is alternative to `React.Children.only()` that is safer (doesn't crash on undefined).
 */
export function safeReactChildrenOnly(
  children: React.ReactNode,
): Exclude<React.ReactNode, undefined | React.ReactNodeArray> {
  const child = Array.isArray(children) ? React.Children.only(children) : children
  return child !== undefined ? child : null
}
