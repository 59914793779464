import { Direction } from "../i18n/LocaleContext"

export type DirSide =  "natural" | "reverse" | "left" | "right"

/**
 * Converts direction side definition to regular css side.
 */
export function parseDirSideBox(side: DirSide, direction: Direction): "left" | "right" {
  if (side === "left" || side === "right") {
    return side
  }

  if (direction === "ltr") {
    return side === "natural" ? "left" : "right"
  } else {
    return side === "natural" ? "right" : "left"
  }
}

/**
 * Converts direction side definition to natural/reverse side.
 */
export function parseDirSideNat(side: DirSide, direction: Direction): "natural" | "reverse" {
  if (side === "natural" || side === "reverse") {
    return side
  }

  if (direction === "ltr") {
    return side === "left" ? "natural" : "reverse"
  } else {
    return side === "left" ? "reverse" : "natural"
  }
}

// for backward compatibility
export const parseDirSide = parseDirSideBox
