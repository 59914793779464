import MessageFormat from "messageformat"

import { upperCase } from "./formatters/upperCase"
import { lowerCase } from "./formatters/lowerCase"
import { relativeTime } from "./formatters/relativeTime"
import { ProjectionType } from "../../config/ProjectionType"
import { binForView, binForVoice } from "./formatters/bin"

export interface MessageFormatOptions {
  projectionType?: ProjectionType
}

export function createMessageFormat({
    projectionType = "view",
  }: MessageFormatOptions = {}) {
  const messageFormat = new MessageFormat()

  // Breaks formats like "The total is {V, number, currency:{C}}" { V: 5.5, C: "EUR" }
  // but could be needed to render properly mixed LTR/RTL strings.
  // messageFormat.setBiDiSupport(true)

  messageFormat.addFormatters({
    uppercase: upperCase,
    lowercase: lowerCase,
    relativetime: relativeTime,
  })

  if (projectionType === "view") {
    messageFormat.addFormatters({
      bin: binForView
    })
  } else if (projectionType === "voice") {
    messageFormat.addFormatters({
      bin: binForVoice
    })
  }

  return messageFormat
}
