import React from "react"

import { Subtract } from "utility-types"

import { LocaleContext, Locale } from "./LocaleContext"

export interface WithLocale {
  locale: Locale
}

/**
 * HoC function that injects Locale as `locale` property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withLocale = <
  S extends WithLocale = WithLocale,
  D = Subtract<S, WithLocale>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <LocaleContext.Consumer>
      {(locale) => (
        <WrappedComponent
          {...(props as unknown as S)}
          locale={locale}
        />
      )}
    </LocaleContext.Consumer>
  )

  wrapper.displayName = `withLocale(${displayName})`

  return wrapper
}
