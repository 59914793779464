import React from "react"

export class NavTitle extends React.PureComponent {
  public render() {
    return (
      <div className="navbar-title">
        <h1 className="navbar-title__inner">
          {this.props.children}
        </h1>
      </div>
    )
  }
}
