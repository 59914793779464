import React from "react"

import { LocalizedText } from "../text/LocalizedText"
import { LocalizedString } from "../i18n/Translator"
import { NavBar, NavBarVariant } from "./NavBar"
import { NavButton } from "./NavButton"
import { NavTitle } from "./NavTitle"
import { ChromeStrings } from "../chrome/Chrome"

export interface DefaultNavBarProps {
  /**
   * The NavBarVariant to be used on the NavBar composed in DefaultNavBarProps.
   */
  variant?: NavBarVariant

  /**
   * Title to be used on the Default nav bar.
   */
  title?: LocalizedString

  /**
   * Other strings to be used in this component.
   */
  strings?: ChromeStrings

  /**
   * Whether or not the component is being used in a dismissible
   * flow (i.e. A flow that logically branches off from the rest
   * of the flow, and could either be proceeded with, or dismissed
   * at any time).
   */
  dismissible?: boolean

  /**
   * Callback to be invoked when the main menu button is clicked.
   * Only applicable when dismissible is not set.
   */
  onMainMenuClick?: () => void

  /**
   * Callback to be invoked when the main problems button is clicked.
   * Only applicable when dismissible is not set.
   */
  onProblemMenuClick?: () => void

  /**
   * Callback to be invoked when the dismiss button is clicked.
   * Only applicable when dismissible is set.
   */
  onDismissClick?: () => void

  /**
   * Is the main menu showing?
   */
  mainMenuExpanded?: boolean

  /**
   * Is the problem menu showing?
   */
  problemMenuExpanded?: boolean
}

export class DefaultNavBar extends React.PureComponent<DefaultNavBarProps> {
  public constructor(props: DefaultNavBarProps) {
    super(props)

    this.handleMainMenuClick = this.handleMainMenuClick.bind(this)
    this.handleProblemMenuClick = this.handleProblemMenuClick.bind(this)
    this.handleDismissClick = this.handleDismissClick.bind(this)
  }

  public handleMainMenuClick() {
    if (this.props.onMainMenuClick) {
      this.props.onMainMenuClick()
    }
  }

  public handleProblemMenuClick() {
    if (this.props.onProblemMenuClick) {
      this.props.onProblemMenuClick()
    }
  }

  public handleDismissClick() {
    if (this.props.onDismissClick) {
      this.props.onDismissClick()
    }
  }

  public render() {
    const strings = this.props.strings || {}

    const title = this.props.title || { defaultString: "" }

    return (
      <NavBar variant={this.props.variant}>
        {!this.props.dismissible && (
          <NavButton
            side="natural"
            iconName="menu"
            hotKey="KeyM"
            onClick={this.handleMainMenuClick}
            testID="mainMenuButton"
            ariaLabel={{ stringID: strings.mainMenu, defaultString: "Menu" }}
            hasPopUp={true}
            popUpExpanded={this.props.mainMenuExpanded}
          >
            <LocalizedText stringID={strings.mainMenu} defaultString="Menu"/>
          </NavButton>
        )}

        {this.props.dismissible && (
          <NavButton
            side="reverse"
            iconName="menu-close"
            hotKey="KeyX"
            onClick={this.handleDismissClick}
            ariaLabel={{ stringID: strings.cancel, defaultString: "Cancel" }}
          >
            <LocalizedText stringID={strings.cancel} defaultString="Cancel"/>
          </NavButton>
        )}

        <NavTitle>
          <LocalizedText {...title} />
        </NavTitle>

        {!this.props.dismissible && (
          <NavButton
            side="reverse"
            iconName="question"
            hotKey="KeyP"
            onClick={this.handleProblemMenuClick}
            testID="problemMenuButton"
            ariaLabel={{ stringID: strings.problemMenu, defaultString: "Problem" }}
            hasPopUp={true}
            popUpExpanded={this.props.problemMenuExpanded}
          >
            <LocalizedText stringID={strings.problemMenu} defaultString="Problem"/>
          </NavButton>
        )}
      </NavBar>
    )
  }
}
