import React from "react"

import { ListItemSize, ListOptionsContext } from "./ListOptionsContext"
import { ListItem } from "./ListItem"
import { ListSubheader } from "./ListSubheader"
import { ListSplitter } from "./ListSplitter"
import { ListItemText } from "./ListItemText"
import { ListItemIcon } from "./ListItemIcon"
import { ListItemHotKey } from "./ListItemHotKey"
import { ListItemSecondaryIcon } from "./ListItemSecondaryIcon"
import { ScrollableLayout } from "../layout/scrollable/ScrollableLayout"
import { memoizeOne } from "../../utilities/memoizeOne"
import { addEventListener, removeEventListener } from "../../utilities/browser/addEventListener"
import { preventEventDefault } from "../../utilities/browser/preventEventDefault"
import { getEventKeyCode } from "../../utilities/browser/getEventKeyCode"
import { KeyCode } from "../keyboard/KeyCode"
import { LocalizedString } from "../.."

export interface ListProps {
  itemSize?: ListItemSize
  stickyHeaders?: boolean
  menu?: boolean
  ariaLabel?: LocalizedString
}

/**
 * List component.
 *
 * Inspired by https://material-ui.com/components/lists/ .
 */
export class List extends React.PureComponent<ListProps> {
  public static Item = ListItem
  public static Subheader = ListSubheader
  public static Splitter = ListSplitter
  public static ItemText = ListItemText
  public static ItemIcon = ListItemIcon
  public static ItemHotKey = ListItemHotKey
  public static ItemSecondaryIcon = ListItemSecondaryIcon

  public static defaultProps = {
    stickyHeaders: true,
  }

  private focusIndex: number
  private activeList?: Element
  private prevList?: Element

  /**
   * handle mouse down to prevent focus from jumping
   * to top of the list when clicking on a list item
   * during keyboard tab events
   */
  private mouseDown: boolean

  public constructor(props: ListProps) {
    super(props)
    this.getListOptions = memoizeOne(this.getListOptions.bind(this))
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.focusIndex = -1
    this.mouseDown = false
  }

  public getListOptions(itemSize: ListItemSize, stickyHeaders: boolean) {
    return { itemSize, stickyHeaders }
  }

  public wrapChildrenForNativeSticky(items: React.ReactNode[]) {
    let currentGroup: React.ReactNode[] = []
    const groups: React.ReactNode[][] = []

    const flushGroup = () => {
      if (currentGroup.length) {
        groups.push(currentGroup)
      }
      currentGroup = []
    }

    items.forEach((item) => {
      if (React.isValidElement(item) && item.type === List.Subheader) {
        flushGroup()
      }

      currentGroup.push(item)
    })

    flushGroup()

    return groups.map((group, index) => (
      <div key={index} className="list-sticky-group">{group}</div>)
    )
  }

  public handleMouseDown() {
    this.mouseDown = true
    return this.mouseDown
  }

  public handleMouseUp() {
    this.mouseDown = false
    return this.mouseDown
  }

  public handleFocus(element: HTMLDivElement) {
    this.prevList = this.activeList
    this.activeList = element

    // only focus on first list item for keyboard tab events (not mouse clicks)
    if (!this.prevList && this.activeList && !this.mouseDown) {
      const listItems =
        this.activeList.querySelectorAll(".list-item__inner--button") as NodeListOf<HTMLElement>
      if (listItems && listItems.length > 0) {
        this.focusIndex = 0
        listItems[this.focusIndex].focus()
      }
    }
  }

  public handleBlur() {
    // Reset prevList and activeList state if List is not focused
    this.prevList = undefined
    this.activeList = undefined
  }

  public handleKeyDown(srcEvent: Event) {
    const event = srcEvent as KeyboardEvent

    // Ignore KeyDown if list is not focused
    if (!this.activeList) {
      return
    }

    if (this.prevList !== this.activeList) {
      this.focusIndex = -1
    }

    // Get list of items on the screen
    const listItems =
      this.activeList.querySelectorAll(".list-item__inner--button") as NodeListOf<HTMLElement>

    if (listItems.length > 0) {
      if (getEventKeyCode(event) === KeyCode.Down) {
        preventEventDefault(event)

        this.focusIndex = this.focusIndex + 1
        if (this.focusIndex >= listItems.length) {
          this.focusIndex = listItems.length - 1
        }

        listItems[this.focusIndex].focus()
      } else if (getEventKeyCode(event) === KeyCode.Up) {
        preventEventDefault(event)

        this.focusIndex = this.focusIndex - 1
        if (this.focusIndex < 0) {
          this.focusIndex = 0
        }

        listItems[this.focusIndex].focus()
      }
    }
  }

  public componentDidMount() {
    addEventListener(document, "keydown", this.handleKeyDown)
    addEventListener(document, "mousedown", this.handleMouseDown)
    addEventListener(document, "mouseup", this.handleMouseUp)
  }

  public componentWillUnmount() {
    removeEventListener(document, "keydown", this.handleKeyDown)
    removeEventListener(document, "mousedown", this.handleMouseDown)
    removeEventListener(document, "mouseup", this.handleMouseUp)
  }

  public render() {
    const stickyHeaders = !!this.props.stickyHeaders
    const itemSize = this.props.itemSize || "standard"

    const listOptions = this.getListOptions(itemSize, stickyHeaders)

    const items = React.Children.toArray(this.props.children)

    const useNativeStickyHeaders = stickyHeaders && ScrollableLayout.nativeSticky

    return (
      <ListOptionsContext.Provider value={listOptions}>
        <ScrollableLayout
          stickyHeaders={this.props.stickyHeaders}
          dangerousClassName="list"
          ariaRole={this.props.menu ? "menu" : "list"}
          ariaLabel={this.props.ariaLabel}
          tabIndex={0}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          {useNativeStickyHeaders ? this.wrapChildrenForNativeSticky(items) : items}
        </ScrollableLayout>
      </ListOptionsContext.Provider>
    )
  }
}
