import React from "react"

import { LocalizedText } from "../text/LocalizedText"
import { ChromeStrings } from "./Chrome"
import { TextLayout } from "../layout/text/TextLayout"
import { ChromeMenu } from "./ChromeMenu"
import { ChromeMenuHeader } from "./ChromeMenuHeader"
import { ChromeMenuHeaderSize } from "./ChromeMenuHeaderSize"
import { FlexLayout } from "../layout/flex/FlexLayout"
import { NavBar } from "../nav/NavBar"
import { LocalizedString } from "../i18n/Translator"
import { HotKeyCode } from "../keyboard/HotKeyCode"

interface Props {
  /**
   * Localized strings used by default for component labels.
   */
  strings?: ChromeStrings

  /**
   * Menu header title.
   */
  headerTitle?: LocalizedString

  /**
   * Menu header size (depends on NavBar size).
   */
  headerSize: ChromeMenuHeaderSize

  /**
   * Callback when menu is clicked (cancels/closes menu).
   */
  onCancel?: () => void
}

/**
 * Problem menu content wrapper.
 *
 * Renders menu header and menu.
 * If menu is empty (no menu items), "no reportable problems" label is rendered instead of the menu.
 */
export class ChromeProblemMenuContent extends React.PureComponent<Props> {
  private readonly cancelHotKeys: HotKeyCode[] = ["KeyP", "KeyX", "Escape"]

  public constructor(props: Props) {
    super(props)
  }

  public isNonEmptyChromeMenu(children: React.ReactNode): children is React.ReactElement {
    return React.isValidElement(children)
      && children.type === ChromeMenu
      && children.props.entries
      && children.props.entries.length
  }

  public render() {
    const strings = this.props.strings || {}

    const headerHeight = this.props.headerSize === "standard"
      ? `${NavBar.defaultHeightRem}rem`
      : `${NavBar.expandedHeightRem}rem`

    const content = this.isNonEmptyChromeMenu(this.props.children)
      ? this.props.children
      : (
        <TextLayout vAlign="middle" hAlign="center">
          <LocalizedText
            variant="gray"
            size="lg"
            bold={true}
            stringID={strings.noReportableProblems}
            defaultString="No reportable problems"
            testID="noReportableProblems"
          />
        </TextLayout>
      )

    return (
      <FlexLayout direction="column">
        <FlexLayout.Item
          size={headerHeight}
        >
          <ChromeMenuHeader
            closeIconSide="reverse"
            variant="error"
            title={this.props.headerTitle}
            size={this.props.headerSize}
            hotKey={this.cancelHotKeys}
            onClick={this.props.onCancel}
            testID="closeProblemMenuButton"
          />
        </FlexLayout.Item>

        <FlexLayout.Item>
          {content}
        </FlexLayout.Item>
      </FlexLayout>
    )
  }
}
