/**
 * Retrieves bounding client rect in cross-platform way.
 */
export function getBoundingClientRect(element: Element): DOMRect {
  const rect = element.getBoundingClientRect()

  // ie7 has no .width and .height
  const width = rect.width ? rect.width : rect.right - rect.left
  const height = rect.height ? rect.height : rect.bottom - rect.top

  // @ts-ignore not full emulation
  return { ...rect, width, height }
}
