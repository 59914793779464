export type ObserverListener<T = unknown> = (event: T) => (boolean | undefined)

/**
 * Generic observer class that implements primitive observable pattern.
 *
 * If listener returns `false` then event won't be processed by other listeners
 * (equivalent to cancel event).
 *
 * Provides similar to EventTarget functionality but it is easier in interface,
 * easier to provide typings, easier to implement chain of responsibility pattern,
 * simple implementation that is easier than EventTarget polyfill.
 *
 * Recommended usage is to extend this class with explicit typing information:
 *
 * ```ts
 * export type MySignInEvent = { type: "signIn", username: string }
 * export type MySignOutEvent = { type: "signOut" }
 * export type MyEvent = SignInEvent | SignOutEvent
 * export class MyDispatcher extends Oberver<MyEvent> {}
 * ```
 */
export class Observer<T = unknown> {
  private listeners: Record<number, ObserverListener<T>> = {}

  private idGen = 0

  /**
   * Subscribe to all events and pass them to the provided listener.
   */
  public subscribe(listener: ObserverListener<T>): number {
    this.idGen += 1

    const id = this.idGen

    this.listeners[id] = listener

    return id
  }

  /**
   * Unsubscribe using previously returned by `subscribe()` ID or using
   * original listener that was passed to `subscribe()`.
   */
  public unsubscribe(subscription: number | ObserverListener<T>) {
    if (typeof subscription === "function") {
      for (const id in this.listeners) {
        const listener = this.listeners[id]

        if (listener === subscription) {
          delete this.listeners[id]
        }
      }
    } else {
      delete this.listeners[subscription]
    }
  }

  /**
   * Dispatch new event and pass it to all listeners.
   *
   * If listener returns `false` then event is treated as cancelled and won't
   * be passed to the rest of listeners. If first listener returns `false` then
   * all other listeners won't be called.
   */
  public dispatch(event: T): boolean {
    for (const id in this.listeners) {
      const listener = this.listeners[id]

      if (listener(event) !== false) {
        return true
      }
    }

    return false
  }

  /**
   * Clear all subscriptions.
   */
  public clear() {
    this.idGen = 0
    this.listeners = {}
  }
}
