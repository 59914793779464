import React from "react"

import { SoundName } from "../../utilities/sound/SoundName"
import { HIGState } from "../../config/HIGState"
import { withDeepMute } from "./withDeepMute"
import { withSlideLayoutActivity } from "../layout/slide/withSlideLayoutActivity"
import { safeReactChildrenOnly } from "../../utilities/react/safeReactChildrenOnly"

const { soundManager } = HIGState

interface PlaySoundProps {
  /**
   * One of provided by AFT UX sound names.
   */
  sound: SoundName

  /**
   * Mute disables play sound feature.
   *
   * Could be used to prevent sound when component is rendered but not visible.
   */
  mute?: boolean

  /**
   * Trigger sound periodically to remind, period is in seconds
   */
  reminder?: number // secs

  /**
   * Unique ID that can be used to skip playing sound if the same view
   * is updated.
   *
   * Without ID any rerender will trigger sound if unmuted.
   */
  id?: string

  /**
   * Deep mute switch (injected automatically).
   *
   * Sound will be muted if either `mute` or `deepMute` is set to `true`.
   */
  deepMute: boolean

  /**
   * Is component inside inactive slide (injected automatically).
   *
   * Works also as mute.
   */
  insideActiveSlide: boolean
}

/**
 * Plays sound.
 */
class PlaySoundBase extends React.PureComponent<PlaySoundProps> {
  private intervalID?: number

  public constructor(props: PlaySoundProps) {
    super(props)
    this.playSound = this.playSound.bind(this)
  }

  public componentDidUpdate(prevProps: PlaySoundProps) {
    const mute = !!(this.props.mute || this.props.deepMute || !this.props.insideActiveSlide)
    const changed = !this.props.id || this.props.id !== prevProps.id

    if (!mute && changed) {
      this.playSound()
    }
  }

  public componentDidMount() {
    const mute = !!(this.props.mute || this.props.deepMute || !this.props.insideActiveSlide)

    if (!mute) {
      this.playSound()
    }
  }

  public componentWillUnmount() {
    this.clearReminderIfSet()
  }

  public clearReminderIfSet() {
    if (this.intervalID) {
      clearInterval(this.intervalID)
      this.intervalID = undefined
    }
  }

  public scheduleReminderIfNeeded() {
    this.clearReminderIfSet()

    if (this.props.reminder) {
      this.intervalID = window.setInterval(
        this.playSound,
        this.props.reminder * 1000,
      )
    }
  }

  public playSound() {
    soundManager.play(this.props.sound)
    this.scheduleReminderIfNeeded()
  }

  public render() {
    return safeReactChildrenOnly(this.props.children)
  }
}

export const PlaySound = Object.assign(
  withSlideLayoutActivity(
    withDeepMute(PlaySoundBase),
  ),
  {
    displayName: "PlaySound",
  },
)
