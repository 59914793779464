import React from "react"

/**
 * Keeps information if child view of this context is active.
 *
 * During transition from one view to another one previous view will get this
 * context value `false` and next view will get `true`.
 *
 * This can be used to implement custom logic for previous view to not effect
 * on application state.
 */
export const SlideLayoutContext = React.createContext<boolean>(true)

SlideLayoutContext.displayName = "SlideLayoutContext"
