// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import isometric_cage_na_img from "../../resources/images/isometric-cage-na.i.svg"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface CageImageProps {

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Cage image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="cage").
 */
export class CageImage extends React.PureComponent<CageImageProps> {
  public render() {
    const { altText, translator } = this.props

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={isometric_cage_na_img}
        className="cage-image"
        alt={imageAltText}
      />
    )
  }
}
