import { WLSProGloveCommands } from "./WLSProGloveCommands"

export class VelocityWLSActionProvider {
    private readonly viewLinkID = "proglove-display-wls"

    private readonly viewLink: HTMLAnchorElement

    public constructor() {
        this.viewLink = this.createViewLink()
    }

    public preload() {
        // noop, assume preloaded
    }

    public unload() {
        this.viewLink.remove()
        // @ts-ignore release link to make IE happy
        this.viewLink = undefined
    }

    // tslint:disable-next-line:max-line-length
    public executeVelocityAction(wlsProGloveCommand: WLSProGloveCommands | undefined, wlsProGloveCommandParameters: string | undefined) {
        if (wlsProGloveCommand && wlsProGloveCommandParameters) {
          this.execVelocityAction(wlsProGloveCommand, wlsProGloveCommandParameters)
        }
    }

    // tslint:disable-next-line:max-line-length
    private execVelocityAction(wlsProGloveCommand: WLSProGloveCommands | undefined, wlsProGloveCommandParameters: string | undefined) {
        this.viewLink.href = "wls:" + wlsProGloveCommand + "(" + wlsProGloveCommandParameters + ")"
        this.viewLink.click()
    }

    private createViewLink(): HTMLAnchorElement {
        // If not initialized then try to find existing link left from previous instance
        let link = document.getElementById(this.viewLinkID) as HTMLAnchorElement

        // If found then initialize using existing link
        if (link) {
          return link
        }

        // If not initialized and there is no existing link then create new one and initialize
        link = document.createElement("a")
        link.setAttribute("hidden", "hidden")
        link.setAttribute("id", this.viewLinkID)
        document.body.appendChild(link)

        return link
    }
}
