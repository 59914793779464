import { Spacing, Theme } from "../theme/Theme"
import { Direction } from "../i18n/LocaleContext"

export type Margin = Spacing | number

// mimic React Native: https://facebook.github.io/react-native/docs/layout-props#margin
export interface MarginProps {
  margin?: Margin
  marginTop?: Margin
  marginRight?: Margin
  marginBottom?: Margin
  marginLeft?: Margin
  marginStart?: Margin
  marginEnd?: Margin
  marginHorizontal?: Margin
  marginVertical?: Margin
}

export function parseMargin(value: Margin, theme: Theme) {
  if (typeof value === "string") {
    const spacing = theme.spacing[value]
    return spacing ? `${spacing}rem` : 0
  } else {
    return value
  }
}

export function convertMarginPropsToStyle(
  props: MarginProps,
  theme: Theme,
  direction: Direction,
) {
  const style: React.CSSProperties = {}

  if (props.margin !== undefined) {
    style.margin = parseMargin(props.margin, theme)
  }

  if (props.marginHorizontal !== undefined) {
    style.marginLeft = parseMargin(props.marginHorizontal, theme)
    style.marginRight = parseMargin(props.marginHorizontal, theme)
  }

  if (props.marginVertical !== undefined) {
    style.marginTop = parseMargin(props.marginVertical, theme)
    style.marginBottom = parseMargin(props.marginVertical, theme)
  }

  if (props.marginStart !== undefined) {
    if (direction === "rtl") {
      style.marginRight = parseMargin(props.marginStart, theme)
    } else {
      style.marginLeft = parseMargin(props.marginStart, theme)
    }
  }

  if (props.marginEnd !== undefined) {
    if (direction === "rtl") {
      style.marginLeft = parseMargin(props.marginEnd, theme)
    } else {
      style.marginRight = parseMargin(props.marginEnd, theme)
    }
  }

  if (props.marginTop !== undefined) {
    style.marginTop = parseMargin(props.marginTop, theme)
  }

  if (props.marginBottom !== undefined) {
    style.marginBottom = parseMargin(props.marginBottom, theme)
  }

  if (props.marginLeft !== undefined) {
    style.marginLeft = parseMargin(props.marginLeft, theme)
  }

  if (props.marginRight !== undefined) {
    style.marginRight = parseMargin(props.marginRight, theme)
  }

  return style
}
