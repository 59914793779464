import React from "react"

import { PrintMon } from "./PrintMon"
import { PrintMonLike } from "./PrintMonLike"

const defaultPrintMon = new PrintMon()

/**
 * Holds currently used PrintMon instance (real or stub) that will be
 * used by PrintMonRequest to print labels.
 *
 * By default it is set to real PrintMon running on localhot.
 */
export const PrintMonContext = React.createContext<PrintMonLike>(defaultPrintMon)

PrintMonContext.displayName = "PrintMonContext"
