/**
 * Pallet variant.
 *
 * Can be used to differentiate between pallet types etc.
 */

export function getPalletVariant(imageVariant: string): PalletVariant {
  const palletVariant = palletVariants.find((variant) => variant === imageVariant)
  if (palletVariant) {
    return palletVariant
  }

  throw new Error(`Unable to find the provided pallet variant: ${imageVariant}`)
}

export const palletVariants = [
  "pallet_height",
  "damaged_pallet",
  "split_pallet",
  "pallet_downstacking",
  "pallet",
  "pallet_case",
  "pallet_case_item",
  "red_pallet",
  "black_pallet",
  "pallet_loaded",
  "red_pallet_loaded",
  "black_pallet_loaded"] as const
export type PalletVariant = typeof palletVariants[number]
