/**
 * Raw key codes, don't confuse with Web API key codes.
 */
 export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  Slash = 47,
  Digit0 = 48,
  Digit9 = 57,
  KeyA = 97,
  KeyZ = 122,
}
