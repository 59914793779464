import React from "react"

import { Subtract } from "utility-types"

import { HotKeyActivityContext } from "./HotKeyActivityContext"

export interface WithHotKeyActivity {
  hotKeyActive: boolean
}

/**
 * HoC function that injects hot key activity (active/inactive) as `hotKeyActive` property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withHotKeyActivity = <
  S extends WithHotKeyActivity = WithHotKeyActivity,
  D = Subtract<S, WithHotKeyActivity>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <HotKeyActivityContext.Consumer>
      {(hotKeyActive) => (
        <WrappedComponent
          {...(props as unknown as S)}
          hotKeyActive={hotKeyActive}
        />
      )}
    </HotKeyActivityContext.Consumer>
  )

  wrapper.displayName = `withHotKeyActivity(${displayName})`

  return wrapper
}
