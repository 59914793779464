import React from "react"

import { AlertVariant } from "./Alert"

export type AlertUpdateCallback = ((variant: AlertVariant | undefined) => void) | undefined

/**
 * Provides update callback to Alert that can be used to dynamically change
 * NavBar variant depending on Alert variant.
 */
export const AlertContext = React.createContext<AlertUpdateCallback>(() => undefined)

AlertContext.displayName = "AlertContext"
