import React from "react"

import { OverlayLayoutMenu } from "./OverlayLayoutMenu"
import { OverlayLayoutShadow } from "./OverlayLayoutShadow"
import { OverlayLayoutContent } from "./OverlayLayoutContent"
import { isReactPlaceholder } from "../../helpers/isReactPlaceholder"
import { HotKeyActivityContext } from "../../keyboard/HotKeyActivityContext"

interface Props {
  activeMenu?: string
  onMenuClose?: (menu: string) => void
}

/**
 * Overlay layout.
 *
 * Designed to wrap box and show slide in overlay menus and backdrop shadow.
 */
export class OverlayLayout extends React.PureComponent<Props>  {
  public static Content = OverlayLayoutContent

  public static Menu = OverlayLayoutMenu

  public constructor(props: Props) {
    super(props)
    this.handleMenuClose = this.handleMenuClose.bind(this)
  }

  public handleMenuClose() {
    const closedMenu = this.props.activeMenu

    if (closedMenu && this.props.onMenuClose) {
      this.props.onMenuClose(closedMenu)
    }
  }

  /**
   * Preprocess child components.
   *
   * - inject extra props into OverlayLayoutMenuProps
   * - no processing for OverlayLayoutContent
   * - ingore all other components
   */
  public getPreprocessedChildren() {
    return React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        if (React.isValidElement<React.ComponentProps<typeof OverlayLayoutMenu>>(child)
          && child.type === OverlayLayoutMenu
        ) {
          const opened = child.props.name === this.props.activeMenu
          const clonedChild = React.cloneElement(child, { _open: opened })

          return (
            <HotKeyActivityContext.Provider value={opened}>
              {clonedChild}
            </HotKeyActivityContext.Provider>
          )
        } else if (React.isValidElement<React.ComponentProps<typeof OverlayLayoutContent>>(child)
          && child.type === OverlayLayoutContent
        ) {
          return (
            <HotKeyActivityContext.Provider value={!this.props.activeMenu}>
              {child}
            </HotKeyActivityContext.Provider>
          )
        }
      } else if (!isReactPlaceholder(child)) {
        console.warn("Invalid child passed to OverlayLayout component:", child)
      }
      return null
    })
  }

  public render() {
    const showShadow = !!this.props.activeMenu

    return (
      <div className="overlay-layout">
        <OverlayLayoutShadow
          hidden={!showShadow}
          onClick={this.handleMenuClose}
        />
        {this.getPreprocessedChildren()}
      </div>
    )
  }
}
