import React from "react"

import { PrintDocFlowLike } from "./PrintDocFlowLike"

/**
 * Holds currently used PDF instance (real or stub) that will be
 * used by PrintDocFlowRequest to print labels.
 *
 * By default this passes not null as the default value as a pdf client must be
 * specified by a provider!
 */
export const PrintDocFlowContext = React.createContext<PrintDocFlowLike>(undefined!)

PrintDocFlowContext.displayName = "PrintDocFlowContext"
