import React from "react"

import { reactTypeFlagCheck } from "../../helpers/reactTypeFlagCheck"
import { safeReactChildrenOnly } from "../../../utilities/react/safeReactChildrenOnly"
import { isReactPlaceholder } from "../../helpers/isReactPlaceholder"

export interface CardLayoutItemProps {
  selected?: boolean
}

// @ts-ignore
export const CardLayoutItem: React.FunctionComponent<CardLayoutItemProps> = ({ children }) => {
  return safeReactChildrenOnly(children)
}

// @ts-ignore
CardLayoutItem.isCardLayoutItem = true

interface CardLayoutStatic {
  Item: typeof CardLayoutItem
}

export const CardLayout: React.FunctionComponent<{}> & CardLayoutStatic = (props) => {
  let selectedCard: React.ReactElement | null = null

  React.Children.forEach(props.children, (child) => {
    if (React.isValidElement<CardLayoutItemProps>(child)
      && reactTypeFlagCheck(child.type, "isCardLayoutItem")
    ) {
      const childProps = child.props as CardLayoutItemProps
      if (childProps.selected) {
        if (!selectedCard) {
          selectedCard = child
        } else {
          console.warn("More than one selected Card detected, first will be used")
        }
      }
      // prevent warning for valid Card items
      return
    } else if (!isReactPlaceholder(child)) {
      console.warn("Invalid child passed to Card component:", child)
    }
  })

  return selectedCard
}

CardLayout.Item = CardLayoutItem
