import React from "react"

import { Locale, LocaleContext } from "../i18n/LocaleContext"

export interface ListItemTextProps {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  inset?: boolean
}

export class ListItemText extends React.PureComponent<ListItemTextProps> {
  public renderWithContexts({ direction }: Locale) {
    const primary: React.ReactNode = this.props.primary || this.props.children || null
    const secondary: React.ReactNode = this.props.secondary || null

    const className = "list-item-text"
      + (this.props.inset ? ` list-item-text--inset-${direction}` : "")
      + (!secondary ? ` list-item-text--only-primary` : "")

    return (
      <div className={className}>
        <div className="list-item-text__primary">
          <span className="list-item-text__primary-inner">
            {primary}
          </span>
        </div>
        {secondary && (
          <div className="list-item-text__secondary">
            <span className="list-item-text__secondary-inner">
              {secondary}
            </span>
          </div>
        )}
      </div>
    )
  }

  public render() {
    return (
      <LocaleContext.Consumer>
        {(locale) => {
          return this.renderWithContexts(locale)
        }}
      </LocaleContext.Consumer>
    )
  }
}
