import React from "react"

import { Locale, LocaleContext } from "../i18n/LocaleContext"
import { HotKey } from "../keyboard/HotKey"
import { HotKeySet } from "../keyboard/HotKeyResponder"

/**
 * Properties to be passed to the `ListItemHotKey` component.
 */
export interface ListItemHotKeyProps {
  /**
   * Hot key or list of hot keys to be used as an alias for click action in
   * case if list item has button style.
   */
  keyCode: HotKeySet
}

/**
 * This component is to be used inside a `ListItem` component, and it displays a
 * `HotKey` right before the text in the `ListItem`.
 */
export class ListItemHotKey extends React.PureComponent<ListItemHotKeyProps> {

  public renderWithContexts({ direction }: Locale) {
    const className = "list-item-hotkey"
      + ` list-item-hotkey--${direction}`

    return (
      <div className={className}>
        <HotKey keyCode={this.props.keyCode}/>
      </div>
    )
  }

  public render() {
    return (
      <LocaleContext.Consumer>
        {(locale) => {
          return this.renderWithContexts(locale)
        }}
      </LocaleContext.Consumer>
    )
  }
}
