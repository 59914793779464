type AreEqualCallback<T = any[]> = (prevArgs: T, nextArgs: T) => boolean

const defaultAreEqual: AreEqualCallback = (prevArgs: any[], nextArgs: any[]) => {
  if (prevArgs.length === nextArgs.length) {
    for (let index = 0; index < prevArgs.length; index++) {
      if (!Object.is(prevArgs[index], nextArgs[index])) {
        return false
      }
    }

    return true
  }

  return false
}

/**
 * Primitive and fast memoize function that memoizes last result assuming that
 * memoized function is idempotent (produces the same result for the same input).
 */
export function memoizeOne<
  T extends (...args: any[]) => any,
  P extends Parameters<T>
>(
  fn: T,
  areEqual: AreEqualCallback<P> = defaultAreEqual,
) {
  let lastResult: any
  let lastArgs: P | undefined

  return (...args: P) => {
    if (lastArgs !== undefined && areEqual(lastArgs, args)) {
      return lastResult
    }

    lastArgs = args
    lastResult = fn.apply(null, args as P)

    return lastResult
  }
}
