import { platform } from "./platform"

import { SoundManager } from "../utilities/sound/SoundManager"
import { ProGloveDisplayManager } from "../utilities/proglove/ProGloveDisplayManager"

/**
 * Initialize default sound manager.
 *
 * Different implementation could be used depending on device capabilities.
 */
const progloveDisplayManager = new ProGloveDisplayManager()

/**
 * Initialize default sound manager.
 *
 * Different implementation could be used depending on device capabilities.
 */
const soundManager = new SoundManager()

/**
 * Audio feedback for clicks.
 *
 * Disabled by default but should be enabled for applications running on touch
 * screens in FCs. Should be disabled for management tools running on regular
 * desktops and laptops.
 *
 * There is no way reliably auto-detect this value so it is disabled by default
 *
 * For example, FC stations are running desktop/windows Firefox but could have
 * or not have touch screen depending on process path configuration.
 */
const clickAudioFeedbackEnabled: boolean = false

/**
 * Enable testID property support.
 *
 * This field can be used on test stage to locate element and asset element
 * value in integ tests.
 */
const testIDSupportEnabled: boolean = false

/**
 * Fulfillment HIG component library state.
 *
 * These values could be updated in realtime to reconfigure library defaults.
 */
export const HIGState = {
  platform,
  soundManager,
  clickAudioFeedbackEnabled,
  testIDSupportEnabled,
  progloveDisplayManager
}
