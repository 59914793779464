import React from "react"

import { Text, TextProps } from "./Text"
import { parseAttributedString, AttributedChunk, EnabledTextAttribute } from "./parseAttributedString"
import { HIGState } from "../../config/HIGState"

export interface AttributedTextProps extends TextProps {
  /**
   * Whether or not all or some of the
   * attributes are going to be stripped off the provided attributed text.
   */
  stripAttributes?: boolean

  /**
   * If the `stripAttributes` value is set, then this would be the attributes that are enabled,
   * or not stripped in other words.
   */
  enableAttributes?: EnabledTextAttribute[]

  /**
   * ID props
   *
   * Must be unique and contain at least one character.
   */
  id?: string
}

/**
 * Text component that supports text attributes: bold and italic.
 *
 * This component has exactly the same props as Text component.
 *
 * It is expected that text could contain primitive tags to format and emphasize
 * text but we need to make sure that solution will be easy portable to other
 * platforms, for example, iOS and Android.
 *
 * Only <b>, <i> and <br> attributes are supported for now because it is easy to
 * implement them on iOS / Android using NSAttributedString and SpannableString
 * without creating a webview for text.
 *
 * Bold/italic props passed to this components have precedence to string
 * attributes.
 */
export class AttributedText extends React.PureComponent<AttributedTextProps> {
  public parseChildren(
    children: React.ReactNode,
  ): AttributedChunk[] {
    switch (typeof children) {
      case "undefined":
      case "boolean":
        // keep well known react behavior that skips boolean and undefined values
        return []

      case "string":
        return parseAttributedString(
          children,
          this.props.stripAttributes,
          this.props.enableAttributes,
        )

      case "number":
        return [
          {
            text: String(children),
            bold: false,
            italic: false,
            break: false,
          },
        ]

      case "object":
        console.warn("Invalid child passed to AttributedText component:", children)

        if (Array.isArray(children)) {
          // iterate over children and use all suitable
          const textChunks: AttributedChunk[] = []
          children.forEach((child) => {
            const subTextChunks = this.parseChildren(child)
            textChunks.push(...subTextChunks)
          })
          return textChunks
        } else {
          // @ts-ignore
          if (children && children.props && children.props.children) {
            // @ts-ignore
            return this.parseChildren(children.props.children)
          }
          return []
        }

      default:
        console.warn("Invalid child passed to AttributedText component:", children)
        return []
    }
  }

  public renderNoChunks() {
    const newProps = { ...this.props }

    // protect from unwanted raw children to be rendered
    delete newProps.children

    return (
      <Text {...newProps} />
    )
  }

  public renderSingleChunk(textChunk: AttributedChunk) {
    const newProps = { ...this.props }

    // protect from unwanted raw children to be rendered
    delete newProps.children

    // merge parent and child bold/italic attributes
    if (textChunk.bold) {
      newProps.bold = true
    }
    if (textChunk.italic) {
      newProps.italic = true
    }

    return (
      <Text {...newProps}>
        {textChunk.text}
      </Text>
    )
  }

  public renderMultipleChunks(chunks: AttributedChunk[]) {
    const newProps = { ...this.props }

    // protect from unwanted raw children to be rendered
    delete newProps.children

    return (
      <Text {...newProps}>
        {chunks.map((chunk, chunkIndex) => {
          if (chunk.break) {
            // render break on native platform using "\n" and using "<br>" on web
            return HIGState.platform === "native" ? "\n" : <br key={chunkIndex} />
          }

          const textProps: TextProps = {}

          if (chunk.bold) {
            textProps.bold = true
          }
          if (chunk.italic) {
            textProps.italic = true
          }

          return (
            <Text key={chunkIndex} {...textProps}>
              {chunk.text}
            </Text>
          )
        })}
      </Text>
    )
  }

  public render() {
    const chunks = this.parseChildren(this.props.children)

    // rendering optimizations based on number of chunks
    if (chunks.length === 0) {
      return this.renderNoChunks()
    } else if (chunks.length === 1) {
      return this.renderSingleChunk(chunks[0])
    } else {
      return this.renderMultipleChunks(chunks)
    }
  }
}
