import React from "react"

export interface LiveClockProps {
  /**
   * How often to run live clock.
   *
   * By default is is once per 1000ms (each 1 second).
   */
  interval?: number

  /**
   * Called each time clock ticks with current time in milliseconds
   * as returned by `Date.now()`.
   *
   * Returning explicit `false` from this callback stops live clock.
   */
  onTick?: (currentTime: number) => boolean | undefined

  /**
   * Render property that is called with current time in milliseconds
   * as returned by `Date.now()`.
   */
  children: (currentTime: number) => React.ReactNode
}

export interface LiveClockState {
  currentTime: number // in ms as returned by Date.now()
}

/**
 * Live clock components that keeps current time state and ticks with
 * specified interval causing children to be rerendered with new time.
 *
 * Can be used as alternative to countdown timer when manual computation of
 * remaining time is needed. This component can also better tolerate umnount/mount
 * procedures since it just provides raw current time.
 */
export class LiveClock extends React.PureComponent<LiveClockProps, LiveClockState> {
  private intervalID?: number

  private defaultTickInterval = 1000 // ms

  public constructor(props: LiveClockProps) {
    super(props)

    const currentTime = Date.now()

    this.state = { currentTime }

    this.tick = this.tick.bind(this)
  }

  public componentDidMount() {
    this.startTimer()
  }

  public componentWillUnmount() {
    this.stopTimer()
  }

  public startTimer() {
    this.stopTimer()
    this.intervalID = window.setInterval(
      this.tick,
      this.props.interval || this.defaultTickInterval,
    )
  }

  public stopTimer() {
    if (this.intervalID) {
      clearInterval(this.intervalID)
      this.intervalID = undefined
    }
  }

  public tick() {
    const currentTime = Date.now()

    this.setState({ currentTime }, () => {
      if (this.props.onTick) {
        const result = this.props.onTick(currentTime)
        if (result === false) {
          this.stopTimer()
        }
      }
    })
  }

  public render() {
    return this.props.children(this.state.currentTime)
  }
}
