import React from "react"

import arrowImage from "../../../resources/images/arrow.i.svg"

import { LocaleContext, Direction } from "../../i18n/LocaleContext"
import { FlexLayout } from "../flex/FlexLayout"

const MoveLayoutSource: React.FunctionComponent = () => null
const MoveLayoutTarget: React.FunctionComponent = () => null

/**
 * Layout that directs to move item (displays overlay blue arrow).
 */
export class MoveLayout extends React.PureComponent {
  public static Source = MoveLayoutSource
  public static Target = MoveLayoutTarget

  public getArrowImageClassName(direction: Direction) {
    return "move-layout__icon"
      + (direction === "rtl" ? " move-layout__icon--flip" : "")
  }

  public getSourceAndTarget() {
    let sourceItem: React.ReactNode | null = null
    let targetItem: React.ReactNode | null = null

    React.Children.forEach(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === MoveLayout.Source) {
          sourceItem = child.props.children
        } else if (child.type === MoveLayout.Target) {
          targetItem = child.props.children
        }
      }
    })

    return { source: sourceItem, target: targetItem }
  }

  public render() {
    const { source, target } = this.getSourceAndTarget()

    return (
      <LocaleContext.Consumer>
        {({ direction }) => (
          <div className="move-layout">
            <img className={this.getArrowImageClassName(direction)} src={arrowImage} />
            <FlexLayout direction="row">
              <FlexLayout.Item size="1fr">
                {source}
              </FlexLayout.Item>
              <FlexLayout.Splitter />
              <FlexLayout.Item size="1fr">
                {target}
              </FlexLayout.Item>
            </FlexLayout>
          </div>
        )}
      </LocaleContext.Consumer>
    )
  }
}
