const hexColorRegExp = /^#([0-9a-f]{6}|[0-9a-f]{3})$/i

/**
 * Covert HEX color to RGB tuple [r, g, b] where each component has 0..255 value.
 *
 * @param color CSS hex color in short #RGB or long #RRGGBB notation.
 */
export function hex2rgb(color: string): [number, number, number] {
  // check if color has an acceptable variant
  if (!hexColorRegExp.test(color)) {
    throw new TypeError(`Invalid hex color: ${color}`)
  }

  // remove starting #
  let hexColor = color.substr(1)

  // convert RGB to RRGGBB
  if (hexColor.length === 3) {
    hexColor = hexColor.substr(0, 1).repeat(2)
             + hexColor.substr(1, 1).repeat(2)
             + hexColor.substr(2, 1).repeat(2)
  }

  // extract RGB parts as 0-255 integer values
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)

  return [r, g, b]
}
