import React from "react"

/**
 * Dumps passed children as preformatted JSON text.
 */
export const JsonText: React.FunctionComponent = ({ children }) => (
  <div className="json-text">
    {JSON.stringify(children, null, "  ")}
  </div>
)
