import React from "react"

import { FlexLayout } from "../layout/flex/FlexLayout"
import { Button } from "../button/Button"
import { LocalizedText } from "../text/LocalizedText"
import { HotKey } from "../keyboard/HotKey"
import { ChromeStrings } from "./Chrome"
import { ChromeMenuHeader } from "./ChromeMenuHeader"
import { ChromeMenuHeaderSize } from "./ChromeMenuHeaderSize"
import { NavBar } from "../nav/NavBar"
import { LocalizedString } from "../i18n/Translator"
import { HotKeyCode } from "../keyboard/HotKeyCode"

interface Props {
  /**
   * Localized strings used by default for component labels.
   */
  strings?: ChromeStrings

  /**
   * Menu header title.
   */
  headerTitle?: LocalizedString

  /**
   * Menu header size (depends on NavBar size).
   */
  headerSize: ChromeMenuHeaderSize

  /**
   * Disable sign out?
   *
   * It make sense to disable sign out for non interruptable tasks.
   */
  disableSignOut?: boolean

  /**
   * Callback when menu is clicked (cancels/closes menu).
   */
  onCancel?: () => void

  /**
   * Callback when sign out button is clicked.
   */
  onSignOut?: () => void
}

/**
 * Main menu content wrapper.
 *
 * Renders menu header, menu and sign out button.
 */
export class ChromeMainMenuContent extends React.PureComponent<Props> {
  private readonly cancelHotKeys: HotKeyCode[] = ["KeyM", "KeyX", "Escape"]

  public constructor(props: Props) {
    super(props)
  }

  private renderSignOutArea(strings: any) {
    if (!this.props.disableSignOut) {
      return (
        <Button
          variant="secondary"
          size="md"
          flex={true}
          onClick={this.props.onSignOut}
        >
          <LocalizedText
            testID="signOutLabel"
            stringID={strings.signOut}
            defaultString="Sign out"
          />
          {" "}
          <HotKey keyCode="KeyS" />
        </Button>
      )
    }

    return (
      <LocalizedText
        testID="signOutLabel"
        stringID={strings.signOutNotAvailable}
        defaultString="Sign out is not available.<br>Please complete the current task."
        attributed={true}
      />
    )
  }

  public render() {
    const strings = this.props.strings || {}

    const headerHeight = this.props.headerSize === "standard"
      ? `${NavBar.defaultHeightRem}rem`
      : `${NavBar.expandedHeightRem}rem`

    return (
      <FlexLayout direction="column">
        <FlexLayout.Item
          size={headerHeight}
        >
          <ChromeMenuHeader
            closeIconSide="natural"
            title={this.props.headerTitle}
            size={this.props.headerSize}
            hotKey={this.cancelHotKeys}
            onClick={this.props.onCancel}
            testID="closeMainMenuButton"
          />
        </FlexLayout.Item>

        <FlexLayout.Item>
          {this.props.children}
        </FlexLayout.Item>

        <FlexLayout.Splitter />

        <FlexLayout.Item
          size={64}
          backgroundColor="gray"
          padding="sm"
        >
          {this.renderSignOutArea(strings)}
        </FlexLayout.Item>
      </FlexLayout>
    )
  }
}
