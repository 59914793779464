import React from "react"
import { Icon as AlchemyIcon } from "@amzn/alchemy-components-react"

import { LocaleContext, Locale, isRtl, isHebrew } from "../i18n/LocaleContext"
import { IconName } from "./IconName"
import { IconNameMap } from "./IconNameMap"
import { IconColor } from "./IconColor"
import { IconSize, IconSizeMap } from "./IconSize"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"
import { LocalizedString, Translator } from "../i18n/Translator"
import { TranslatorContext } from "../i18n/TranslatorContext"

AlchemyIcon.displayName = "AlchemyIcon"

export interface IconProps extends DangerousProps, TestProps {
  name: IconName,
  size: IconSize,
  // No color means using the current text color
  color?: IconColor
  ariaLabel?: LocalizedString
}

export class Icon extends React.PureComponent<IconProps> {
  public renderWithAlchemy(translator: Translator, locale: Locale) {
    const { color, size, name, ariaLabel } = this.props
    const className = "icon"
                + ` icon--size-${size}`
                + (color ? ` icon--color-${color}` : "")
                + (this.props.dangerousClassName ? ` ${this.props.dangerousClassName}` : "")
    const content = (
      <AlchemyIcon
        className={className}
        icon={name && IconNameMap[name]}
        label={ariaLabel ? translator.lookup(ariaLabel) : undefined}
        rtl={isRtl(locale)}
        hebrew={isHebrew(locale)}
        size={IconSizeMap[size]}
        // Fix the height to the desired size.
        // Due to an issue that will be soon fixed by Alchemy,
        // the SVG would actually go below the inline-box a bit,
        // causing vertical misalignment
        style={{
          height: `${IconSizeMap[size]}rem !important`,
        }}
        data-testid={renderTestID(this.props.testID)}
        aria-hidden={!ariaLabel ? true : undefined}
      />
    )
    return content
  }

  public render() {
    return (
      <LocaleContext.Consumer>
        {(locale) => (
          <TranslatorContext.Consumer>
            {(translator) => {
              return this.renderWithAlchemy(translator, locale)
            }}
          </TranslatorContext.Consumer>
        )}
      </LocaleContext.Consumer>
    )
  }
}
