import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import IdPSelection from './idp-selection/IdPSelection';
import AuthenticationStatus from './device/authetication-status/AuthenticationStatus';
import DeviceVerification from "src/device/verification/DeviceVerification";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route path="/idp-selection" exact component={IdPSelection}/>
                        <Route path="/device/authentication-status" exact component={AuthenticationStatus}/>
                        <Route path="/device/verification" exact component={DeviceVerification}/>

                        <Route><h1>404</h1><h2>The requested URL was not found.</h2></Route>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;