// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import drop_zone_img from "../../resources/images/drop-zone.i.svg"
import drop_zone_pallet_img from "../../resources/images/drop-zone-pallet.i.svg"
import drop_zone_black_pallet_img from "../../resources/images/drop-zone-black-pallet.i.svg"
import drop_zone_red_pallet_img from "../../resources/images/drop-zone-red-pallet.i.svg"

import { DropZoneVariant } from "./DropZoneVariant"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface DropZoneImageProps {
  /**
   * The drop-zone type that defines the drop-zone image to be rendered.
   */
  dropZoneVariant: DropZoneVariant

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Drop-zone image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="drop-zone").
 */
export class DropZoneImage extends React.PureComponent<DropZoneImageProps> {
  public render() {
    const { dropZoneVariant, altText, translator } = this.props
    const imageConf = imageConfMap[dropZoneVariant]

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={imageConf.path}
        className="drop-zone-image"
        alt={imageAltText}
        width={imageConf.width}
        height={imageConf.height}
      />
    )
  }
}

interface ImageConf {
  path: string
  width: number
  height: number
}

const imageConfMap: Record<DropZoneVariant, ImageConf> = {
  drop_zone: {
    path: drop_zone_img,
    width: 150,
    height: 150,
  },
  drop_zone_pallet: {
    path: drop_zone_pallet_img,
    width: 150,
    height: 150,
  },
  drop_zone_black_pallet: {
    path: drop_zone_black_pallet_img,
    width: 150,
    height: 150,
  },
  drop_zone_red_pallet: {
    path: drop_zone_red_pallet_img,
    width: 150,
    height: 150,
  }
}
