import React from "react"

import { Subtract } from "utility-types"

import { DeepMuteContext } from "./DeepMuteContext"

export interface DeepMuteAware {
  deepMute: boolean
}

/**
 * HoC function that injects DeepMuteContext as `deepMute` property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withDeepMute = <
  S extends DeepMuteAware = DeepMuteAware,
  D = Subtract<S, DeepMuteAware>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <DeepMuteContext.Consumer>
      {(deepMute) => (
        <WrappedComponent
          {...(props as unknown as S)}
          deepMute={deepMute}
        />
      )}
    </DeepMuteContext.Consumer>
  )

  wrapper.displayName = `withDeepMute(${displayName})`

  return wrapper
}
