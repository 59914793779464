import React from "react"

import { ChromeConfirmationModal } from "./ChromeConfirmationModal"
import { CountdownTimer } from "../datetime/CountdownTimer"
import { HotKeyCode } from "../keyboard/HotKeyCode"

type ChromeSignOutModalStringKey = "caption" | "body" | "yes" | "no"

export type ChromeSignOutModalStrings = Partial<Record<ChromeSignOutModalStringKey, string>>

interface ChromeSignOutModalProps {
  /**
   * Date timestamp when modal was initiated.
   *
   * Needed to make sure that timeout timer will be synced with the clock.
   */
  initialTime?: number

  /**
   * Automatic sign out is performed after this timeout (in seconds).
   */
  timeout: number // in seconds

  /**
   * Callback executed when user manually confirms sign out.
   */
  onSignOut?: () => void

  /**
   * Callback executed when user manually cancels sign out.
   */
  onCancel?: () => void

  /**
   * Called automatically when user did not respond in time (means sign out).
   */
  onTimeout?: () => void
}

/**
 * Sign out confirmation modal that is displayed when user initiates sign out.
 */
export class ChromeSignOutModal extends React.PureComponent<ChromeSignOutModalProps> {
  // override this value to customize strings and provide translations
  public static defaultStrings: ChromeSignOutModalStrings = {}

  public static readonly defaultTexts: Record<ChromeSignOutModalStringKey, string> = {
    caption: "Sign out?",
    body: "Your session will time out {remainingTime, plural, =0 {now} =1 {in <b>#</b> second} other {in <b>#</b> seconds}}.",
    yes: "Yes, Sign Out",
    no: "No",
  }

  public static readonly hotKeys: Record<"yes" | "no", HotKeyCode> = {
    yes: "Enter",
    no: "KeyX",
  }

  public render() {
    const { defaultStrings, defaultTexts, hotKeys } = ChromeSignOutModal
    const { timeout, initialTime } = this.props

    return (
      <CountdownTimer
        initialTime={initialTime}
        initialValue={timeout}
        onFinish={this.props.onTimeout}
      >
        {(remainingTime) => (
          <ChromeConfirmationModal
            id="signOut"

            caption={{
              stringID: defaultStrings.caption,
              defaultString: defaultTexts.caption,
            }}

            body={{
              stringID: defaultStrings.body,
              defaultString: defaultTexts.body,
              parameters: { remainingTime },
            }}

            primaryButtonHotKey={hotKeys.yes}
            primaryButtonLabel={{
              stringID: defaultStrings.yes,
              defaultString: defaultTexts.yes,
            }}
            onPrimaryButtonClick={this.props.onSignOut}

            secondaryButtonHotKey={hotKeys.no}
            secondaryButtonLabel={{
              stringID: defaultStrings.no,
              defaultString: defaultTexts.no,
            }}
            onSecondaryButtonClick={this.props.onCancel}
          />
        )}
      </CountdownTimer>
    )
  }
}
