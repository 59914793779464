import { addEventListener, removeEventListener } from "./browser/addEventListener"

import { getEventClipboardText } from "./browser/getEventClipboardText"
import { getFormData } from "./browser/getFormData"
import { getBoundingClientRect } from "./browser/getBoundingClientRect"
import { getEventKeyCode } from "./browser/getEventKeyCode"
import { preventEventDefault } from "./browser/preventEventDefault"
import { stopEventPropagation } from "./browser/stopEventPropagation"
import { getEventTarget } from "./browser/getEventTarget"

/**
 * Cross-platform wrappers for APIs that can't be properly polyfilled.
 */
export const BrowserUtils = {
  addEventListener,
  removeEventListener,
  getEventKeyCode,
  getEventClipboardText,
  getBoundingClientRect,
  getFormData,
  preventEventDefault,
  stopEventPropagation,
  getEventTarget,
}
