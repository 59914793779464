/**
 * Dock variant.
 *
 * Can be used to differentiate between dock types etc.
 */

export function getDockVariant(imageVariant: string): DockVariant {
  const dockVariant = dockVariants.find((variant) => variant === imageVariant)
  if (dockVariant) {
    return dockVariant
  }

  throw new Error(`Unable to find the provided door variant: ${imageVariant}`)
}

export const dockVariants = [
  "dock_door_closed"] as const
export type DockVariant = typeof dockVariants[number]
