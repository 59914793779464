import React from "react"

import { Transition } from "react-transition-group"

import { Locale } from "../../i18n/LocaleContext"
import { OverlayLayoutMenuSide } from "./OverlayLayoutMenuSide"
import { memoizeOne } from "../../../utilities/memoizeOne"
import { OverlayLayoutConfig } from "./OverlayLayoutConfig"
import { withLocale } from "../../i18n/withLocale"
import { BufferLayout } from "../buffer/BufferLayout"
import { addEventListener, removeEventListener } from "../../../utilities/browser/addEventListener"
import { preventEventDefault } from "../../../utilities/browser/preventEventDefault"
import { getEventKeyCode } from "../../../utilities/browser/getEventKeyCode"
import { KeyCode } from "../../keyboard/KeyCode"
import { getFirstFocusableElement, getLastFocusableElement } from "../../../utilities/browser/getElementFocus"

export interface OverlayLayoutMenuProps {
  /**
   * Side where menu should show up.
   *
   * Values `natural` and `reverse` depend on text direction.
   */
  side: OverlayLayoutMenuSide

  /**
   * Size of the menu in pixels or percents.
   *
   * If not set, then it is 100%.
   */
  size?: number

  /**
   * Name of the menu.
   *
   * Required to track `onClose` event.
   */
  name?: string

  /**
   * Render menu with transparent background?
   *
   * Designed mostly for custom modals.
   */
  transparent?: boolean

  /**
   * Buffer content until transitioned is exited.
   */
  buffered?: boolean

  /**
   * Injected by parent OverlayLayout.
   */
  _open?: boolean

  /**
   * Injected by withLocale() HoC wrapper.
   */
  locale: Locale

  /**
   * Role of the component.
   */
  role?: string

  /**
   * Used for identifying modals.
   */
  ariaModal?: boolean
}

/**
 * Slide in menu for overlay layout.
 *
 * Menu of overlay layout handles all of the tab event handlers to prevent escaping the screen.
 */
class OverlayLayoutMenuBase extends React.PureComponent<OverlayLayoutMenuProps> {
  private readonly rootRef = React.createRef<HTMLDivElement>()

  private readonly defaultSize = "100%"

  private focusableElements: string
  private firstFocusableElement?: HTMLElement
  private lastFocusableElement?: HTMLElement

  public constructor(props: OverlayLayoutMenuProps) {
    super(props)

    this.handleEnteredTransition = this.handleEnteredTransition.bind(this)
    this.handleExitedTransition = this.handleExitedTransition.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)

    // cache to speedup rendering
    this.getContainerStyle = memoizeOne(this.getContainerStyle.bind(this))

    this.focusableElements = this.props.ariaModal ? ".chrome-modal .button" : ".chrome-menu-header, .list, .button"
  }

  public getContainerStyle(
    side: OverlayLayoutMenuSide,
    size: number | undefined,
  ) {
    const isVertical = side === "top" || side === "bottom"
    const sizeDimension = isVertical ? "height" : "width"
    return { [sizeDimension]: size || this.defaultSize }
  }

  public focus() {
    if (this.rootRef.current) {
      if (this.props.ariaModal) {
        this.rootRef.current.focus()
      } else {
        // Focus the first focusable element on entered transition
        this.firstFocusableElement =
          getFirstFocusableElement(this.focusableElements, this.rootRef.current)
        this.firstFocusableElement.focus()
      }
    }
  }

  public handleKeyDown(srcEvent: Event) {
    const event = srcEvent as KeyboardEvent

    if (!this.rootRef.current) {
      return
    }

    // Update focusable elements in case of slide transition
    this.firstFocusableElement =
      getFirstFocusableElement(this.focusableElements, this.rootRef.current)
    this.lastFocusableElement =
      getLastFocusableElement(this.focusableElements, this.rootRef.current)

    if (getEventKeyCode(event) === KeyCode.Tab) {
      if (event.shiftKey) { // Handle Shift + Tab
        if (this.firstFocusableElement === document.activeElement) {
          preventEventDefault(event)

          if (this.lastFocusableElement) {
            this.lastFocusableElement.focus()
          }
        }
      } else {
        if (this.lastFocusableElement &&
            this.lastFocusableElement.contains(document.activeElement) ||
            this.lastFocusableElement === document.activeElement
          ) {
          preventEventDefault(event)

          if (this.firstFocusableElement) {
            this.firstFocusableElement.focus()
          }
        }
      }
    }
  }

  public handleEnteredTransition() {
    // This will make first element from opened menu to be focusable if Tab is pressed.
    // Need to focus after transition otherwise could cause animation issues for right side menu.
    // Adds keydown event listener for tab events
    this.focus()
    addEventListener(document, "keydown", this.handleKeyDown)
  }

  public handleExitedTransition() {
    // Removed keydown event listener for tab events after exiting
    removeEventListener(document, "keydown", this.handleKeyDown)
  }

  public componentWillUnmount() {
    removeEventListener(document, "keydown", this.handleKeyDown)
  }

  public render() {
    const { size, side, transparent, buffered, role, ariaModal} = this.props

    const style = this.getContainerStyle(side, size)

    const direction = this.props.locale.direction

    // Transition is used instead of CSSTransition to have an easy access to
    // transition state that is used to flush buffers and remove content from
    // the menu once transition is completed.
    return (
      <Transition
        appear={true}
        in={this.props._open}
        timeout={OverlayLayoutConfig.transitionDuration}
        onEntered={this.handleEnteredTransition}
        onExited={this.handleExitedTransition}
      >
        {(state) => {
          const className = `overlay-layout-menu`
                          + ` overlay-layout-menu--${state}`
                          + ` overlay-layout-menu--${direction}-${side}`
                          + ` overlay-layout-menu--${direction}-${side}--${state}`
                          + (transparent ? ` overlay-layout-menu--transparent` : "")

          const content = buffered
            ? <BufferLayout flush={state === "exited"}>{this.props.children}</BufferLayout>
            : (state !== "exited" && this.props.children)

          return (
            <div
              className={className}
              style={style}
              tabIndex={-1}
              ref={this.rootRef}
              role={state === "entered" ? role : undefined}
              aria-modal={state === "entered" ? ariaModal : undefined}
            >
              {content}
            </div>
          )
        }}
      </Transition>
    )
  }
}

export const OverlayLayoutMenu = Object.assign(
  withLocale(OverlayLayoutMenuBase),
  {
    displayName: "OverlayLayoutMenu",
  },
)
