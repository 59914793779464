import debounce from "lodash.debounce"

import { SoundProvider } from "./SoundProvider"
import { AudioTagSoundProvider } from "./AudioTagSoundProvider"
import { VelocitySoundProvider } from "./VelocitySoundProvider"
import { NullSoundProvider } from "./NullSoundProvider"
import { SoundType } from "./SoundType"
import { SoundMap } from "./SoundMap"

export type SoundManagerBackend =
  | "audio"
  | "velocity-standard"
  | "velocity-legacy"
  | "velocity-hig"
  | "velocity"
  | "null"
  | "auto"

const defaultSoundMap: SoundMap = {
  success: "success",
  error: "error",
  attention: "attention",
  confirmation: "confirmation"
}

/**
 * This is facade for sound provider.
 *
 * Can automatically detect sound provider or use explicitly provided.
 */
export class SoundManager {
  private soundProvider: SoundProvider

  private backend: SoundManagerBackend

  private navigator: Navigator

  private soundMap: SoundMap

  public constructor(
    backend: SoundManagerBackend = "auto",
    navigator = window.navigator,
    soundMapPreferences: SoundMap = defaultSoundMap
  ) {
    this.navigator = navigator

    this.backend = backend

    this.soundProvider = this.createProvider(backend)

    this.soundMap = soundMapPreferences

    this.preload()

    // debounce multiple play requests that could be triggered by modals/views,
    // alerts and buttons to prevent sound stuttering, last sound wins here
    this.play = debounce(this.play.bind(this), 10)
  }

  public getBackend() {
    return this.backend
  }

  public setBackend(backend: SoundManagerBackend) {
    if (backend !== this.backend) {
      // unload previous sound provider
      if (this.soundProvider) {
        this.soundProvider.unload()
      }

      this.soundProvider = this.createProvider(backend)
      this.backend = backend
      this.preload()
    }
  }

  public preload() {
    this.soundProvider.preload()
  }

  public stop() {
    this.soundProvider.stop()
  }

  public play(sound: SoundType) {
    console.log(`Playing sound: ${sound}`)
    this.soundProvider.play(this.soundMap[sound])
  }

  private createProvider(backend: SoundManagerBackend): SoundProvider {
    switch (backend) {
      case "null":
        return new NullSoundProvider()

      case "audio":
        return new AudioTagSoundProvider()

      case "velocity": {
        const isMC32 = /\bIEMobile\b/.test(this.navigator.userAgent)

        if (isMC32) {
          // MC32 should always use legacy sound preset since hig preset is not available
          return new VelocitySoundProvider("legacy")
        }

        return new VelocitySoundProvider("hig")
      }

      case "velocity-standard":
        return new VelocitySoundProvider("standard")

      case "velocity-legacy":
        return new VelocitySoundProvider("legacy")

      case "velocity-hig":
        return new VelocitySoundProvider("hig")

      case "auto": {
        // assume MC33/PM550 are using Velocity Browser
        // assume any mobile IE is Wavelink Browser
        // no easy way to differentiate regular browser and Velocity/Wavelink browsers
        const isVelocity = /\b(IEMobile|MC33|PM550)\b/.test(this.navigator.userAgent)

        if (isVelocity) {
          return this.createProvider("velocity")
        } else {
          return this.createProvider("audio")
        }
      }

      default:
        // prevent crash but show error on console
        console.error(`Invalid SoundManager backend: ${backend}`)
        return new NullSoundProvider()
    }
  }
}
