import React from "react"

export type Direction = "ltr" | "rtl"

export interface Locale {
  locale: string
  direction: Direction
}

export function getLocaleDirection(locale: string): Direction {
  return /^(ar|he|iw|fa|ur|yi)_/.test(locale) ? "rtl" : "ltr"
}

export function createLocale(locale: string): Locale {
  return { locale, direction: getLocaleDirection(locale) }
}

export function isRtl(locale: Locale): boolean {
  return locale.direction === "rtl"
}

export function isHebrew(locale: Locale): boolean {
  return /^(he|iw)_/.test(locale.locale)
}

export const defaultLocale: Locale = createLocale("en_US")

export const LocaleContext = React.createContext<Locale>(defaultLocale)

LocaleContext.displayName = "LocaleContext"
