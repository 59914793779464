// tslint:disable:no-reference
/// <reference path="../../../types/ogg-sound.d.ts" />
/// <reference path="../../../types/mp3-sound.d.ts" />

import { SoundName } from "./SoundName"
import { SoundProvider } from "./SoundProvider"

import successMp3 from "../../resources/sounds/success.mp3"
import successOgg from "../../resources/sounds/success.ogg"

import errorMp3 from "../../resources/sounds/error.mp3"
import errorOgg from "../../resources/sounds/error.ogg"

import attentionMp3 from "../../resources/sounds/attention.mp3"
import attentionOgg from "../../resources/sounds/attention.ogg"

import confirmationMp3 from "../../resources/sounds/confirmation.mp3"
import confirmationOgg from "../../resources/sounds/confirmation.ogg"

/**
 * Sound provider that uses <audio> tag to play sounds.
 *
 * Could not work without additional configuration on browser level to enable
 * auto play: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
 */
export class AudioTagSoundProvider implements SoundProvider {
  private lastPlayedElement?: HTMLAudioElement

  private readonly sources: Record<SoundName, { mp3: string, ogg: string }> = {
    success: {
      mp3: successMp3,
      ogg: successOgg,
    },
    error: {
      mp3: errorMp3,
      ogg: errorOgg,
    },
    attention: {
      mp3: attentionMp3,
      ogg: attentionOgg,
    },
    confirmation: {
      mp3: confirmationMp3,
      ogg: confirmationOgg,
    },
  }

  public preload() {
    for (const sound in this.sources) {
      this.loadSound(sound as SoundName)
    }
  }

  public unload() {
    for (const sound in this.sources) {
      this.unloadSound(sound as SoundName)
    }
  }

  public play(sound: SoundName) {
    this.stop()

    const element = this.loadSound(sound)

    if (element.buffered && element.buffered.length === 0) {
      console.warn(`Unable to play unbuffered "${sound}" sound`)
      return
    }

    this.lastPlayedElement = element

    const promise = element.play()

    // on Chrome 46 `.play()` doesn't return promise
    if (promise && promise.catch) {
      // provide a catch() to prevent unhandled promise rejections
      promise.catch((e) => {
        console.warn(`Unable to play "${sound}" sound:`, e)
      })
    }
  }

  public stop() {
    if (this.lastPlayedElement) {
      try {
        // stop the sound
        this.lastPlayedElement.pause()
        // rewind the position
        this.lastPlayedElement.currentTime = 0
        // clear last played sound
        this.lastPlayedElement = undefined
      } catch (e) {
        console.warn(`Unable to pause/rewind previous sound`, e)
      }
    }
  }

  private loadSound(sound: SoundName): HTMLAudioElement {
    const element = this.getSoundElement(sound)

    return element ? element : this.createSoundElement(sound)
  }

  private unloadSound(sound: SoundName) {
    const element = this.getSoundElement(sound)

    if (element) {
      element.remove()
    }
  }

  private createSoundElement(sound: SoundName): HTMLAudioElement {
    const { mp3, ogg } = this.sources[sound]

    const element = document.createElement("audio")

    element.setAttribute("id", this.getSoundElementID(sound))
    element.setAttribute("preload", "auto")

    if (mp3) {
      const source = document.createElement("source")
      source.setAttribute("type", "audio/mp3")
      source.setAttribute("src", `${mp3}`)
      element.appendChild(source)
    }

    if (ogg) {
      const source = document.createElement("source")
      source.setAttribute("type", "audio/ogg")
      source.setAttribute("src", `${ogg}`)
      element.appendChild(source)
    }

    document.body.appendChild(element)

    return element
  }

  private getSoundElement(sound: SoundName): HTMLAudioElement | undefined {
    const id = this.getSoundElementID(sound)
    return document.getElementById(id) as (HTMLAudioElement | null) || undefined
  }

  private getSoundElementID(sound: SoundName) {
    return `hig-sound-${sound}-audio`
  }
}
