import React from "react"

export type ListItemSize = "standard" | "expanded"

export interface ListOptions {
  itemSize: ListItemSize
  stickyHeaders: boolean
}

/**
 * ListOptionsContext helps to render children as it is from List without
 * using React.cloneElement() that is triggering rerender for elements.
 */
export const ListOptionsContext = React.createContext<ListOptions>({
  itemSize: "standard",
  stickyHeaders: true,
})

ListOptionsContext.displayName = "ListOptionsContext"
