import { PrintMonOptions, PrintMonLike } from "./PrintMonLike"
import { BrowserUtils } from "../../utilities/BrowserUtils"

/**
 * Print using Amazon PrintMon web API.
 *
 * By default this API is enabled and server is listening only on localhost
 * interface using HTTP protocol and 5965 port.
 *
 * This web API could be disabled by local IT in case if other printing tool
 * is need to be used on the station.
 *
 * Amazon PrintMon web API is available only on Windows stations where Amazon
 * PrintMon is installed.
 *
 * Amazon PrintMon API has header "Access-Control-Allow-Origin: *" so can be
 * used from any domain.
 *
 * There are two reasons why PrintMon can fail:
 *
 * - PrintMon is not installed or server URL is not accessible (firewall etc)
 * - PrintMon tried to print but failed for any other reason (returns "invalid" status)
 *
 * @see https://w.amazon.com/index.php/Printmon
 * @see https://w.amazon.com/bin/view/TaskUI/Development/AmazonPrintMonAPI/
 */
export class PrintMon implements PrintMonLike {
  private defaultServer = "http://localhost:5965"

  private defaultTimeout = 5000 // ms

  public constructor(defaultServer?: string, defaultTimeout?: number) {
    if (defaultServer) {
      this.defaultServer = defaultServer
    }
    if (defaultTimeout) {
      this.defaultTimeout = defaultTimeout
    }
  }

  public async print({
    raw,
    barcode,
    text,
    badgeID,
    description,
    quantity,
    sequence,
    server,
    timeout,
  }: PrintMonOptions): Promise<void> {
    const apiServer = `${server || this.defaultServer}`

    let apiURL: string

    if (raw) {
      apiURL = `${apiServer}/bmp_request?page=printraw`
        + "&data=" + encodeURIComponent(raw)

      if (barcode || text || description || badgeID || sequence || quantity) {
        throw new Error(`PrintMon API doesn't support special barcode attributes in raw mode`)
      }
    } else if (barcode) {
      apiURL = `${apiServer}/printer?action=print&type=barcode`
        + "&data=" + this.toHexString(barcode)
        + (text ? "&text=" + this.toHexString(text) : "")
        + (badgeID ? "&badgeid=" + badgeID : "")
        + (description ? "&desc=" + this.toHexString(description) : "")
        + (sequence ? "&seq=" + sequence : "")
        + (quantity ? "&quantity=" + quantity : "")
    } else {
      throw new Error(`PrintMon API requires one of "raw" or "barcode" to be passed`)
    }

    // IE7 doesn't support CORS so remote printing requires iframe manipulation
    if (/\bMSIE\b/.test(navigator.userAgent)) {
      return this.ajaxGetUsingIframe(apiURL, timeout)
    }

    return this.ajaxGetUsingFetch(apiURL, timeout)
  }

  private async ajaxGetUsingIframe(url: string, timeout?: number) {
    return new Promise((resolve, reject) => {
      const iframe = document.createElement("iframe")

      const abortTimeout = window.setTimeout(() => {
        cleanup()
        reject()
      }, timeout || this.defaultTimeout)

      const onLoad = () => {
        clearTimeout(abortTimeout)
        cleanup()
        resolve()
      }

      const onError = () => {
        clearTimeout(abortTimeout)
        cleanup()
        reject()
      }

      const cleanup = () => {
        BrowserUtils.removeEventListener(iframe, "load", onLoad)
        BrowserUtils.removeEventListener(iframe, "error", onError)
        document.body.removeChild(iframe)
      }

      BrowserUtils.addEventListener(iframe, "load", onLoad)
      BrowserUtils.addEventListener(iframe, "error", onError)

      iframe.style.display = "none"
      iframe.src = url

      document.body.appendChild(iframe)
    }) as Promise<void>
  }

  private async ajaxGetUsingFetch(url: string, timeout?: number) {
    // if passed server is invalid the connection will hang so we need to abort
    // print request if it takes too long, real PrintMon prints almost immediately
    const abortController = new AbortController()

    // this timeout will either abort fetch() or won't be able to do that
    // if fetch promise will be already resolved or rejected
    setTimeout(() => abortController.abort(), timeout || this.defaultTimeout)

    return fetch(url, { signal: abortController.signal })
      .then((response) => response.text())
      .then((responseText) => {
        if (responseText.trim() !== "valid") {
          throw new Error(`PrintMon returned wrong status: ${responseText}`)
        }
      })
  }

  private toHexString(value: string) {
    let text = ""

    for (let i = 0, len = value.length; i < len; i++) {
      const hex = Number(value.charCodeAt(i)).toString(16)
      text += hex
    }

    return text
  }
}
