import React from "react"

export type BoxVariant = "default" | "white" | "gray"

export type BoxPadding = "default" | "none" | "xs" | "sm" | "md" | "lg" | "xl"

export type BoxSide = "top" | "left" | "bottom" | "right"

export type BoxBorder = "default" | "none" | "all" | BoxSide | BoxSide[]

import { renderTestID } from "../helpers/renderTestID"
import { TestProps } from "../helpers/TestProps"

export interface BoxProps extends TestProps {
  variant?: BoxVariant
  padding?: BoxPadding
  border?: BoxBorder
  rounded?: boolean
}

// AF UX needs Box to have slightly larger rounding than other components
const borderRadius = 4

export class Box extends React.PureComponent<BoxProps> {
  public static defaultProps = {
    variant: "default",
    padding: "default",
    border: "default",
    rounded: true,
  }

  public getBorderClassName(border: BoxBorder) {
    return typeof border === "string"
      ? `box--border-${border}`
      : (border.length
        ? border.map((side) => `box--border-${side}`).join(" ")
        : `box--border-none`
      )
  }

  public hasSideBorders() {
    const border = this.props.border!
    return typeof border !== "string" && border.length > 0 && border.length < 4
  }

  public render() {
    const className = "box"
      + ` box--${this.props.variant}`
      + ` box--padding-${this.props.padding}`
      + ` ${this.getBorderClassName(this.props.border!)}`

    // incomplete border frame with rounding looks bad, implicitly turn off rounding in that case
    const enableRounding = this.props.rounded && !this.hasSideBorders()

    // we set rounding on style instead of css class for IE7 compatibility
    const style = enableRounding ? { borderRadius } : undefined

    return (
      <div
        className={className}
        style={style}
        data-testid={renderTestID(this.props.testID)}
      >
        <div className="box__inner">
          {this.props.children}
        </div>
      </div>
    )
  }
}
