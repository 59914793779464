import React from "react"
import { Spinner as BaseSpinner } from "@amzn/alchemy-components-react"
import { LocalizedString, Translator } from "../i18n/Translator"
import { withTranslator } from "../i18n/withTranslator"
import { withLocale } from "../i18n/withLocale"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"
import { Locale } from "../i18n/LocaleContext"

BaseSpinner.displayName = "AlchemySpinner"

export type SpinnerColor = "white" | "bright-blue"

export type SpinnerSize = "md"

export type SpinnerLabelSide = "aside" | "bottom"

export interface SpinnerProps extends DangerousProps, TestProps {
  /**
   * Spinner color. Default is "bright-blue".
   */
  color?: SpinnerColor

  /**
   * Spinner side. Default is "md".
   */
  size?: SpinnerSize

  /**
   * Spinner label. Not set by default.
   */
  label?: LocalizedString

  /**
   * Spinner label position. Default is "bottom".
   */
  labelSide?: SpinnerLabelSide

  /**
   * Delay label show? Enabled by default.
   */
  delayLabel?: boolean

  /**
   * Make spinner to fit the container and render in the center of container.
   */
  flex?: boolean
  /**
   * Translator is used to translate provided labels.
   *
   * Injected automatically.
   */
  translator: Translator

  /**
   * Locale is used to detect text direction.
   *
   * Injected automatically.
   */
  locale: Locale
}

interface SpinnerState {
  labelDelayDone: boolean
}

/**
 * Spinner component that indicates running process using SVG and CSS animations.
 *
 * By default it shows bright blue animated spinner that will show label
 * (if provided) after delay.
 */
export class SpinnerBase extends React.PureComponent<SpinnerProps, SpinnerState> {
  public readonly defaultLabelDelay = 3000

  private labelDelayTimeout?: number

  public constructor(props: SpinnerProps) {
    super(props)
    this.state = { labelDelayDone: false }
    this.handleShowLabelDelay = this.handleShowLabelDelay.bind(this)
  }

  public componentDidMount() {
    this.labelDelayTimeout = window.setTimeout(this.handleShowLabelDelay, this.defaultLabelDelay)
  }

  public componentWillUnmount() {
    if (this.labelDelayTimeout) {
      clearTimeout(this.labelDelayTimeout)
    }
  }

  public handleShowLabelDelay() {
    this.setState({ labelDelayDone: true })
  }

  private renderWithAlchemy(translator: Translator) {
    const {
      size = "md",
      color = "bright-blue",
      labelSide = "bottom",
      delayLabel = true,
      label,
      flex,
      dangerousStyle,
    } = this.props

    const {
      labelDelayDone,
    } = this.state

    const className = "spinner"
    + ` spinner--color-${color}`
    + (flex ? ` spinner--flex` : "")

    const hideLabel = !label || (delayLabel && !labelDelayDone)
    const content = (
      <BaseSpinner
        className={className}
        style={dangerousStyle}
        data-testid={renderTestID(this.props.testID)}
        size={SizeMap[size]}
        label={hideLabel ? undefined : translator.lookup(label)}
        labelPosition={labelSide === "bottom" ? "bottom" : "end"}
      />
    )
    return content
  }

  public render() {
    return this.renderWithAlchemy(this.props.translator)
  }
}

export const Spinner = Object.assign(
  withTranslator(
    withLocale(
      SpinnerBase
    ),
  ),
  {
    displayName: "Spinner",
  },
)

// We are not using Alchemy's predefined sizes, but using custom sizes
const SizeMap: Record<SpinnerSize, string> = {
  md: "4",
}
