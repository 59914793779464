import React from "react"

import { HIGState } from "../../config/HIGState"

export interface NavTextProps {
  side?: "natural" | "reverse"
}

export class NavText extends React.PureComponent<NavTextProps> {
  public render() {
    const side = this.props.side || "natural"

    return (
      <div className={`navbar-text navbar-text--${side}`}>
        <div className="navbar-text__inner">
          {this.props.children}
        </div>
      </div>
    )
  }
}
