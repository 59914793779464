import React from "react"

import { DangerousProps } from "../../helpers/DangerousProps"
import { renderTestID } from "../../helpers/renderTestID"
import { TestProps } from "../../helpers/TestProps"

export interface TextLayoutProps extends DangerousProps, TestProps {
  /**
   * Vertical alignment along the horizontal axis.
   */
  vAlign?: "top" | "bottom" | "middle"

  /**
   * Horizontal alignment of the children.
   */
  hAlign?: "natural" | "reverse" | "center"

  /**
   * Whether or not the component truncates the children.
   */
  truncate?: boolean

  /**
   * Whether or not the component will fit its container edge to edge.
   */
  flex?: boolean
}

export const TextLayout: React.FunctionComponent<TextLayoutProps> =
  ({
    hAlign,
    vAlign,
    truncate,
    flex = true,
    dangerousClassName,
    dangerousStyle,
    children,
    testID,
  }) => {
    const className = "text-layout"
      + (flex ? ` text-layout--flex` : "")
      + (hAlign ? ` text-layout--h-align-${hAlign}` : "")
      + (vAlign ? ` text-layout--v-align-${vAlign}` : "")
      + (truncate ? ` text-layout--truncate` : "")
      + (dangerousClassName ? ` ${dangerousClassName}` : "")

    const updatedChildren = React.Children.map(children, (item) => {
      if (item === " ") {
        return <div className="text-layout__spacing">&nbsp;</div>
      } else {
        return item
      }
    })

    const child = truncate
      ? <div className="text-layout__inner">{updatedChildren}</div>
      : updatedChildren

    return (
      <div
        className={className}
        style={dangerousStyle}
        data-testid={renderTestID(testID)}
      >
        {child}
      </div>
    )
  }
