import React from "react"

export interface LayoutSize {
  width: number,
  height: number
}

// @deprecated For IE7 previously. Will be removed
export const LayoutSizeContext = React.createContext<LayoutSize>({
  width: 0,
  height: 0,
})

LayoutSizeContext.displayName = "LayoutSizeContext"
