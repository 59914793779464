import { HotKeyCode } from "./HotKeyCode"
import { Observer } from "../../utilities/Observer"

/**
 * Routes hot keys to hot key responders.
 *
 * Tries to process hot key in each responder until responder report that
 * hot key is processed. This helps to preven double hot key processing
 * in case of hot key conflicts.
 */
export class HotKeyDispatcher extends Observer<HotKeyCode> {}
