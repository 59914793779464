export interface AttributedChunk {
  text: string
  italic: boolean
  bold: boolean
  break: boolean
}

export type EnabledTextAttribute = "bold" | "italic" | "line-break"

export function parseAttributedString(
  text: string,
  stripAttributes?: boolean,
  enableAttributes?: EnabledTextAttribute[],
): AttributedChunk[] {
  // convert all \n line breaks into <br> attributes
  // added for compatibility between web and native platform to render both
  // variations <br> and \n as new line on both platforms
  const processedText = text.replace(/\n/, "<br>")

  // split string using these tags (opening or closing)
  const regExp = /<(\/?)(b|i|br)>/g

  // list of chunks that could be rendered using primitive components
  const chunks: AttributedChunk[] = []

  // last regexp match
  let match

  // offset from the beginning of scanned text string
  let stateOffset = 0
  // is specific offset inside bold attribute pairs?
  let stateBold = false
  // is specific offset inside italic attribute pairs?
  let stateItalic = false

  do {
    // regexp object is stateful when global or sticky flag is used
    match = regExp.exec(processedText)

    const textPart = match
      // extract text between previous match and current match if matched (not last chunk)
      ? processedText.substr(stateOffset, match.index - stateOffset)
      // if not matched then extract between previous match and end of text (last chunk)
      : processedText.substr(stateOffset)

    // if chunk is not empty, then save it
    if (textPart.length) {
      chunks.push({
        text: textPart,
        bold: stripAttributes
          ? (enableAttributes
            ? (enableAttributes.includes("bold") && stateBold)
            : false)
          : stateBold,
        italic: stripAttributes
          ? (enableAttributes
            ? (enableAttributes.includes("italic") && stateItalic)
            : false)
          : stateItalic,
        break: false,
      })
    }

    if (match) {
      // whole attribute text
      const attrText = match[0]
      // it is starting attribute if backslash in attribute is not matched
      const attrStart = match[1] === ""
      // extract name of attribute for easier processing using switch() {...}
      const attrName = match[2]

      switch (attrName) {
        case "b":
          // set bold state based on if attribute is starting or ending
          stateBold = attrStart
          break

        case "i":
          // set italic state based on if attribute is starting or ending
          stateItalic = attrStart
          break

        case "br":
          if (attrStart) {
            // treat valid <br> attributes as line break, add chunk
            chunks.push({
              text: "",
              bold: false,
              italic: false,
              break: stripAttributes
                ? (enableAttributes
                  ? (enableAttributes.includes("line-break"))
                  : false)
                : true,
            })
          } else {
            // treat invalid </br> attribute as regular text chunk, add chunk
            chunks.push({ text: attrText, bold: false, italic: false, break: false })
          }
          break

        default:
          // should never happen but...
          throw new Error(`Invalid attribute: ${attrText}`)
      }

      // move tracking offset to the beginning of next unprocessed character
      stateOffset = match.index + attrText.length
    }
  } while (match)

  return chunks
}
