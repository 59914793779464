import React from "react"

import { safeReactChildrenOnly } from "../../../utilities/react/safeReactChildrenOnly"

interface LoadingLayoutProps {
  loading: boolean
  delay?: number
}

type LoadingLayoutPropsWithChildren = React.PropsWithChildren<LoadingLayoutProps>

interface LoadingLayoutState {
  bufferedChildren?: React.ReactNode
}

/**
 * This component improves "loading" behaviour and debounces extra renders
 * during multiple updates caused by "loading" state change.
 *
 * If component is in "loading" state then it will show previous children
 * for a short period of time before passing "loading" view. This is needed
 * to show "loading" indicator only when "loading" is taking more time than
 * expected.
 *
 * Debouncing for render() cycles improves performance on IE7 platform and also
 * makes SlideTransition animations smoother on WEB platform.
 *
 * This component is perfect to be used as companion to SlideLayout if loading
 * indicator is used.
 */
export class LoadingLayout extends React.Component<LoadingLayoutProps, LoadingLayoutState> {
  public static readonly defaultDelay = 300

  private timeoutID?: number

  public constructor(props: LoadingLayoutPropsWithChildren) {
    super(props)
    this.state = {
      bufferedChildren: undefined,
    }
    this.flush = this.flush.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: LoadingLayoutPropsWithChildren) {
    // it it is "loading" and previously it was not "loading" then buffer "loading" state
    if (nextProps.loading && !this.props.loading) {
      this.setState({
        bufferedChildren: this.props.children,
      }, () => {
        this.cancelFlush()
        this.scheduleFlush()
      })
    } else if (this.state.bufferedChildren && !nextProps.loading) {
      this.cancelFlush()
      this.flush()
    }
  }

  public shouldComponentUpdate(
    nextProps: LoadingLayoutPropsWithChildren,
    nextState: LoadingLayoutState,
  ) {
    const prevChildren = this.state.bufferedChildren !== undefined
      ? this.state.bufferedChildren
      : this.props.children

    const nextChildren = nextState.bufferedChildren !== undefined
      ? nextState.bufferedChildren
      : nextProps.children

    return prevChildren !== nextChildren
  }

  public scheduleFlush() {
    this.timeoutID = window.setTimeout(this.flush, this.props.delay || LoadingLayout.defaultDelay)
  }

  public cancelFlush() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID)
      this.timeoutID = undefined
    }
  }

  public flush() {
    this.setState({
      bufferedChildren: undefined,
    })
  }

  // tslint:disable-next-line: no-null-undefined-union
  public render() {
    const children = this.state.bufferedChildren !== undefined
      ? this.state.bufferedChildren
      : this.props.children

    return safeReactChildrenOnly(children)
  }
}
