import React, {Component} from 'react';
import * as styles from './AuthenticationStatus.module.css';
import {NavBar, NavTitle, Text} from "@amzn/fulfillment-hig-components";
import errorIcon from '../../images/error.png';
import successIcon from '../../images/success.png';

import qs from 'query-string';

interface State {
    error: string;
}

class AuthenticationStatus extends Component <{}, State> {

    state: State = {
        error: '',
    };

    // React lifecycle method. Gets called when the component output has been rendered to the DOM.
    componentDidMount() {
        const query_parameters = qs.parse(window.location.search);

        this.setState({
            error: query_parameters.error as string,
        });

        if(window.opener){
            const message = query_parameters.error ? `authorization_error:${query_parameters.error}`: "authorization_success";
            window.opener.postMessage(message, "*");
        }
    }

    render() {
        const {error} = this.state;

        const renderAuthenticationStatus = () => {
            if (error) {
                return (
                    <div>
                        <img src={errorIcon} className={styles.icon}/> <br/>
                        <Text size="xl" dangerousStyle={{color: "red"}}>Device is not authenticated.</Text>
                    </div>

                    //TODO Add TryItAgain Button
                )
            } else {
                return (
                    <div>
                        <img src={successIcon} className={styles.icon}/> <br/>
                        <Text size="xl" dangerousStyle={{color: "green"}}>Device is authenticated successfully.</Text>
                    </div>
                )
            }
        };

        return (
            <div>
                <div className={styles.heading}>
                    <NavBar>
                        <NavTitle>
                            <Text size="lg">FIT Security Token Service</Text>
                        </NavTitle>
                    </NavBar>
                </div>
                <div className={styles.parent}>
                    {renderAuthenticationStatus()}
                </div>
            </div>
        )
    }
}

export default AuthenticationStatus;