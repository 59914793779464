import React from "react"

export * from "./Theme"

import { Theme } from "./Theme"

import { LightTheme } from "./LightTheme"

export const defaultTheme = LightTheme

export const ThemeContext = React.createContext<Theme>(defaultTheme)

ThemeContext.displayName = "ThemeContext"
