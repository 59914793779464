import React from "react"

import { IconName } from "../icon/IconName"
import { IconColor } from "../icon/IconColor"
import { Icon } from "../icon/Icon"
import { Locale, LocaleContext } from "../i18n/LocaleContext"

export interface ListItemIconProps {
  name: IconName
  _secondary?: boolean
}

type ListItemIconState = "default" | "active"

export class ListItemIcon extends React.PureComponent<ListItemIconProps> {
  private readonly iconSize = 16

  private readonly stateColors: Record<ListItemIconState, IconColor> = {
    default: "black",
    active: "white",
  }

  private readonly states: ListItemIconState[]  = ["default", "active"]

  public renderWithContexts({ direction }: Locale) {
    const className = "list-item-icon"
      + ` list-item-icon--${!this.props._secondary ? "primary" : "secondary"}-${direction}`

    return (
      <div className={className}>
        {this.states.map((state) => (
          <Icon
            key={state}
            dangerousClassName={`list-item-icon__inner--${state}`}
            name={this.props.name}
            color={this.stateColors[state]}
            size={this.iconSize}
          />
        ))}
      </div>
    )
  }

  public render() {
    return (
      <LocaleContext.Consumer>
        {(locale) => {
          return this.renderWithContexts(locale)
        }}
      </LocaleContext.Consumer>
    )
  }
}
