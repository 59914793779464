import React from "react"

import { ChromeConfirmationModal } from "./ChromeConfirmationModal"
import { HotKeyCode } from "../keyboard/HotKeyCode"

type ChromeSignOutModal2StringKey = "caption" | "yes" | "no"

export type ChromeSignOutModal2Strings = Partial<Record<ChromeSignOutModal2StringKey, string>>

interface ChromeSignOutModal2Props {
  /**
   * Callback executed when user manually confirms sign out.
   */
  onSignOut?: () => void

  /**
   * Callback executed when user manually cancels sign out.
   */
  onCancel?: () => void
}

/**
 * Sign out confirmation modal that is displayed when user initiates sign out.
 *
 * This is a variant of ChromeSignOutModal designed for when LogoutOutWorkflow is enabled.
 * It has no timeout and no body text.
 * But note that idle detection should still be active and can log the user out
 * when necessary.
 */
export class ChromeSignOutModal2 extends React.PureComponent<ChromeSignOutModal2Props> {
  // override this value to customize strings and provide translations
  public static defaultStrings: ChromeSignOutModal2Strings = {}

  public static readonly defaultTexts: Record<ChromeSignOutModal2StringKey, string> = {
    caption: "Sign out?",
    yes: "Yes, Sign Out",
    no: "No",
  }

  public static readonly hotKeys: Record<"yes" | "no", HotKeyCode> = {
    yes: "Enter",
    no: "KeyX",
  }

  public render() {
    const { defaultStrings, defaultTexts, hotKeys } = ChromeSignOutModal2

    return (
      <ChromeConfirmationModal
        id="signOut"

        caption={{
          stringID: defaultStrings.caption,
          defaultString: defaultTexts.caption,
        }}

        primaryButtonHotKey={hotKeys.yes}
        primaryButtonLabel={{
          stringID: defaultStrings.yes,
          defaultString: defaultTexts.yes,
        }}
        onPrimaryButtonClick={this.props.onSignOut}

        secondaryButtonHotKey={hotKeys.no}
        secondaryButtonLabel={{
          stringID: defaultStrings.no,
          defaultString: defaultTexts.no,
        }}
        onSecondaryButtonClick={this.props.onCancel}
      />
    )
  }
}
