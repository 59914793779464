import React from "react"

import debounce from "lodash.debounce"

import { IconName } from "../icon/IconName"
import { Icon } from "../icon/Icon"
import { HotKey } from "../keyboard/HotKey"
import { HIGState } from "../../config/HIGState"
import { SoundManager } from "../../utilities/sound/SoundManager"
import { HotKeyResponder, HotKeySet } from "../keyboard/HotKeyResponder"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"
import { LocalizedString, Translator } from "../i18n/Translator"
import { withTranslator } from "../i18n/withTranslator"

export interface NavButtonProps extends TestProps {
  /**
   * Icon to display.
   */
  iconName?: IconName

  /**
   * Hot key or set of hot keys as alternative to mouse click.
   */
  hotKey?: HotKeySet

  /**
   * Display button inside NavBar on this side.
   */
  side?: "natural" | "reverse"

  /**
   * Callback that is called when button is clicked or hot key is pressed.
   */
  onClick?: () => void

  translator: Translator

  ariaLabel?: LocalizedString

  /**
   * Does this button controls a pop-up such as a menu?
   */
  hasPopUp?: boolean

  /**
   * Is the pop-up expanded?
   */
  popUpExpanded?: boolean
}

class NavButtonBase extends React.PureComponent<NavButtonProps> {
  private readonly soundManager: SoundManager

  private readonly clickAudioFeedbackEnabled: boolean

  private readonly clickDebounceDelay = 100 // in ms

  public constructor(props: NavButtonProps) {
    super(props)

    this.handleClick = debounce(this.handleClick.bind(this),
                                this.clickDebounceDelay, { leading: true, trailing: false })

    this.handleHotKey = this.handleHotKey.bind(this)
    this.soundManager = HIGState.soundManager
    this.clickAudioFeedbackEnabled = HIGState.clickAudioFeedbackEnabled
  }

  public componentWillUnmount() {
    // Debounced methods should be cancelled to prevent setState() after destroy
    // @ts-ignore
    this.handleClick.cancel()
  }

  public handleClick() {
    if (this.clickAudioFeedbackEnabled) {
      this.soundManager.play("confirmation")
    }

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  public handleHotKey() {
    this.handleClick()
  }

  public render() {
    const side = this.props.side || "natural"

    const isNatural = side === "natural"

    const className = "navbar-button"
      + ` navbar-button--${side}`
      + (this.props.iconName ? ` navbar-button--with-icon` : "")

    const labelClassName = "navbar-button__label"

    // AFT UX wants to make menu icon larger in NavBar
    const iconSize = this.props.iconName === "menu" ? 32 : 24

    // When the pop-up this controls expands, it will hide this button
    // and so aria-hidden is set to true in this case.

    const content = (
      <button
        className={className}
        tabIndex={undefined}
        onClick={this.handleClick}
        data-testid={renderTestID(this.props.testID)}
        aria-label={this.props.ariaLabel
          ? this.props.translator.lookup(this.props.ariaLabel) : undefined}
        aria-haspopup={this.props.hasPopUp}
        aria-expanded={this.props.popUpExpanded}
        aria-hidden={this.props.popUpExpanded}
        accessKey={this.props.hotKey ? HotKey.describeHotKeySet(this.props.hotKey) : undefined}
      >
        <span
          className="navbar-button__inner"
        >
          {this.props.iconName && isNatural && (
            <Icon name={this.props.iconName} size={iconSize} color="white" />
          )}
          {this.props.children && (
            <span className={labelClassName}>
              {this.props.iconName && isNatural && (<span>&nbsp;&nbsp;</span>)}
              {this.props.children}
              {this.props.hotKey && " "}
              {this.props.hotKey && <HotKey keyCode={this.props.hotKey} />}
              {this.props.iconName && !isNatural && (<span>&nbsp;&nbsp;</span>)}
            </span>
          )}
          {this.props.iconName && !isNatural && (
            <Icon name={this.props.iconName} size={iconSize} color="white" />
          )}
        </span>
      </button>
    )

    if (this.props.hotKey) {
      return (
        <HotKeyResponder
          keyCode={this.props.hotKey}
          onKeyPress={this.handleHotKey}
        >
          {content}
        </HotKeyResponder>
      )
    }

    return content
  }
}

export const NavButton = Object.assign(
  withTranslator(NavButtonBase), {
    displayName: "NavButton"
  }
)
