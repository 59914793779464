import React from "react"

import { FontSize } from "../theme/Theme"
import { ResourceId, ResourceIdBaseProps } from "../resource/ResourceId"

export type ProductIdSize = FontSize

interface ProductIdProps extends ResourceIdBaseProps {
  /**
   * Product idenitifer.
   */
  productID: string
}

/**
 * Renders product identifier (ASIN, ISBN, fnSKU, fcSKU, LPN etc).
 *
 * Has additional optimizations for LPN that could be up to 40 characters long.
 */
export const ProductId: React.FunctionComponent<ProductIdProps>
  = React.memo((props: ProductIdProps) => {
    const { productID, ...resourceIdProps } = props

    return (
      <ResourceId
        {...resourceIdProps}
        resourceID={productID}
        variant={/^lpn/i.test(productID) ? "lpn" : "asin"}
      />
    )
  })

ProductId.displayName = "ProductId"
