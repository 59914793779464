import React from "react"

import { ListOptionsContext } from "./ListOptionsContext"

export interface ListSplitterProps {
  hidden?: boolean
}

export class ListSplitter extends React.PureComponent<ListSplitterProps> {
  public render() {
    return (
      <ListOptionsContext.Consumer>
        {({ itemSize }) => {
          const className = "list-splitter"
            + ` list-splitter--size-${itemSize}`
            + (this.props.hidden ? " list-splitter--hidden" : "")
          return (
            <div className={className} />
          )
        }}
      </ListOptionsContext.Consumer>
    )
  }
}
