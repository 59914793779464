import { IconName } from "./IconName"

export const IconNameMap: Record<IconName, string> = {
  "add-photo": "addPhoto",
  "alert": "alert",
  "barcode": "barCode",
  "bell": "bell",
  "calendar": "calendar",
  "caret-down": "caretDown",
  "caret-left": "caretLeft",
  "caret-right": "caretRight",
  "caret-up": "caretUp",
  "check-solid": "checkSolid",
  "check": "check",
  "chevron-down": "chevronDown",
  "chevron-horizontal": "chevronHorizontal",
  "chevron-left": "chevronLeft",
  "chevron-right": "chevronRight",
  "chevron-up": "chevronUp",
  "clipboard": "clipboard",
  "comment": "comment",
  "download": "download",
  "ellipsis": "ellipsis",
  "error": "error",
  "expand-arrows": "expandArrows",
  "external-link": "externalLink",
  "file": "file",
  "globe": "globe",
  "grid": "grid",
  "hide-detail": "hideDetail",
  "history": "history",
  "image": "imageSolid",
  "info-solid": "infoSolid",
  "info": "infoLight",
  "list": "list",
  "lock-unlock": "lockUnlock",
  "lock": "lock",
  "long-arrow-down": "longArrowDown",
  "long-arrow-left": "longArrowLeft",
  "long-arrow-right": "longArrowRight",
  "long-arrow-up": "longArrowUp",
  "menu-close": "menuClose",
  "menu": "menu",
  "no-wifi": "noWifi",
  "pencil": "pencil",
  "print": "print",
  "profile": "profile",
  "qr-code": "qrCode",
  "question": "questionLight",
  "save": "save",
  "search": "search",
  "show-detail": "showDetail",
  "sort-alpha-down": "sortAlphaDown",
  "sort-alpha-up": "sortAlphaUp",
  "sort-amount-down": "sortAmountDown",
  "sort-amount-up": "sortAmountUp",
  "sort-numeric-down": "sortNumericDown",
  "sort-numeric-up": "sortNumericUp",
  "trash": "trash",
  "triangle": "triangle",
  "upload": "upload",
  "x-solid": "xSolid",
  "x": "x",
}
