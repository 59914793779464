import React from "react"

import { CartSlotVariant } from "./CartSlotVariant"
import { FlexLayout } from "../layout/flex/FlexLayout"
import { CartSlot, CartSlotProps, CartSlotSize } from "./CartSlot"
import { Size } from "../helpers/parseSize"
import { TestProps } from "../helpers/TestProps"

export interface MultiSlotCartProps extends TestProps {
  /**
   * The number of rows in the grid.
   */
  rows: number

  /**
   * The number of columns in the grid.
   */
  cols: number

  /**
   * Spacing between the cells in the grid.
   */
  spacingSize?: Size

  /**
   * Size used to render the inner CartSlot components.
   */
  slotSize?: CartSlotSize

  /**
   * The variant used for the one and only active tote.
   */
  activeSlotVariant?: CartSlotVariant

  /**
   * The custom color used on the one and only active tote.
   */
  activeSlotColor?: string

  /**
   * The location of the active tote, if any.
   */
  activeSlot?: string

  /**
   * The locations of the prepared totes.
   */
  preparedSlots?: string[]

  /**
   * The location labels for the totes in the grid.
   * The size of this array must equal rows times cols.
   */
  locations: string[]
}

interface ViewModel {
  rows: ViewModelRow[]
}

type ViewModelRow = "spacer" | { cols: ViewModelColumn[] }
type ViewModelColumn = "spacer" | ViewModelSlot

interface ViewModelSlot extends Omit<CartSlotProps, "translator" | "layoutSize" | "location"> {
  location: string
  testID?: string
}

/**
 * Renders a grid of cart slots.
 * A maximum of one slot could be active at a given moment.
 * Any number of slots could be prepared.
 * @deprecated
 */
export class MultiSlotCart extends React.PureComponent<MultiSlotCartProps> {

  /**
   * Evaluates a view model of what is to be rendered.
   */
  private buildViewModel(): ViewModel {
    const {
      slotSize,
      activeSlotVariant,
      activeSlotColor,
      activeSlot,
      preparedSlots = [],
      locations,
    } = this.props

    let {
      rows,
      cols,
    } = this.props

    if (rows < 1 || isNaN(rows)) {
      rows = 1
    }

    if (cols < 1 || isNaN(cols)) {
      cols = 1
    }

    if (locations.length < (rows * cols)) {
      console.warn(
        `Invalid locations array size (${locations.length}) ` +
        "passed to to MultiSlotCart component " +
        `with (rows, cols) = (${rows}, ${cols})`,
      )
    }

    const viewModelRows: ViewModelRow[] = []

    Array.from(Array(rows).keys()).forEach((row) => {
      const viewModelColumns: ViewModelColumn[] = []

      Array.from(Array(cols).keys()).forEach((col) => {
        const slotInd = row * cols + col
        const location = locations[slotInd]
        const isActiveSlot = activeSlot === locations[slotInd]

        viewModelColumns.push({
          location,
          size: slotSize,
          variant: (isActiveSlot ? activeSlotVariant : "inactive"),
          color: (isActiveSlot ? activeSlotColor : undefined),
          prepared: preparedSlots.includes(location),
        })

        if (col < (cols - 1)) {
          viewModelColumns.push("spacer")
        }
      })

      viewModelRows.push({
        cols: viewModelColumns
      })

      if (row < (rows - 1)) {
        viewModelRows.push("spacer")
      }
    })

    return {
      rows: viewModelRows
    }
  }

  public render() {
    const {
      spacingSize = "sm",
      locations,
    } = this.props

    let {
      rows,
      cols,
    } = this.props

    if (rows < 1 || isNaN(rows)) {
      rows = 1
    }

    if (cols < 1 || isNaN(cols)) {
      cols = 1
    }

    if (locations.length < (rows * cols)) {
      console.warn(
        `Invalid locations array size (${locations.length}) ` +
        "passed to to MultiSlotCart component " +
        `with (rows, cols) = (${rows}, ${cols})`,
      )
    }

    const viewModel = this.buildViewModel()

    return (
      <FlexLayout direction="column" testID={this.props.testID}>
        {viewModel.rows.map((row, rowInd) => (
          (row === "spacer" ? (
            <FlexLayout.Spacer
              key={"row_spacer_" + rowInd}
              size={spacingSize}
            />
          ) : (
            <FlexLayout.Item key={"row_" + rowInd}>
              <FlexLayout direction="row">
                {row.cols.map((col, colInd) => (
                  (col === "spacer" ? (
                    <FlexLayout.Spacer
                      key={"col_spacer_" + colInd}
                      size={spacingSize}
                    />
                  ) : (
                    <FlexLayout.Item key={"col_" + colInd}>
                      <CartSlot
                        location={col.location}
                        size={col.size}
                        variant={col.variant}
                        color={col.color}
                        prepared={col.prepared}
                      />
                    </FlexLayout.Item>
                  ))
                ))}
              </FlexLayout>
            </FlexLayout.Item>
          ))
        ))}
      </FlexLayout>
    )
  }

}
