/**
 * Tier variant.
 *
 * Can be used to differentiate between tier types etc.
 */

 export function getTierVariant(imageVariant: string): TierVariant {
  const tierVariant = tierVariants.find((tier) => tier === imageVariant)
  if (tierVariant) {
    return tierVariant
  }

  throw new Error(`Unable to find the provided tier variant: ${imageVariant}`)
 }

 export const tierVariants = [
  "cases_per_full_tier",
  "cases_per_partial_tier",
  "full_tier"] as const
 export type TierVariant = typeof tierVariants[number]
