import React from "react"

import { PlaySound } from "../sound/PlaySound"
import { Alert } from "../alert/Alert"
import { LocalizedString } from "../i18n/Translator"
import { PDFRequestStatus } from "./PrintDocFlowLike"

export interface PrintDocFlowAlertStrings {
    READY?: string,
    NOT_STARTED?: string,
    IN_PROGRESS?: string,
    COMPLETED?: string,
    ERRORED?: string,
    TIMED_OUT?: string,
}

export interface PrintDocFlowProps {
  /**
   * PrintDocFlow status provided by `PrintDocFlowRequest`.
   */
  status: PDFRequestStatus

  /**
   * Mark alert as inactive, alert status doesn't propagate to chrome in that case.
   */
  inactive?: boolean

  /**
   * Localization string IDs for each PrintDocFlow status.
   *
   * Initialize `PrintDocFlowAlert.defaultStrings` with default translation
   * strings for different states or explicitly pass them as `strings` property.
   */
  strings?: PrintDocFlowAlertStrings
}

/**
 * PrintDocFlow status alert component.
 *
 * Has visual and audio indication of print status.
 */
export class PrintDocFlowAlert extends React.PureComponent<PrintDocFlowProps> {
  public static defaultStrings?: PrintDocFlowAlertStrings

  public render() {
    const { status, inactive } = this.props

    // If the request should be considered as failed
    const hasFailed = status === PDFRequestStatus.ERRORED || status === PDFRequestStatus.TIMED_OUT

    const sound = hasFailed ? "error" : "confirmation"
    const mute = !(hasFailed || status === PDFRequestStatus.COMPLETED)
    const variant = hasFailed ? "error" : status === PDFRequestStatus.COMPLETED ? "success" : "info"
    const strings = this.props.strings || PrintDocFlowAlert.defaultStrings || {}

    const title: LocalizedString = {
      stringID: strings[status],
      defaultString: `Printing: ${status}`,
    }

    return (
      <PlaySound sound={sound} mute={mute}>
        <Alert density="dense" variant={variant} title={title} inactive={inactive} />
      </PlaySound>
    )
  }
}
