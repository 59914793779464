import React from "react"

import { Input, Textarea } from "@amzn/alchemy-components-react"

import debounce from "lodash.debounce"

import { LocalizedString, Translator } from "../i18n/Translator"
import { withTranslator } from "../i18n/withTranslator"
import { withLocale } from "../i18n/withLocale"
import { Locale } from "../i18n/LocaleContext"
import { memoizeOne } from "../../utilities/memoizeOne"
import { withSlideLayoutActivity } from "../layout/slide/withSlideLayoutActivity"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"
import { generateID } from "../../utilities/uuid"
import { isKeyPrintable } from "../keyboard/isKeyPrintable"
import { LocalizedText } from "../text/LocalizedText"

Input.displayName = "AlchemyInput"
Textarea.displayName = "AlchemyTextarea"

export type TextInputTextAlign =
  "natural"
  | "reverse"
  | "center"
  | "left"
  | "right"

export interface ValidationResult {
  valid: boolean,
  failureMessage?: LocalizedString
}

export type TextInputValidator = string | RegExp | ((value: string) => boolean | ValidationResult)

export type TextInputFilter = string | RegExp

export type TextInputSize = "sm" | "md" | "lg" | "xl" | "2xl"

export type TextInputVisualState = "focused" | "invalid" | "invalid-focused" | "disabled"

export interface TextInputProps extends DangerousProps, TestProps {
  /**
   * Form field name.
   */
  name?: string

  /**
   * Form field size.
   *
   * Affects font size, padding, margin etc.
   */
  size?: TextInputSize

  /**
   * Expand field to the whole available width.
   */
  fluid?: boolean

  /**
   * Make field to fit into parent container.
   *
   * Only for textarea
   */
  flex?: boolean

  /**
   * Width of the field.
   *
   * Grid units are only supported: 1gu..12gu (12gu is equal to fluid)
   */
  width?: string

  /**
   * Initial value for the field.
   */
  initialValue?: string

  /**
   * Function that validates the value.
   *
   * The value is RegExp, stringified RegExp or custom function
   *
   * For a function, if a boolean is returned, validationFailureMessage
   * will be used to show the error when it's false;
   * if {valid: boolean, failureMessage?: LocalizedString} is returned, failureMessage
   * will be used when valid is false.
   * The second approach is preferred, especially if the message can change depending
   * on the error.
   */
  validator?: TextInputValidator

  /**
   * Custom validation failure message for input errors.
   *
   * If the message can change depending on the error, please use
   * validator to return the message instead.
   */
  validationFailureMessage?: LocalizedString

  /**
   * Enable to mark field as invalid if empty.
   */
  required?: boolean

  /**
   * Min number of characters for the field.
   */
  minLength?: number

  /**
   * Max number of characters for the field.
   */
  maxLength?: number

  /**
   * Display placeholder.
   */
  placeholder?: LocalizedString

  /**
   * Render field as read only.
   */
  readOnly?: boolean

  /**
   * Render field as disabled.
   *
   * Disabled fields are not submitted with form and can't be focused.
   */
  disabled?: boolean

  /**
   * Set text alignment.
   */
  textAlign?: TextInputTextAlign

  /**
   * Enables integrated browser auto completion.
   *
   * Disabled by default.
   */
  autoComplete?: boolean

  /**
   * Filter input for this field.
   *
   * The value is RegExp or stringified RegExp
   */
  filter?: TextInputFilter

  /**
   * Custom filter failure message for input errors.
   *
   * Validaiton failure message takes precedence over the filter failure message.
   */
  filterFailureMessage?: LocalizedString

  /**
   * Automatically focus field.
   */
  autoFocus?: boolean

  /**
   * Auto select whole text when input is focused.
   */
  autoSelect?: boolean

  /**
   * Whether or not spell checking will be enabled.
   *
   */
  spellCheck?: boolean

  /**
   * Whether or not the text input is multiline.
   */
  multiline?: boolean

  /**
   * Triggered when user changes the value and leaves the field.
   */
  onChange?: (newValue: string) => void

  /**
   * Force specific state for demo purposes.
   */
  forceState?: TextInputVisualState

  /**
   * Translator is used to translate provided labels.
   *
   * Injected automatically.
   */
  translator: Translator

  /**
   * Locale is used to detect text direction.
   *
   * Injected automatically.
   */
  locale: Locale

  /**
   * Is field inside active slide.
   *
   * Inactive slide requires disabling autoFocus etc to prevent buggy beaviour
   * during slide transitions.
   */
  insideActiveSlide: boolean

  /**
   * Special annotation for the code that helps other code to determin
   * what kind of content is inside field to properly decide if hot key
   * should be processed or not.
   */
  contentType?: "number"

  /**
   * Custom localized string to override the aria-label accessibility tag.
   *
   * This field is mandatory to make inputs accessibility.
   */
  ariaLabel?: LocalizedString

  /**
   * String to override the aria-labelledby accessibility tag.
   *
   * This field will take precedence over ariaLabel.
   */
  ariaLabelledBy?: string

  /**
   * Unique id to map to the input and validation message.
   *
   */
  id?: string

  /**
   * This presets the min attribute of the input. It can only be a number. (From Alchemy)
   *
   */
  min?: number

  /**
   * This presets the max attribute of the input. It can only be a number. (From Alchemy)
   *
   */
  max?: number
}

interface TextInputState {
  // is field valid?
  // we don't use native validation here, so need to keep tracking of state
  // native validation is good but it is not supported on IE7
  valid: boolean

  // is field touched by user?
  // clean fields are not marked as invalid until user touchs it or form is submitted
  dirty: boolean

  // keep track of failure state for filter failure messages during keypress events
  filterFailure: boolean

  // The current message for validaton failure
  validationFailureMessage?: LocalizedString

  id: string
}

export interface TextInputStrings {
  minLength?: string,
  maxLength?: string,
  required?: string,
}

/**
 * Generic text input component.
 */
class TextInputBase extends React.PureComponent<TextInputProps, TextInputState> {
  private readonly inputRef = React.createRef<HTMLElement>()

  private readonly validateDelay = 100 // ms

  private readonly selectDelay = 100 // ms

  private readonly focusDelay = 100 // ms

  private readonly defaultSize = "md"

  public static defaultStrings?: TextInputStrings

  private readonly emptyStrings: TextInputStrings = {}

  public constructor(props: TextInputProps) {
    super(props)

    this.state = {
      dirty: false,
      valid: true,
      filterFailure: false,
      id: this.props.id ?? generateID()
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)

    // cache RegExp
    this.getFilterRegExp = memoizeOne(this.getFilterRegExp.bind(this))

    // cache function
    this.getValidator = memoizeOne(this.getValidator.bind(this))

    // debounce for performance optimization and to run after key press is processed
    this.validateWithDelay = debounce(this.validateWithDelay.bind(this), this.validateDelay)
  }

  public getValidator(validator: TextInputValidator) {
    switch (typeof validator) {
      case "function":
        return validator

      case "string":
        const regExp = new RegExp(validator)
        return (char: string) => regExp.test(char)

      default:
        return (char: string) => validator.test(char)
    }
  }

  public isValid(value: string): ValidationResult {
    const strings = TextInputBase.defaultStrings || this.emptyStrings
    // check required validator
    if (this.props.required && value.length === 0) {
      return {
        valid: false,
        failureMessage: {
          stringID: strings.required,
          defaultString: "A value is required."
        }
      }
    }

    // check minLength validator
    if (this.props.minLength !== undefined
      && this.props.minLength !== null
      && value.length < this.props.minLength
    ) {
      return {
        valid: false,
        failureMessage: {
          parameters: { length: this.props.minLength },
          stringID: strings.minLength,
          defaultString: "Minimum length: {length}"
        }
      }
    }

    // check maxLength validator
    if (this.props.maxLength !== undefined
      && this.props.maxLength !== null
      && value.length > this.props.maxLength
    ) {
      return {
        valid: false,
        failureMessage: {
          parameters: { length: this.props.maxLength },
          stringID: strings.maxLength,
          defaultString: "Maximum length: {length}"
        }
      }
    }

    // check custom validator
    if (this.props.validator) {
      const validator = this.getValidator(this.props.validator)
      const result = validator(value)
      if (result === false) {
        return {
          valid: false,
          failureMessage: this.props.validationFailureMessage
        }
      } else if (typeof result === "object" && !result.valid) {
        return result
      }
    }

    // check filter if value is not empty
    // it is still possible to bypass filter and use clipboard to paste invalid value
    if (this.props.filter && value.length) {
      const filterRegExp = this.getFilterRegExp(this.props.filter)
      if (!filterRegExp.test(value)) {
        return {
          valid: false,
          failureMessage: this.props.filterFailureMessage
        }
      }
    }

    // treat as valid otherwise
    return { valid: true }
  }

  public validateWithDelay() {
    // this function is debounced in constructor so it could buffer a few
    // validation requests (when use is typing text, for example)
    this.validate()
  }

  public validate() {
    const input = this.getInputElement()
    if (input) {
      const result = this.isValid(input.value || "")

      this.setState({
        valid: result.valid,
        dirty: !result.valid || this.state.dirty,
        filterFailure: !result.valid && this.state.filterFailure,
        validationFailureMessage: result.failureMessage
      })

      return result.valid
    }
    return true
  }

  public getFilterRegExp(filter: TextInputFilter): RegExp {
    // convert string to RegExp
    // convert RegExp to the format that can be used not only on single character
    // but on whole value to use it also in validator
    return typeof filter === "string"
      ? new RegExp(`^(${filter})+$`)
      : new RegExp(`^(${filter.source})+$`, filter.ignoreCase ? "i" : "")
  }

  public handleKeyDown(event: Event | React.KeyboardEvent) {
    const kbdEvent = event as KeyboardEvent

    // Ignore IME composition events
    // See https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event
    // Note `isComposing` is not yet available in React.KeyboardEvent
    // but only available in KeyboardEvent
    if (kbdEvent.isComposing || kbdEvent.keyCode === 229) {
      return
    }

    // Skip if no char will be inserted
    if (!isKeyPrintable(kbdEvent.key)) {
      return
    }

    if (!this.state.dirty) {
      this.setState({ dirty: true })
    }

    if (this.props.filter) {
      const filterRegExp = this.getFilterRegExp(this.props.filter)

      if (!filterRegExp.test(kbdEvent.key)) {
        event.preventDefault()

        this.setState({
          filterFailure: true,
          validationFailureMessage: this.props.filterFailureMessage
        })
      } else {
        // Remove filter message for valid regex
        if (this.state.filterFailure) {
          this.setState({
            filterFailure: false,
            validationFailureMessage: undefined
          })
        }
      }
    }
  }

  public handleFocus() {
    if (this.props.autoSelect) {
      this.select()
    }
  }

  public handleBlur() {
    // Note: If the content is changed, change event would also be fired,
    // causing us this.validate() twice.

    // immediately revalidate field when leaving it
    this.validate()
  }

  public handleChange(event: Event | React.SyntheticEvent) {
    // revalidate form
    if (!this.validate()) {
      return
    }

    if (this.props.onChange) {
      const newValue = (event.target as HTMLInputElement).value
      this.props.onChange(newValue)
    }
  }

  public select() {
    const input = this.getInputElement()
    if (input) {
      // delay is needed to make it properly work across different versions of browsers
      setTimeout(() => input.select(), this.selectDelay)
    }
  }

  public focus() {
    // native autoFocus is not used because `preventScroll` can be passed to it
    // without `preventScroll` there are a bunch of problems in slide layout
    const focusDelay = this.focusDelay
    // immediate focus could be not reliable, with delay - reliable enough ;-)
    setTimeout(() => this.getInputElement()?.focus({ preventScroll: true }), focusDelay)
  }

  public componentDidMount() {
    if (this.props.autoFocus) {
      this.focus()
    }

    // Use setTimeout as a workaround
    // as this.getCurrentRef().shadowRoot would not have <input>
    // immediately available
    setTimeout(() => this.attachHooks())
  }

  public componentWillUnmount() {
    this.detachHooks()
  }

  public attachHooks() {
    const input = this.getInputElement()
    if (input) {
      input.addEventListener("change", this.handleChange)
      input.addEventListener("keydown", this.handleKeyDown)
      input.addEventListener("blur", this.handleBlur)
      input.addEventListener("focus", this.handleFocus)

      // $validate and $reset are used by <Form /> to check
      // if form is valid on form submit stage
      // @ts-ignore
      this.inputRef.current.$validate = () => {
        return this.validate()
      }

      // @ts-ignore
      this.inputRef.current.$reset = () => {
        this.setState({ dirty: false })
      }
    }
  }

  public detachHooks() {
    // clear hooks to prevent resource leak
    const input = this.getInputElement()
    if (input) {
      input.removeEventListener("change", this.handleChange)
      input.removeEventListener("keydown", this.handleKeyDown)
      input.removeEventListener("blur", this.handleBlur)
      input.removeEventListener("focus", this.handleFocus)

      // @ts-ignore
      this.inputRef.current.$validate = undefined
      // @ts-ignore
      this.inputRef.current.$reset = undefined
    }
  }

  public normalizeWidth(width?: string) {
    return width?.endsWith("gu")
      ? `${Math.max(1, Math.min(12, parseInt(width, 10)))}gu`
      : undefined
  }

  public getPlaceholderText(placeholder?: LocalizedString) {
    const { translator } = this.props
    return placeholder ? translator.lookup(placeholder) : ""
  }

  public getStatusMessage(invalid: boolean) {
    if ((invalid || this.state.filterFailure) && this.state.validationFailureMessage) {
      return this.props.translator.lookup(this.state.validationFailureMessage)
    }
  }

  private getCurrentRef() {
    return this.inputRef.current
  }

  private getInputElement() {
    if (this.props.multiline) {
      return this.getCurrentRef()?.shadowRoot?.querySelector("textarea")
    } else {
      return this.getCurrentRef()?.shadowRoot?.querySelector("input")
    }
  }

  public render() {
    const {
      name,
      size = this.defaultSize,
      fluid,
      flex,
      disabled,
      readOnly,
      autoComplete,
      placeholder,
      textAlign,
      dangerousClassName,
      dangerousStyle,
      locale,
      multiline = false,
      translator,
      ariaLabel,
      ariaLabelledBy,
      contentType,
      testID,
      min,
      max
    } = this.props

    // render as invalid only if it is dirty to have it initially not red
    const invalid = this.state.dirty && !this.state.valid

    const width = this.normalizeWidth(this.props.width)

    const placeholderText = this.getPlaceholderText(placeholder)

    const defaultValue = this.props.initialValue ?? ""

    const style = dangerousStyle

    const statusMessage = this.getStatusMessage(invalid)

    const wrapperClassName = "text-input-wrapper"
      + (flex ? ` text-input-wrapper--flex` : "")
      + (fluid ? ` text-input-wrapper--fluid` : "")
      + (width ? ` text-input-wrapper--width-${width}` : "")

    const validationMessageId = `${this.state.id}-validation-message`

    const className = "text-input"
      + ` text-input--size-${size}`
      + (invalid ? ` text-input--invalid` : "")
      + (textAlign ? ` text-input--align-${textAlign}-${locale.direction}` : "")
      + (dangerousClassName ? ` ${dangerousClassName}` : "")

    return (
      <div className={wrapperClassName}>
        {!multiline ? (
          <Input
            id={this.state.id}
            ref={this.inputRef}
            type={contentType ? contentType : "text"}
            className={className}
            style={style}
            // always stretch so we can control the width
            stretch={true}
            name={name}
            autocomplete={autoComplete ? "on" : "off"}
            placeholder={placeholderText}
            disabled={disabled}
            readonly={readOnly}
            value={defaultValue}
            status={statusMessage}
            statusVariant={statusMessage ? "error" : "default"}
            statusSrOnly={true}
            label={ariaLabel ? translator.lookup(ariaLabel) : undefined}
            labelSrOnly={true}
            min={contentType === "number" ? min : undefined}
            max={contentType === "number" ? max : undefined}

            // Below 4 handlers are only for unittesting
            // The real handlers are registered through addEventListener
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}

            data-content-type={contentType}
            data-testid={renderTestID(testID)}
            aria-labelledby={ariaLabelledBy}
          />
        ) : (
          <Textarea
            id={this.state.id}
            ref={this.inputRef}
            rows={1}
            fill={this.props.flex}
            notResizable={true}
            className={className}
            style={style}
            name={name}
            autocomplete={autoComplete ? "on" : "off"}
            spellcheck={this.props.spellCheck}
            placeholder={placeholderText}
            disabled={disabled}
            readonly={readOnly}
            value={defaultValue}
            status={statusMessage}
            statusSrOnly={true}
            statusVariant={statusMessage ? "error" : "default"}
            label={ariaLabel ? translator.lookup(ariaLabel) : undefined}
            labelSrOnly={true}

            // Below 4 handlers are only for unittesting
            // The real handlers are registered through addEventListener
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}

            data-content-type={contentType}
            data-testid={renderTestID(testID)}
            aria-labelledby={ariaLabelledBy}
          />
          )
        }
        {(invalid || this.state.filterFailure) && this.state.validationFailureMessage && (
          <div
            className="theme--variant-error text-input--validation-failure-message"
            aria-hidden={true}  // rely on Alchemy status
          >
            <LocalizedText
              id={validationMessageId}
              {...this.state.validationFailureMessage}
            />
          </div>
        )}
      </div>
    )
  }
}

export const TextInput = Object.assign(
  withSlideLayoutActivity(
    withLocale(
      withTranslator(
        TextInputBase
      ),
    ),
  ),
  {
    displayName: "TextInput",
    setTextInputDefaultStrings(defaultStrings: TextInputStrings) {
      TextInputBase.defaultStrings = defaultStrings
    },
  },
)
