import React from "react"

import { renderTestID } from "../helpers/renderTestID"
import { TestProps } from "../helpers/TestProps"
import { Icon } from "../icon/Icon"

export interface ProgressStepProps extends TestProps {
  /**
   * If step is complete component will show
   * green disc with white checkmark
   */
  complete?: boolean
  /**
   * If step is active component will show
   * blue disc with bold white step number
   */
  active?: boolean
  /**
   * Number to show in disc
   */
  step: number
}

export class ProgressStep extends React.PureComponent<ProgressStepProps> {
  public render() {
    const { active, complete, step } = this.props

    /**
     * Step disc shows green with a white checkmark when complete
     * and blue with white bold text when active
     */
    let stepClasses = "progress-step"
    if (complete) {
      stepClasses += " progress-step--complete"
    } else if (active) {
      stepClasses += " progress-step--active"
    }

    const checkMark = <Icon name="check" color="white" size={16} />

    return (
      <div
        className={stepClasses}
        data-testid={renderTestID(this.props.testID)}
      >
        <div className="progress-step__disc--wrapper">
          <div className="progress-step__left-bar" />
          <div className="progress-step__disc">
            {!complete ? step : checkMark}
          </div>
          <div className="progress-step__right-bar" />
        </div>
        <span>{this.props.children}</span>
      </div>
    )
  }
}
