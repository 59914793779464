import React from "react"

import { Text, TextProps } from "./Text"
import { AttributedText } from "./AttributedText"

import { Translator } from "../i18n/Translator"
import { TranslatorContext } from "../i18n/TranslatorContext"
import { EnabledTextAttribute } from "./parseAttributedString"

interface LocalizedTextParameters {
  [key: string]: any
}

interface LocalizedTextProps extends TextProps {
  // LocalizedText props
  stringID?: string
  parameters?: LocalizedTextParameters
  defaultString?: string

  // use AttributedText instead of Text
  attributed?: boolean
  stripAttributes?: boolean
  enableAttributes?: EnabledTextAttribute[]

  /**
   * ID props
   *
   * Must be unique and contain at least one character.
   */
  id?: string
}

/**
 * Render localized text using current locale context and MessageFormat.
 *
 * This component is assuming that all string translations will have a MessageFormat format.
 * There is an option to pass fallback string that is also processed as MessageFormat format string.
 * Current locale context is used to retrieve translation strings.
 *
 * Simple attributes could be used in text if "attributed" prop is enabled.
 */
export class LocalizedText extends React.PureComponent<LocalizedTextProps> {
  public getText(translator: Translator) {
    return translator.lookup(this.props)
  }

  public render() {
    const TextComponent = this.props.attributed ? AttributedText : Text

    const textProps = { ...this.props }
    delete textProps.stringID
    delete textProps.parameters
    delete textProps.defaultString
    delete textProps.attributed

    return (
      <TranslatorContext.Consumer>
        {(translator) => (
          <TextComponent {...textProps}>
            {this.getText(translator)}
          </TextComponent>
        )}
      </TranslatorContext.Consumer>
    )
  }
}
