import React from "react"

import { Subtract } from "utility-types"
import { ViewportCaps } from "./ViewportCaps"
import { ViewportCapsContext } from "./ViewportCapsContext"

interface WithViewportCaps {
  viewportCaps: ViewportCaps
}

/**
 * HoC function that injects ViewportCaps as `viewportCaps` property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withViewportCaps = <
  S extends WithViewportCaps = WithViewportCaps,
  D = Subtract<S, WithViewportCaps>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <ViewportCapsContext.Consumer>
      {(viewportCaps) => (
        <WrappedComponent
          {...(props as unknown as S)}
          viewportCaps={viewportCaps}
        />
      )}
    </ViewportCapsContext.Consumer>
  )

  wrapper.displayName = `withViewportCaps(${displayName})`

  return wrapper
}
