import React from "react"

import { Subtract } from "utility-types"

import { SlideLayoutContext } from "./SlideLayoutContext"

export interface WithSlideLayoutActivityProps {
  insideActiveSlide: boolean
}

/**
 * HoC function that injects SlideLayoutContext's value as insideActiveSlide property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withSlideLayoutActivity = <
  S extends WithSlideLayoutActivityProps = WithSlideLayoutActivityProps,
  D = Subtract<S, WithSlideLayoutActivityProps>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <SlideLayoutContext.Consumer>
      {(active) => (
        <WrappedComponent
          {...(props as unknown as S)}
          insideActiveSlide={active}
        />
      )}
    </SlideLayoutContext.Consumer>
  )

  wrapper.displayName = `withSlideLayoutActivity(${displayName})`

  return wrapper
}
