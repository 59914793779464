import React from "react"

import { ResourceId, ResourceIdBaseProps } from "../resource/ResourceId"

interface Props extends Pick<ResourceIdBaseProps, "size" | "testID"> {
  /**
   * Tracking number.
   */
  trackingNumber: string
}

/**
 * Renders a shipping tracking number.
 */
export const ShippingTrackingNumber: React.FunctionComponent<Props>
  = (props: Props) => (
    <ResourceId
      resourceID={`#${props.trackingNumber}`}
      variant="shipping"
      size={props.size}
      testID={props.testID}
    />
  )

ShippingTrackingNumber.displayName = "ShippingTrackingNumber"
