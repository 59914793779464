import React from "react"

import { Subtract } from "utility-types"

import { LayoutSize, LayoutSizeContext } from "./LayoutSizeContext"

export interface WithLayoutSize {
  layoutSize: LayoutSize
}

/**
 * HoC function that injects LayoutSize as `layoutSize` property.
 *
 * @see https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/HOC.md
 */
export const withLayoutSize = <
  S extends WithLayoutSize = WithLayoutSize,
  D = Subtract<S, WithLayoutSize>
>(
  WrappedComponent: React.ComponentType<S>,
): React.FunctionComponent<D> => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const wrapper: React.FunctionComponent<D> = (props: D)  => (
    <LayoutSizeContext.Consumer>
      {(layoutSize) => (
        <WrappedComponent
          {...(props as unknown as S)}
          layoutSize={layoutSize}
        />
      )}
    </LayoutSizeContext.Consumer>
  )

  wrapper.displayName = `withLayoutSize(${displayName})`

  return wrapper
}
