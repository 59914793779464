// tslint:disable:no-reference
/// <reference path="../../../types/svg-image.d.ts" />

import React from "react"

import dock_door_closed_img from "../../resources/images/dock-door-closed.i.svg"

import { DockVariant } from "./DockVariant"
import { LocalizedString, Translator } from "../i18n/Translator"

export interface DockImageProps {
  /**
   * The dock type that defines the dock image to be rendered.
   */
  dockVariant: DockVariant

  /**
   * Alt text for the image.
   */
  altText?: LocalizedString | ""

  /**
   * Translator is used to translate alt text localized string.
   *
   * Injected automatically.
   */
  translator: Translator
}

/**
 * Dock image.
 *
 * This is an internal component inside the Image component
 * injected through props (name="dock").
 */
export class DockImage extends React.PureComponent<DockImageProps> {
  public render() {
    const { dockVariant, altText, translator } = this.props
    const imageConf = imageConfMap[dockVariant]

    const imageAltText = altText ? translator.lookup(altText) : ""

    return (
      <img
        src={imageConf.path}
        className="dock-image"
        alt={imageAltText}
        width={imageConf.width}
        height={imageConf.height}
      />
    )
  }
}

interface ImageConf {
  path: string
  width: number
  height: number
}

const imageConfMap: Record<DockVariant, ImageConf> = {
  dock_door_closed: {
    path: dock_door_closed_img,
    width: 150,
    height: 90,
  }
}
