/**
 * Drop-zone variant.
 *
 * Can be used to differentiate between drop-zone types etc.
 */

export function getDropZoneVariant(imageVariant: string): DropZoneVariant {
  const dropZoneVariant = dropZoneVariants.find((variant) => variant === imageVariant)
  if (dropZoneVariant) {
    return dropZoneVariant
  }

  throw new Error(`Unable to find the provided drop-zone variant: ${imageVariant}`)
}

export const dropZoneVariants = [
  "drop_zone",
  "drop_zone_pallet",
  "drop_zone_black_pallet",
  "drop_zone_red_pallet"] as const

export type DropZoneVariant = typeof dropZoneVariants[number]
