import React from "react"

import { renderTestID } from "../helpers/renderTestID"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"
import { FontSize } from "../theme/Theme"

export type ResourceIdSize = FontSize
export type ResourceIdVariant = "lpn" | "asin" | "shipping"

export interface ResourceIdBaseProps extends DangerousProps, TestProps {
  /**
   * Badge size.
   */
  size?: ResourceIdSize

  /**
   * Weither to render product ID expanded even if it can't fit on the screen.
   *
   * Makes sense only for LPNs.
   */
  expanded?: boolean

  /**
   * Component doesn't support children elements.
   */
  children?: undefined
}

export interface ResourceIdProps extends ResourceIdBaseProps {
  /**
   * Product idenitifer.
   */
  resourceID: string

  /**
   * Product idenitifer.
   */
  variant: ResourceIdVariant
}

/**
 * Renders product identifier (ASIN, ISBN, fnSKU, fcSKU, LPN etc).
 *
 * Has additional optimizations for LPN that could be up to 40 characters long.
 */
export class ResourceId extends React.PureComponent<ResourceIdProps> {
  private readonly shippingPrefixLength = 4

  private readonly shippingSuffixLength = 6

  private readonly lpnPrefixLength = 6

  private readonly lpnSuffixLength = 4

  private getPrefixLength(): number {
    switch (this.props.variant) {
      case "lpn":
        return this.lpnPrefixLength
      case "shipping":
        return this.shippingPrefixLength
      default:
        return 0
    }
  }

  private getSuffixLength(): number {
    switch (this.props.variant) {
      case "lpn":
        return this.lpnSuffixLength
      case "shipping":
        return this.shippingSuffixLength
      default:
        return 0
    }
  }

  public splitResourceID(resourceID: string) {
    let prefix: string | undefined
    let content: string | undefined
    let suffix: string | undefined

    switch (this.props.variant) {
      case "lpn":
      case "shipping":
        prefix = resourceID.substring(0, this.getPrefixLength())
        suffix = resourceID.substring(resourceID.length - this.getSuffixLength())
        content = resourceID.substring(prefix.length, resourceID.length - this.getSuffixLength())
        break

      case "asin":
        content = resourceID
        break

      default:
        console.warn("Invalid variant passed to ResourceId component:", this.props.variant)
    }

    return [prefix, content, suffix]
  }

  public render() {
    const {
      resourceID,
      variant,
      dangerousClassName,
      dangerousStyle,
      expanded,
      size = "md"
    } = this.props

    const [prefix, content, suffix] = resourceID ? this.splitResourceID(resourceID.trim()) : []

    const className = "resource-id"
      + ` resource-id--variant-${variant}`
      + ` resource-id--size-${size}`
      + (expanded ? " resource-id--expanded" : "")
      + (dangerousClassName ? ` ${dangerousClassName}` : "")

    // enforce direction because product ID is always LTR
    // trick with RTL on content is needed to add ellipsis from opposite side to show ID ending
    return (
      <div
        className={className}
        style={dangerousStyle}
        data-testid={renderTestID(this.props.testID)}
        dir="ltr"
      >
        {prefix !== undefined && (
          <div className="resource-id__prefix">
            {prefix}
          </div>
        )}
        {content !== undefined && (
          <div className="resource-id__content" dir="rtl">
            {content}
          </div>
        )}
        {suffix !== undefined && (
          <div className="resource-id__suffix">
            {suffix}
          </div>
        )}
      </div>
    )
  }
}
